<div
  class="op-tab-content--container op-files-tab"
>
  <section
    class="op-tab-content--tab-section"
    [ngClass]="{ 'op-tab-content--tab-section_no-header': (showAttachmentHeader$ | async) === false }"
    data-qa-selector="op-tab-content--tab-section"
  >
    <div
      *ngIf="showAttachmentHeader$ | async"
      class="op-tab-content--header"
    >
      <span class="spot-icon spot-icon_attachment op-files-tab--icon op-files-tab--icon_clip"></span>
      <span class="op-tab-content--header-text" [textContent]="text.attachments.label"></span>
    </div>

    <op-attachment-list [resource]="workPackage"></op-attachment-list>

    <op-attachments-upload [resource]="workPackage"></op-attachments-upload>
  </section>

  <section
    *ngFor="let storage of storages$ | async"
    data-qa-selector="op-tab-content--tab-section"
    class="op-tab-content--tab-section"
  >
    <div class=op-tab-content--header>
      <span class="spot-icon spot-icon_nextcloud-circle op-files-tab--icon op-files-tab--icon_nextcloud"></span>
      <span class="op-tab-content--header-text" [textContent]="storage.name"></span>
    </div>

    <op-file-link-list
      [resource]="workPackage"
      [storage]="storage"
    ></op-file-link-list>
  </section>
</div>
