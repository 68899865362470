<div
  class="inline-label ng-select-container"
  id="div-values-{{filter.id}}"
>
  <op-project-autocompleter
    [value]="filter.values"
    [focusDirectly]="shouldFocus"
    [multiple]="true"
    [id]="'values-' + filter.id"
    class="advanced-filters--ng-select -multi-select"
    (valueChange)="onChange($event)"
    [apiFilters]="additionalProjectApiFilters"
  ></op-project-autocompleter>
</div>
