<div class="details-wrapper"><input type="hidden" name="task-details-sprint-id" id="task-details-sprint-id" value="354"
    autocomplete="off">
  <input type="hidden" name="invoboard_id" id="invoboard_id" value="434" class="task-detaile-invoboard-id"
    autocomplete="off">

  <div class="details-dragger d-none d-lg-block"></div>
  <div class="details-top-btns pt-2 d-flex align-items-center justify-content-between ">
    <div class="detail-header-butoon">
      <button class="detail-close-btn" id="detail-close-btn" (click)="clossDetailModel()"><img
          src="/assets/close-detail.svg"></button>
      <a class="detail-preview-btn" id="detail-ticket-preview" 
          [href]="'/projects/' + projectId + '/tickets/' + ticket?.id"><i aria-hidden="true"
          class="icon-zen-mode button--icon"></i></a>
    </div>
    <div class="details-button-wrapper">
      <button class="detail-del-btn" id="task-del-btn" data="6574"><img class="mr-2"
          src="/assets/DetailDel-b5d55b6a1cb34b1a311df491bb2fb06dfc92c0a42a1f0a2e5705265509a26249.svg"> Delete</button>
      <button class="detail-edit-btn ml-2" [style.display]="isShowUpdates ? 'none' : 'block'" id="edit-detail-btn"
        (click)="editTicketDetail(true)"><img class="mr-2"
          src="/assets/DetailEdit-3fc68074700250ff759b449d71ccac81314ba13828db18605c285860d25359c3.svg"> Edit</button>
      <button class="detail-update-btn ml-2 d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
        id="update-detail-btn" (click)="editTicketDetail(false)"><img class="mr-2"
          src="/assets/DetailEdit-3fc68074700250ff759b449d71ccac81314ba13828db18605c285860d25359c3.svg"> Update</button>
    </div>
  </div>
  <div class="details-content-wrapper mb-2">
    <button class="details-content-header" type="button" id="details-One" data-bs-toggle="collapse"
      data-bs-target="#details-collapseOne" aria-expanded="true" aria-controls="details-collapseOne"
      (click)="toggleDetailsCollapse()">
      <span>Details</span>
      <img class="ml-1" src="/assets/DetailArrow-af3eb324ae8c913cb7d4c4f15edf2867085eeeafc265955e639179b84255f4d2.svg">
    </button>
    <div id="details-collapseOne" class="accordion-collapse collapse show"
      [style.display]="isShowDetailsCollaps ? 'block' : 'none'">
      <div class="details-content-body">
        <div class="details-top-wrapper">
          <div class="add-attachment-wrapper">
            <label class="add-attachment">
              <span> <img src="assets/attach.svg" alt="" class="mr-1"> Attachment</span>
              <input type="file" class="d-none" id="sprint-task-attachment" (change)="onFileSelected($event)">
            </label>
          </div>
          <div #watcherWrapper class="add-watcher-wrapper">
            <button class="add-watcher-btn" id="addWatcherBtn" (click)="toggleWatcherDropdown()">
              <img src="/assets/watcher-icon.svg" alt="" class="mr-1">
              Add Watcher
            </button>
            <div class="add-watcher-dropdown-menu d-none" id="addWatcherMenu"
              [style.display]="isShowWatcherDropdown ? 'block': 'none'">
              <div class="add-watcher-title">Watching This Issue</div>
              <div class="display-watchers-wrapper" id="display-watcher-list">
                <ng-container *ngFor="let watcher of ticket?.watchers">
                  <div class="watcher-member-wrapper">
                    <div class="watcher-member-content">
                        <ng-container *ngIf="watcher.get_user_avatar">
                          <img [src]="getMemberAvatarURL(watcher.user_id)"
                            class="watcher-member-avatar sprint-member-avatar principle-avatar"
                            alt="{{ watcher.user.firstname }} {{ watcher.user.lastname }}" />
                        </ng-container>
                        <ng-container *ngIf="!watcher.get_user_avatar">
                          <div
                            [style.background-color]="getAvatarBackgroundColor(watcher.user.firstname, watcher.user.lastname)"
                            class="watcher-member-avatar sprint-member-avatar principle-avatar">
                            {{ getInitials(watcher.user.firstname, watcher.user.lastname) }}
                          </div>
                        </ng-container>
                      <div class="watcher-member-name">{{ getUserFullName(watcher.user) }}</div>
                    </div>
                    <div class="remove-watcher-member" (click)="deleteWatcher(watcher.user_id, ticket?.id)">
                      <img src="/assets/cross-icon.svg" alt="">
                    </div>
                  </div>
                </ng-container>
              </div>
              <hr>
              <div class="add-watchers-select">
                <select name="task-select-watcher-id" id="task-select-watcher-id" (change)="onSelectWatcher($event)">
                  <option value="">Add watcher</option>
                  <ng-container *ngFor="let member of members">
                    <option [value]="member.user_id">{{ getUserFullName(member.user) }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div id="task-attachment-list" class="task-attachment-list">
          <ng-container *ngFor="let attachment of ticket?.attachments">
            <div class="sprint-task-attached-file" [id]="'sprint-attached-id-'+attachment?.id">
              <div>
                  <a [href]="'api/v3/attachments/' + attachment?.id + '/content'"
                    target="_blank" style="word-break: break-all;">
                      {{ attachment?.filename || attachment?.fileName }}
                  </a>
              </div>
              <div class="remove-attached-file" (click)="deleteAttachment(attachment?.id)">
                  <img src="/assets/cross-icon.svg" alt="">
              </div>
            </div>
          </ng-container>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">ID</div>
          <div class="field-inp">{{ ticket?.id }}</div>
          <input type="hidden" name="detail-task-id" id="detail-task-id" value="6574" autocomplete="off">
        </div>
        <div class="content-field-wrapper align-items-start flex-column">
          <div class="field-title mb-1">Title</div>
          <div class="field-inp" id="field-title-detail" [style.display]="isShowUpdates ? 'none' : 'block'">{{ ticket?.subject }}</div>
          <div class="field-inp d-none title-field-inp" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="field-inp-title-detail">
            <textarea type="text" maxlength="200" id="updated-task-name" [(ngModel)]="updatedTicket.subject" [value]="updatedTicket?.subject"></textarea>
          </div>
        </div>
        <div class="content-field-wrapper flex-column align-items-start">
          <div class="field-title mb-1">Description</div>
          <div class="field-inp task-description-field w-100" [style.display]="isShowUpdates ? 'none' : 'block'"
            id="description-detail" [innerHTML]="ticket?.description"></div>
          <div class="w-100" id="textarea-description-detail" [style.display]="isShowUpdates ? 'block' : 'none'">
            <div class="description_detail">
              <div class="description_detail_editor">
                <op-ckeditor [context]="ckEditorContext"
                 [content]="updatedTicket.description"
                 (onContentChange)="onContentChange($event)">
                </op-ckeditor>
              </div>
            </div>
          </div>
        </div>
        <div class="parent-child-wrapper">
          <div class="content-field-wrapper">
            <div class="field-title">Child Issue</div>
            <div class="d-flex align-items-center">
              <button class="child-view-btn mr-2" [class.opened]="isShowParentChildField" id="childs-btn">Child Issue
                <img class="ml-1" src="/assets/DetailArrow.svg" (click)="toggleParentChildField()"></button>
              <div class="field-inp" id="add-child-issue-btn" (click)="addNewChildWrapper(true)">+</div>
            </div>
          </div>
          <div class="add-new-child-wrapper d-none" id="create-new-child" [style.display]="isShowChildWrapper">
            <div class="cretae-child-inp-wrapper">
              <div class="cretae-child-inp">
                <input type="text" id="create-child-inp" placeholder="Add child title" autocomplete="off"
                  maxlength="200" #createChildInput (input)="onChildSearch(createChildInput.value)">
              </div>
              <div class="existing-child-options" *ngIf="seachedChildTickets.length>0" id="existing-child-options">
                <ng-container *ngFor="let child of seachedChildTickets">
                  <div class="child-dropdown-option" (click)="setSearchChildInput(child)">
                    <p>{{child.id}}</p>- {{child.subject}}</div>
                </ng-container>
              </div>
            </div>
            <div class="new-child-btns-wrapper d-flex align-items-center justify-content-end">
              <button class="cancel-new-child-btn" id="cancel-child-task"
                (click)="addNewChildWrapper(false)">Cancel</button>
              <ng-container *ngIf="childAddButtonVisible">
                <button class="create-new-child-btn" (click)="setTicketChild()" id="add-task-children">Add</button>
              </ng-container>
            </div>
          </div>
          <ng-container *ngIf="isShowParentChildField">
            <div class="parent-child-field" *ngIf="ticket?.children.length > 0">
              <ng-container *ngFor="let child of ticket?.children">
                <div class="sprint-child-list child-list-issue-40" data-child-id="child?.id">
                  <div class="sprint-child-heading">
                    <img src="/assets/ListIcon.svg" class="mr-2 list-icon">
                    <p>{{ child?.subject }}</p>
                  </div>
                  <div class="sprint-child-tags">
                    <div class="child-members-wrapper">
                      <div class="child-member">
                        {{ child?.id }}
                      </div>
                      <div class="child-added-member-wrapper dropdown mr-2" #addChildMemberDropdown>
                        <button class="add-child-member-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                          id="addChildMemberBtn-40" (click)="toggleChildMemberMenu()">
                          <ng-container *ngIf="child?.all_assignees?.length > 0">
                            <div class="child-member-img sprint-member-avatar"
                              [style.background-color]="getAvatarBackgroundColor(child?.all_assignees[0]?.firstname, child?.all_assignees[0]?.lastname)">
                              <ng-container *ngIf="child?.all_assignees[0]?.get_user_avatar == null">
                                {{ getUserInitials(child?.all_assignees[0]) }}
                              </ng-container>
                              <ng-container *ngIf="child?.all_assignees[0]?.get_user_avatar">
                                <img 
                                [src]="getMemberAvatarURL(child?.all_assignees[0]?.id)"
                                alt="{{ child?.all_assignees[0]?.firstname }} {{ child?.all_assignees[0]?.lastname }}">
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="child?.all_assignees?.length == 0">
                            <div class="child-member-img sprint-member-avatar" data="25" style="color: #1a67a3">
                              -
                            </div>
                          </ng-container>
                        </button>
                        <div class="add-child-member-menu dropdown-menu show d-none"
                          [style.display]="isShowChildMemberMenu? 'block': 'none'" id="childMemberMenu-40"
                          data-popper-placement="bottom-end">
                          <div id="listing-assignee-40">
                            <div class="display-child-members-wrapper" id="display-child-members-list">
                              <ng-container *ngFor="let assignee of child?.all_assignees">
                                <div class="child-member-content-wrapper">
                                  <div class="child-member-content">
                                    <div class="child-member-avatar sprint-member-avatar principle-avatar"
                                      [style.background-color]="getAvatarBackgroundColor(assignee?.firstname, assignee?.lastname)">
                                      <ng-container *ngIf="assignee?.get_user_avatar == null">
                                        {{ getUserInitials(assignee) }}
                                      </ng-container>
                                      <ng-container *ngIf="assignee?.get_user_avatar">
                                        <img 
                                        [src]="getMemberAvatarURL(assignee?.id)"
                                        alt="{{ assignee?.firstname }} {{ assignee?.lastname }}">
                                      </ng-container>
                                    </div>
                                    <div class="child-member-name">{{ getUserFullName(assignee) }}</div>
                                  </div>
                                  <div class="remove-child-member" (click)="deleteAssignee(child.id, assignee.id)">
                                    <img src="/assets/cross-icon.svg" alt="">
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                          <hr>
                          <div class="add-child-member-select">
                            <select name="task-select-assignee-id" id="task-select-child-assignee-id" (change)="onSelectChildAssignee($event, child?.id)">
                              <option value="">Add Assignee</option>
                              <ng-container *ngFor="let member of members">
                                <option [value]="member.user_id">{{ getUserFullName(member.user) }}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="child-options-wrapper" #childOptionWrapper>
                      <button class="child-options-btn" (click)="toggleChildOptionMenu()">
                        <img src="/assets/childOption.svg">
                      </button>
                      <div class="child-options-menu d-none" [style.display]="isShowChildOption? 'block': 'none'">
                        <div class="child-option remove-child-btn" data-child-id="40">Remove Child Issue</div>
                        <div class="child-option remove-child-from-sprint" data-child-id="40">Remove from Sprint</div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Labels</div>
          <div class="d-flex align-items" #labelsDropdownContainer>
            <div class="sprint-labels-detail-container" id="detail-field-label" *ngIf="ticket?.labels?.length > 0">
              <ng-container *ngFor="let label of ticket?.labels?.slice(0,2)">
                <div class="sprint-task-detail-label" [style.background-color]="label?.color?.hexcode">
                  <span [style.color]="getContrastColor(label?.color?.hexcode)">{{ label?.title }}</span>
                  <button class="remove-ticket-label" label-id="label?.id" (click)="removeTicketLabel(label?.id)">
                    <svg width="16" height="16" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M16.6765 19.2938L9.70649 12.3238L2.73644 19.2938L0.413086 16.9705L7.38314 10.0004L0.413086 3.03038L2.73644 0.707031L9.70649 7.67708L16.6765 0.707031L18.9999 3.03038L12.0298 10.0004L18.9999 16.9705L16.6765 19.2938Z"
                        fill="white"></path>
                    </svg>
                  </button>
                </div>
              </ng-container>
              <ng-container *ngIf="ticket?.labels?.length > 2">
                <div class="multi-label-wrappers">
                  <div class="sprint-task-detail-label" style="background-color: grey"
                    (mouseover)="toggleMultiLabelDetail('block')" (mouseleave)="toggleMultiLabelDetail('none')">
                    <span>+{{ ticket?.labels?.length-2 }}</span>
                  </div>
                  <div class="task-multiLabel-tooltip" [style.display]="isMultiLabelVisible"
                    (mouseover)="toggleMultiLabelDetail('block')" (mouseleave)="toggleMultiLabelDetail('none')">
                    <ng-container *ngFor="let label of ticket?.labels?.slice(2,ticket?.labels?.length)">
                      <div class="sprint-task-detail-label" [style.background-color]="label?.color?.hexcode">
                        <span [style.color]="getContrastColor(label?.color?.hexcode)">{{ label?.title }}</span>
                        <button class="remove-ticket-label" label-id="label?.id" (click)="removeTicketLabel(label?.id)">
                          <svg width="16" height="16" viewBox="0 0 16 26" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M16.6765 19.2938L9.70649 12.3238L2.73644 19.2938L0.413086 16.9705L7.38314 10.0004L0.413086 3.03038L2.73644 0.707031L9.70649 7.67708L16.6765 0.707031L18.9999 3.03038L12.0298 10.0004L18.9999 16.9705L16.6765 19.2938Z"
                              fill="white"></path>
                          </svg>
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
            <div class="add-labels-wrapper">
              <button class="add-label-btn" (click)="showLabelsDropdown(true)">+</button>
              <div class="add-labels-dropdown-container d-none" [style.display]="isShowLabelDropdown? 'block': 'none'">
                <div class="labels-dropdown-top">
                  <button class="labels-dropdown-close-btn" (click)="showLabelsDropdown(false)">×</button>
                </div>
                <div class="labels-select-wrapper" [style.display]="isShowLabelSelectWrapper? 'block': 'none'">
                  <div class="d-flex align-items-center search-labels-inp">
                    <img src="/assets/search_label.svg" alt="">
                    <input type="text" placeholder="Search Labels.." autocomplete="off" id="search-label-input" #searchLabelsInput (input)="searchLabels(searchLabelsInput.value)">
                  </div>
                  <div class="search-labels-inp-border"></div>
                  <div class="all-labels-listing">
                    <ng-container *ngFor="let label of searchedLabels">
                      <div class="listed-label-wrapper listed-label-2330">
                        <div class="editable-label-wrap">
                          <div class="selectable-label-wrap " label-id="label?.id" [style.background-color]="label?.color?.hexcode" (click)="toggleLabel(label?.id)" [hidden]="label.editMode">
                            <span class="selectable-label-text">{{ label?.title }}</span>
                            <div *ngIf="isTicketLabel(label?.id)" class="member-selected label-selected" style="color:white;">✓</div>
                          </div>
                          <input class="edit-label-inp" type="text" autocomplete="off"
                            placeholder="Enter label title" #editLabelInput [value]="label?.title" [hidden]="!label.editMode">
                        </div>
                        <div class="editable-label-btns-wrap d-flex align-items-center">
                          <button class="edit-label-btn label-edit-btn" (click)="toggleEditMode(label.id)" [hidden]="label.editMode">
                            <img src="/assets/tiitle_edit.svg" alt="">
                          </button>
                          <button class="edit-label-btn update-label-btn" (click)="toggleEditMode(label.id, editLabelInput.value)" [hidden]="!label.editMode">
                            <img src="/assets/save_title.svg" alt="">
                          </button>
                          <button class="edit-label-btn mr-0 discard-update-label" (click)="toggleEditMode(label.id)" [hidden]="!label.editMode">
                            <img alt="delete" src="/assets/cross-icon.svg">
                          </button>
                          <button class="edit-label-btn mr-0 delete-label" (click)="deleteTicketLabel(label.id)" [hidden]="label.editMode">
                            <img alt="delete" src="/assets/cross-icon.svg">
                          </button>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="mt-3 mx-3">
                    <button class="create-label-btn" (click)="showLabelCreateWrapper(true)">
                      <img class="pl-4 pr-3" src="/assets/plus.svg" alt="">
                      Create New Label
                    </button>
                  </div>
                </div>
                <div class="labels-create-wrapper d-none" [style.display]="isShowLabelCreateWrapper ? 'block': 'none'">
                  <div class="d-flex align-items-center labels-create-top">
                    <button class="labels-back-btn" (click)="showLabelCreateWrapper(false)">
                      <img src="/assets/back.svg" alt="">
                    </button>
                    <h3>Create label</h3>
                  </div>
                  <div class="create-labels-top-border"></div>
                  <div class="create-labels-inp-field">
                    <input type="text" placeholder="Title" #newLabelTitle autocomplete="off" id="ticket-new-label-name">
                  </div>
                  <h4 class="select-clr-title">Select a Color</h4>
                  <div class="create-labels-colors-grid">
                    <ng-container *ngFor="let color of colors">
                      <button class="label-color-btn" [style.background-color]="color?.hexcode" (click)="selectLabelColor(color?.id)">
                        <div *ngIf="isSelectedColor(color?.id)" class="member-selected create-label-selected" style="color:white;">✓</div>
                      </button>
                    </ng-container>
                  </div>
                  <div class="create-labels-footer">
                    <button class="label-create-btn" id="ticket-create-new-label" (click)="createTicketLabel(newLabelTitle.value)">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Priority</div>
          <div class="field-inp" [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-priority">
            <div class="priority-type-tag">
              <div class="tag-color" [style.background]="ticket?.priority?.color?.hexcode"></div>
              <div class="tag-text">
                {{ ticket?.priority?.name }}
              </div>
            </div>
          </div>
          <div class="field-inp select-priority-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="select-field-priority">
            <select name="updated-task-priority" id="updated-task-priority" [(ngModel)]="updatedTicket.priority_id">
              <option *ngFor="let priority of taskPriorities" [value]="priority.id" [selected]="updatedTicket?.priority_id === priority.id">
                {{ priority?.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Type</div>
          <div class="field-inp " [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-task-type">
            <div class="task-type-tag">
              <div class="tag-color" [style.background]="ticket?.type?.color"></div>
              <div class="tag-text">
                {{ ticket?.type?.name }}
              </div>
            </div>
          </div>
          <div class="field-inp select-priority-inp d-none " [style.display]="isShowUpdates ? 'block' : 'none'"
            id="select-field-task-type">
            <select name="updated-task-type" id="updated-task-type" [(ngModel)]="updatedTicket.type_id">
              <option *ngFor="let type of taskTypes" [value]="type.id" [selected]="updatedTicket?.type_id === type.id">
                {{ type?.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Progress</div>
          <div class="field-inp" style="width: 120px;">
            <div class="sprint-task-progress">
              <div class="task-progress-completed" [style.width.%]="ticket?.progress?.complete_percentage">
              </div>
              <div class="task-progress-details">
                <div class="prog-details-item">
                  <div class="prog-details-item-title green-title">Completed</div>
                  <div class="prog-details-item-value ticket-complete-hours">
                    {{ ticket?.progress?.ticket_completed_hours }} Hr(s)
                  </div>
                </div>
                <div class="prog-details-item">
                  <div class="prog-details-item-title gray-title">Remaining</div>
                  <div class="prog-details-item-value ticket-remaining-hours">
                    {{ ticket?.progress?.ticket_remaining_hours }} Hr(s)
                  </div>
                </div>
                <div class="prog-details-item">
                  <div class="prog-details-item-title red-title">Extra Hours</div>
                  <div class="prog-details-item-value ticket-extra-hours">
                    {{ ticket?.progress?.extra_time_hours }} Hr(s)
                  </div>
                </div>
                <div class="prog-details-item">
                  <div class="prog-details-item-title text-title">Total</div>
                  <div class="prog-details-item-value ticket-total-hours">
                    {{ ticket?.progress?.total_spent_hours }} Hr(s)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">Assigned to</div>
          <div class="membersAssignee">
            <member-tooltip (remove)="removeAssignee($event)"
              [users]="ticket?.all_assignees"
              [isDeleteable]="'true'"
              style="display: flex;"></member-tooltip>
            <div class="assigned-to-wrapper">
              <div class="add-member-dropdown-wrapper" #memberDropdownWrapper>
                <button class="add-member-btn" id="sprintTaskAddMember" (click)="toggleMemberListWrapper()">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.55664 1.5V14.5M1.05664 8H14.0566" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </button>
                <div class="add-member-list-wrapper d-none ssssss" id="sprintTaskAddMemberDropdown"
                  [style.display]="isShowAddMemberListWrapper? 'block' : 'none'">
                  <div class="add-member-search">
                    <div class="search-input">
                      <img src="/assets/search_label.svg">
                      <input id="assignee-search" type="text" placeholder="Find Members" #searchMembersInput (input)="searchMembers(searchMembersInput.value)">
                    </div>
                    <div class="add-member-divider">
                      <hr>
                    </div>
                  </div>

                  <ng-container *ngIf="searchMembersInput.value.length > 0">
                    <ng-container *ngFor="let member of searchedMembers">
                      <div class="add-member-list " (click)="toggleAssignee(member?.user)">
                        <div class="member-content" style="width: 100%">
                          <div class="member-avatar multi-member-sprint principle-avatar" data-principal-name="Muhammad Islam"
                          [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                            <ng-container *ngIf="member?.get_user_avatar == null">
                              {{ getUserInitials(member?.user) }}
                            </ng-container>
                            <ng-container *ngIf="member?.get_user_avatar">
                              <img 
                              [src]="getMemberAvatarURL(member?.user?.id)"
                              alt="{{ member?.user?.firstname }} {{ member?.user?.lastname }}">
                            </ng-container>
                          </div>
                          <div class="memberSelectedWrapper" style="width: 100%">
                            <div class="member-name">{{ getUserFullName(member?.user) }}</div>
                            <div class="member-selected" *ngIf="isMember(member?.user)"><img src="/assets/check.svg" class="tick"></div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="searchMembersInput.value.length == 0">
                    <ng-container *ngFor="let member of members">
                      <div class="add-member-list " (click)="toggleAssignee(member?.user)">
                        <div class="member-content" style="width: 100%">
                          <div class="member-avatar multi-member-sprint principle-avatar" data-principal-name="Muhammad Islam"
                          [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                            <ng-container *ngIf="member?.get_user_avatar == null">
                              {{ getUserInitials(member?.user) }}
                            </ng-container>
                            <ng-container *ngIf="member?.get_user_avatar">
                              <img 
                              [src]="getMemberAvatarURL(member?.user?.id)"
                              alt="{{ member?.user?.firstname }} {{ member?.user?.lastname }}">
                            </ng-container>
                          </div>
                          <div class="memberSelectedWrapper" style="width: 100%">
                            <div class="member-name">{{ getUserFullName(member?.user) }}</div>
                            <div class="member-selected" *ngIf="isMember(member?.user)"><img src="/assets/check.svg" class="tick"></div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Story Points</div>
          <div class="field-inp" [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-story-points">
            <div class="story-points-tag">
              {{ ticket?.story_points || 0 }}
            </div>
          </div>
          <div class="field-inp select-priority-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="task-story-points-inp">
            <input type="number" min="0" class="story-points-inp" id="story-points-inp" [value]="updatedTicket?.story_points" #ticketStoryPoints (input)="validateInput()" [(ngModel)]="updatedTicket.story_points">
          </div>
        </div>
        <div class="multi-assignee-wrapper">
          <ng-container *ngFor="let assignee of ticket?.all_assignees_with_story_points">
            <div class="content-field-wrapper mt-2 assignee-sp-wrapper-7">
              <div class="field-title">
                <div class="assigned-member-storypoints-wrapper">
                  <div class="assigned-member-avatar principle-avatar" data-principal-name="getUserFullName(assignee?.assigned_to)"
                    [style.background-color]="getAvatarBackgroundColor(assignee?.assigned_to?.firstname, assignee?.assigned_to?.lastname)">
                    <ng-container *ngIf="assignee?.avatar == null">
                      {{ getUserInitials(assignee?.assigned_to) }}
                    </ng-container>
                    <ng-container *ngIf="assignee?.avatar">
                      <img 
                      [src]="getMemberAvatarURL(assignee?.assigned_to?.id)"
                      alt="{{ assignee?.assigned_to?.firstname }} {{ assignee?.assigned_to?.lastname }}">
                    </ng-container>
                  </div>
                  <div class="assigned-member-storypoints-content">
                    <div class="assigned-member-name">{{ getUserFullName(assignee?.assigned_to) }}</div>
                  </div>
                </div>
              </div>
              <div class="field-inp detail-field-assignee-storypoints" [style.display]="isShowUpdates ? 'none' : 'block'">
                <div class="assigned-storypoints-tag">{{ assignee?.assignee_story_point || 0 }} Story Points</div>
              </div>
              <div class="field-inp assignee-story-points-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'">
                <input type="number" min="0" class="story-points-inp assignee" value="" data-assignee-id="7" [ngClass]="'assignee-story-point-'+assignee?.assigned_to?.id"
                  data-work-package-assignee-id="" [value]="getAssigneeStoryPoint(assignee?.id)" #assigneeStoryPoint (input)="updateAssigneeStoryPoint(assigneeStoryPoint.value, assignee)">
              </div>
            </div>
          </ng-container>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">Status</div>
          <div class="field-inp" [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-status">
            <div class="status-type-tag">
              <div class="tag-color" [style.background]="ticket?.status?.color?.hexcode"></div>
              <div class="tag-text">
                {{ ticket?.status?.name }}
              </div>
            </div>
          </div>
          <div class="field-inp select-status-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="select-status-inp">
            <select name="updated-task-status" id="updated-task-status" [(ngModel)]="updatedTicket.status_id">
              <option *ngFor="let status of ticketStatus" [value]="status.id" [selected]="updatedTicket?.status_id === status.id">
                {{ status?.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Start Date</div>
          <div class="field-inp" [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-start-date">
            <div class="story-points-tag">
              {{ ticket?.start_date || '' }}
            </div>
          </div>
          <div class="field-inp select-priority-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="details-start-date-inp">
            <input type="date" class="task-details-date" id="edit-start-date-inp"
              (change)="validateDueDate($event)"
              (value)="updatedTicket?.start_date" [(ngModel)]="updatedTicket.start_date">
          </div>
        </div>
        <div class="content-field-wrapper mt-2">
          <div class="field-title">Due Date</div>
          <div class="field-inp" [style.display]="isShowUpdates ? 'none' : 'block'" id="detail-field-end-date">
            <div class="story-points-tag">
              {{ ticket?.due_date || '' }}
            </div>
          </div>
          <div class="field-inp select-priority-inp d-none" [style.display]="isShowUpdates ? 'block' : 'none'"
            id="details-end-date-inp">
            <input type="date" class="task-details-date" id="edit-end-date-inp"
              (change)="validateDueDate($event)"
              (value)="updatedTicket?.due_date" [(ngModel)]="updatedTicket.due_date">
          </div>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">Reported by</div>
          <div class="field-inp">{{ ticket?.author?.firstname || ticket?.author?.firstname || "" }}</div>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">Created by</div>
          <div class="field-inp">{{ ticket?.author?.firstname || ticket?.author?.firstname || "" }}</div>
        </div>
        <div class="content-field-wrapper">
          <div class="field-title">Created at</div>
          <div class="field-inp">{{ ticket?.created_at || '' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>