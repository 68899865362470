<div class="comment-section">
  <div class="comments">
    <div class="journal-user-img"
      [style.background-color]="getAvatarBackgroundColor(activity?.user?.firstname, activity?.user?.lastname)">
      <ng-container *ngIf="activity?.get_user_avatar == null">
        {{ getUserInitials(activity?.user) }}
      </ng-container>
      <ng-container *ngIf="activity?.get_user_avatar">
        <img
        [src]="getMemberAvatarURL(activity?.user?.id)"
        alt="{{ activity?.user?.firstname }} {{ activity?.user?.lastname }}">
      </ng-container>
      <ng-container *ngIf="!activity?.hasOwnProperty('get_user_avatar')">
        N/A
      </ng-container>
    </div>
    <div class="activity_comment">
      <h4>{{ getUserFullName(activity?.user) }}</h4>
      <p>{{ formatDateToString(activity?.created_at) }}</p>
      <ng-container *ngIf="activity?.data?.note?.length > 0">
        <ul class="journal-details">
          <ng-container *ngFor="let detail of activity?.data?.note">
            <li [innerHTML]="detail"></li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
</div>