<div
  class="spot-modal op-confirm-dialog loading-indicator--location"
  data-indicator-name="modal"
  tabindex="0"
  cdkFocusInitial
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <div class="spot-modal--header">{{text.title}}</div>

  <div *ngIf="divideContent" class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <span class="op-confirm-dialog--text spot-body-small"
          [textContent]="text.text"
    ></span>
    <span
      *ngFor="let data of passedData"
      class="spot-body-small"
    >
      <br/>
      <strong>{{data}}</strong>
    </span>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button spot-modal--cancel-button spot-action-bar--action"
        data-qa-selector="confirmation-modal--cancel"
        (click)="closeMe($event)"
      >
        <span *ngIf="icon.cancel as clazz" class="spot-icon spot-icon_{{clazz}}"></span>
        <span>{{text.button_cancel}}</span>
      </button>
      <button
        type="button"
        class="button spot-action-bar--action"
        data-qa-selector="confirmation-modal--confirmed"
        [ngClass]="dangerHighlighting ? '-danger': '-highlight'"
        (click)="confirmAndClose($event)"
      >
        <span *ngIf="icon.continue as clazz" class="spot-icon spot-icon_{{clazz}}"></span>
        <span>{{text.button_continue}}</span>
      </button>
    </div>
  </div>
</div>
