<a
  class="button"
  [href]="myNotificationSettingsLink"
  target="_blank"
>
  <op-icon icon-classes="button--icon icon-settings">
  </op-icon>
  <span
    class="button--text"
    [textContent]="text.mySettings"
  >
          </span>
</a>