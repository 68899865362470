import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
  ViewChild,
  ViewChildren,
  ElementRef,
  Renderer2,
  QueryList,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { I18nService } from "core-app/core/i18n/i18n.service";

@Component({
  templateUrl: "./ticket-listing.component.html",
  styleUrls: ["./ticket-listing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketListingComponent implements AfterViewInit {
  // @ViewChild("title") title: ElementRef;
  @ViewChild("scrollingContainer", { static: false }) scrollingContainer: ElementRef;
  @ViewChild("onFilterButton", { static: false }) filterButton: ElementRef;
  @ViewChild("sprintLists", {static: false}) sprintLists: ElementRef;
  @ViewChildren("taskProgressDetails") taskProgressDetails: QueryList<ElementRef>;
  @ViewChildren("taskMultiLabelTooltip") taskMultiLabelTooltip: QueryList<ElementRef>;
  @ViewChild("collapseSettingButton") collapseSettingButton: ElementRef;
  @ViewChild("collapseSettingDropDown") collapseSettingDropDown: ElementRef;
  @ViewChildren("showErrorMessage") showErrorMessage: QueryList<ElementRef>;
  @ViewChildren("showSuccessMessage") showSuccessMessage: QueryList<ElementRef>;

  public tickets: any[] = [];
  public projectId: number;
  public page = 1;
  public perPage = 20;
  public totalPages = 1;
  public totalTickets = 0;
  public isLoading = true;
  public order: string = "asc";
  public filterParams: string = "";
  public column: string = "";
  public assignees: any[] = [];
  public labels: any[] = [];
  public colors: any[] = [];
  public members: any[] = [];
  public showFilter: boolean = false;
  public showDetails: boolean = false;
  public taskTypes: any[] = [];
  public ticketStatus: any[] = [];
  public taskPriorities: any[] = [];
  public currentUser: {};
  public scrollTop = 0;
  public scrollLeft = 0;
  public lastPosition: { top: number; right: number } = { top: 0, right: 0 };
  public selectedTicket:any = null;
  public divHeight = 0;
  public errorMessage: String = ''
  public successMessage: String = ''

  columnVisibility: { [key: string]: any } = {
    Assignee: { key: 'Assignee', value: false },
    Status: { key: 'Status', value: false },
    Type: { key: 'Type', value: false },
    Priority: { key: 'Priority', value: false },
    Label: { key: 'label', value: false },
    Progress: { key: 'Progress', value: false },
    StartDate: { key: 'Start_date', value: false },
    DueDate: { key: 'Due_date', value: false },
    HoursSpent: { key: 'HoursSpent', value: false },
    Points: { key: 'Points', value: false },
  };

  columnVisibilitySettings: { [key: string]: { key: string, value: boolean} } = {
    Assignee: { key: 'Assignee', value: true },
    Status: { key: 'Status', value: true },
    Type: { key: 'Type', value: true },
    Priority: { key: 'Priority', value: true },
    Label: { key: 'label', value: true },
    Progress: { key: 'Progress', value: true },
    StartDate: { key: 'Start_date', value: true },
    DueDate: { key: 'Due_date', value: true },
    HoursSpent: { key: 'HoursSpent', value: true },
    Points: { key: 'Points', value: true },
  };
  backlogSettingId: number | null = null;
  sprintPlanningSettingId: number | null = null;
  isCollapseSettingsVisible: boolean = false;
  isButtonActive: boolean = false;
  activeDetailTab: boolean =false;
  private documentClickHandler: () => void;
  constructor(
    private I18n: I18nService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private e1: ElementRef,
    private renderer: Renderer2
  ) {}

  onSprintTaskProgressEnter(event: MouseEvent, ticket: any) {
    const index = this.tickets.indexOf(ticket);
    this.showTaskProgressDetails(event, index);
  }

  setErrorMessage(event: String) {
    this.errorMessage = event;
    setTimeout(() => { 
      this.errorMessage = '';
      this.cdr.detectChanges();
    }, 3000);
  }

  setSuccessMessage(event: String) {
    this.successMessage = event;
    setTimeout(() => { 
      this.successMessage = '';
      this.cdr.detectChanges();
    }, 3000);
  }

  updateTicket(data: any) {
    const ticketIndex = this.tickets.findIndex((ticket: any)=> ticket.id == data.id)
    this.tickets[ticketIndex].labels = data.labels;
    this.tickets[ticketIndex].assignees_list = data.all_assignees;
    this.tickets[ticketIndex].story_points = data.story_points;
    this.tickets[ticketIndex].title = data.subject;
    this.tickets[ticketIndex].priority = data.priority;
    this.tickets[ticketIndex].status = data.status;
    this.tickets[ticketIndex].type = data.type;
    this.tickets[ticketIndex].start_date = data.start_date;
    this.tickets[ticketIndex].due_date = data.due_date;
  }

  onSprintTaskProgressLeave(ticket: any) {
    const index = this.tickets.indexOf(ticket);
    this.hideTaskProgressDetails(index);
  }

  showTaskProgressDetails(event: MouseEvent, index: number) {
    const divs = this.taskProgressDetails.toArray();
    const div = divs[index].nativeElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const right = window.innerWidth - rect.left - 50;
    let top = rect.top;

    if (top + div.offsetHeight + 160 > window.innerHeight + window.scrollY) {
      top = rect.top - 160;
    }

    this.renderer.setStyle(div, "right", `${right}px`);
    this.renderer.setStyle(div, "top", `${top}px`);
    this.renderer.setStyle(div, "display", "block");
  }

  hideTaskProgressDetails(index: any) {
    const divs = this.taskProgressDetails.toArray();
    const div = divs[index].nativeElement;
    this.renderer.setStyle(div, "display", "none");
  }

  onSprintTaskMultiLabelEnter(event: MouseEvent, ticket: any) {
    const index = this.tickets.indexOf(ticket);
    this.showMultiLabels(event, ticket?.id);
  }

  onSprintTaskMultiLabelLeave(ticket: any) {
    this.hideMultiLabels(ticket?.id);
  }

  showMultiLabels(event: MouseEvent, index: any) {
    const div = document.querySelector(`.task-${index}`) as HTMLElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const right = window.innerWidth - rect.left - 20;
    let top = rect.top + 20;

    setTimeout(() => {
      this.divHeight = div.offsetHeight;
    });

    if (top + this.divHeight + 10 > window.innerHeight + window.scrollY) {
      top = rect.top - this.divHeight;
      this.renderer.setStyle(div, "right", `${right}px`);
      this.renderer.setStyle(div, "top", `${top}px`);
      this.renderer.setStyle(div, "display", "block");
    } else {
      this.renderer?.setStyle(div, "right", `${right}px`);
      this.renderer?.setStyle(div, "top", `${top}px`);
      this.renderer?.setStyle(div, "display", "block");
    }
  }

  hideMultiLabels(index: any) {
    const div = document.querySelector(`.task-${index}`) as HTMLElement;
    this.renderer.setStyle(div, "display", "none");
  }

  ngOnInit(): void {
    this.column = this.getQueryParameter("column") || this.column;
    this.order = this.getQueryParameter("order") || this.order;
    this.getProjectId();
    this.loadProjectInfo();
    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    if (!urlSearchParams.has("filterParams")) {
      this.loadAllProjectTickets([]);
    }
  }

  ngAfterViewInit(): void {
    // Add document click event listener
    this.documentClickHandler = this.onDocumentClick.bind(this);
    this.renderer.listen('document', 'click', this.documentClickHandler);
  }

  getProjectId(): number {
    const url = window.location.href;
    const matches = url.match(/projects\/(\d+)\/project_tickets/);
    if (matches && matches.length > 1) {
      this.projectId = +matches[1];
      return +matches[1];
    } else {
      return -1;
    }
  }

  loadProjectInfo(): void {
    this.http.get(`/api/v1/projects/${this.projectId}/project_info`).subscribe((data: any) => {
      this.assignees = data.project.project_sprint_assignees;
      this.labels = data.project.labels;
      this.colors = data.project.available_label_colors;
      this.members = data.project.members;
      this.taskTypes = data.task_types;
      this.currentUser = data.current_user;
      this.ticketStatus = data.ticket_status;
      this.taskPriorities = data.task_priorities;
      this.backlogSettingId = data?.columns_setting?.id;
      this.sprintPlanningSettingId = data?.columns_setting?.sprint_planning_setting_id;
      this.setColumnSettingsData(data.columns_setting);
      this.cdr.detectChanges();
    });
  }

  setColumnSettingsData(setting: any) {
    for (const column in setting.collapse_column) {
      if (setting.collapse_column.hasOwnProperty(column)) {
          const keyToUpdate = column;
          const valueToUpdate = setting.collapse_column[column];
          let objectEntry = this.getObjectKeyByValue(this.columnVisibility, keyToUpdate)
          if (objectEntry && this.columnVisibility.hasOwnProperty(objectEntry)) {
            if (valueToUpdate) { this.handleColumnClicked(objectEntry,true) }
              this.columnVisibility[objectEntry].value = valueToUpdate;
          }
      }
    }
    for (const column in setting.show_column) {
      if (setting.show_column.hasOwnProperty(column)) {
          const keyToUpdate = column;
          const valueToUpdate = setting.show_column[column];
          let objectEntry = this.getObjectKeyByValue(this.columnVisibilitySettings, keyToUpdate)
          if (objectEntry && this.columnVisibilitySettings.hasOwnProperty(objectEntry)) {
            if (valueToUpdate) {
              this.columnVisibilitySettings[objectEntry].value = valueToUpdate;
            } else {
              this.columnVisibilitySettings[objectEntry].value = !valueToUpdate;
              this.toggleActiveSettingClass(objectEntry)
            }
          }
      }
    }
  }

  loadAllProjectTickets(previousTickets: any[]): void {
    this.isLoading = true;
    this.http
      .get(
        `/api/v1/projects/${this.projectId}/expensive_tasks?per_page=${this.perPage}&page=${this.page}&column=${this.column}&order=${this.order}&${this.filterParams}`
      )
      .subscribe((data: any) => {
        this.tickets = previousTickets.concat(data?.tasks);
        this.totalPages = data?.total_pages;
        this.totalTickets = data?.total_entries;
        if (this.page <= this.totalPages) {
          this.page++;
        }
        this.isLoading = false;
        this.cdr.detectChanges();
      });
      if(!this.isFiltersApplied()) {
        this.clearUrl();
      }
  }

  isFiltersApplied() {
    let encodedfilters = decodeURIComponent(this.filterParams);
    let decodedFilters = decodeURIComponent(encodedfilters.split('=')[1]);
    if(!decodedFilters || decodedFilters === 'undefined'){ return false; }

    let filters = JSON.parse(decodedFilters);
      return (
          filters.subject.subject === "" ||
          filters.subject.search_type !== "any_match" ||
          filters.assigned_to_id?.length > 0 ||
          filters.no_member ||
          filters.closed ||
          filters.no_label ||
          filters.label_ids?.length > 0 ||
          filters.due_date_filters?.noDate ||
          filters.due_date_filters?.overDate ||
          filters.due_date_filters?.dueInNextDay ||
          filters.due_date_filters?.dueInNextWeek ||
          filters.due_date_filters?.dueInNextMonth ||
          filters.story_points?.length > 0 ||
          filters.status_id?.length > 0 ||
          filters.tyoe_id?.length > 0
      );

  }

  clearUrl() {
    window.history.replaceState({}, document.title, window.location.href.split('?')[0]);
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  getInitials(firstName: string, lastName: string): string {
    return (firstName[0] + lastName[0]).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  showFilterMenu(): void {
    this.showFilter = true;
  }

  hideFilterMenu(): void {
    this.showFilter = false;
  }
  showDetailsMenu(ticket: any): void {
    if (ticket.id != this.selectedTicket?.id) {
      this.selectedTicket = ticket;
      this.getTicketDetail();
    }
  }
  hideDetailsMenu(): void {
    this.showDetails = false;
    this.activeDetailTab = false;
    this.selectedTicket = null;
  }

  getTicketDetail(): void {
    this.http
      .get(
        `/api/v3/work_packages/${this.selectedTicket?.id}`,
      )
      .subscribe((data: any) => {
        this.selectedTicket = data;
        this.showDetails = true;
        this.activeDetailTab= true;
        this.cdr.detectChanges();
      });
  }

  updateQueryParams(filters: string): void {
    this.filterParams = filters;
    this.page = 1;
    this.loadAllProjectTickets([]);
  }

  @HostListener("scroll", ["$event"])
  onScroll(event: Event): void {
    if (this.isLoading) {
      return;
    }
    const scrollContainer = event.target as HTMLElement;
    const scrollHeight = scrollContainer.scrollHeight;
    this.scrollTop = scrollContainer.scrollTop;
    this.scrollLeft = scrollContainer.scrollLeft;
    const clientHeight = scrollContainer.clientHeight;

    const triggerThreshold = 50;
    if (scrollHeight - this.scrollTop - clientHeight < triggerThreshold && this.page <= this.totalPages) {
      this.isLoading = true;
      this.loadAllProjectTickets(this.tickets);
    }
  }

  sortProjects(column: string, order: "asc" | "desc"): void {
    this.page = 1;
    this.perPage = 20;
    this.order = order;
    this.column = column;
    this.handlePageState("column", column);
    this.handlePageState("order", order);
    this.loadAllProjectTickets([]);
  }

  handlePageState(paramName: string, paramValue: string): void {
    let currentURL = new URL(window.location.href);
    let searchParams = currentURL.searchParams;

    if (searchParams.has(`${paramName}`)) searchParams.delete(`${paramName}`);
    searchParams.append(paramName, paramValue);
    // searchParams.append('order', this.order);
    currentURL.search = searchParams.toString();

    window.history.pushState(
      {
        html: "response.html",
        pageTitle: "response.pageTitle",
      },
      "",
      currentURL.href
    );
  }

  getQueryParameter(paramName: string): any {
    let currentURL = new URL(window.location.href);
    let searchParams = currentURL.searchParams;

    return searchParams.get(`${paramName}`);
  }

  getProgressColor(ticket: { [key: string]: any }): string {
    return ticket?.progress?.ticket_remaining_hours == 0 && ticket?.progress?.extra_time_hours > 0 ? "red" : "#3cb280";
  }

  handleColumnClicked(column: string, reload: boolean = false) {

    let columnLabel = column;
    if(columnLabel == 'StartDate' ){
      columnLabel = 'Start Date'
    }
    if(columnLabel == 'DueDate'){
      columnLabel = 'Due Date'
    }
    if(columnLabel == 'HoursSpent'){
      columnLabel = 'Hours Spent'
    }

    column = column.replace(/\s+/g, '')
 
    const wrapperDiv = this.e1.nativeElement.querySelector('.project-collapsed-columns-wrapper');
    const existingDiv = wrapperDiv.querySelector(`.sprint-collapsed-${column}-col`);
    if (!existingDiv) {
        this.columnVisibility[column].value = !this.columnVisibility[column].value;
        const childDiv = this.renderer.createElement('div');
        // Add classes to the child div
        this.renderer.addClass(childDiv, 'column-collapsed');
        this.renderer.addClass(childDiv, 'sprint-collapsed-column');
        this.renderer.addClass(childDiv, `sprint-collapsed-${column}-col`);
          // Apply click event to the child div
          this.renderer.listen(childDiv, 'click', () => {
            this.handleColumnCollapsedClicked(column);
            this.updateColumnClick(this.columnVisibility[column].key,false,"collapse_column")
          });
        
        const pElement = this.renderer.createElement('p');
        this.renderer.addClass(pElement, 'column-collapsed-padding');
        this.renderer.appendChild(pElement, this.renderer.createText(columnLabel));
        this.renderer.appendChild(childDiv, pElement);
        this.renderer.appendChild(wrapperDiv, childDiv);
        this.columnVisibility[column].value = true;
        this.applyBackgroundColor()
        if (!reload) { this.updateColumnClick(this.columnVisibility[column].key,true,'collapse_column') }
    }
    this.cdr.detectChanges();
  }
  handleColumnCollapsedClicked(column: string) {
    column = column.replace(/\s+/g, '')
    const wrapperDiv = this.e1.nativeElement.querySelector('.project-collapsed-columns-wrapper');
    this.columnVisibility[column].value = false;

    const existingDiv = wrapperDiv.querySelector(`.sprint-collapsed-${column}-col`);
      if (existingDiv) {
        this.renderer.removeChild(wrapperDiv, existingDiv);
        this.applyBackgroundColor()
      }
  }

  getObjectKeyByValue(obj: any, value: string) {
    for (const column in obj) {
        if (obj.hasOwnProperty(column) && obj[column].key === value) {
            return column;
        }
    }
    return null;
}
  // collapsed setting Btn

  onDocumentClick(event: Event): void {
    const dropdown = this.collapseSettingDropDown.nativeElement;
    const dropDownBtn = this.collapseSettingButton.nativeElement;
    const isClickInsideElement = dropdown.contains(event.target);
    const isClickOnDropdownBtn = dropDownBtn.contains(event.target);
    if (!isClickInsideElement && !isClickOnDropdownBtn) {
      this.isCollapseSettingsVisible = false;
      this.cdr.detectChanges();
    } else {
      if (isClickOnDropdownBtn) {
        this.isCollapseSettingsVisible = !this.isCollapseSettingsVisible;
        this.cdr.detectChanges();
      }
    }
  }
  

  handleSelectAll(){
    for (let key in this.columnVisibilitySettings) {
      if (this.columnVisibilitySettings.hasOwnProperty(key)) {
        this.columnVisibilitySettings[key].value = true;
      }
    }
    this.updateSelectAll();
  }

  updateSelectAll() {
    const formData = new FormData();
    formData.append('select_all', true.toString());
    formData.append('column_type', 'show_column');
    this.http
    .patch(
      `api/v3/projects/${this.projectId}/sprint_planning_settings/${this.sprintPlanningSettingId}/backlog_setting/${this.backlogSettingId}`,
      formData
    )
    .subscribe((data: any) => {
      if (data && data.success == true) {
        this.backlogSettingId = data?.sprint_planning_setting?.id;
        this.sprintPlanningSettingId = data?.sprint_planning_setting?.sprint_planning_setting_id;
        this.setColumnSettingsData(data.sprint_planning_setting);
        this.cdr.detectChanges();
      }
    })
  }

  updateColumnClick(key: string, value: boolean, column_type: 'show_column' | 'collapse_column') {
    const formData = new FormData();
    formData.append('key', key.toString());
    formData.append('value', value.toString());
    formData.append('column_type', column_type);
    this.http
    .patch(
      `api/v3/projects/${this.projectId}/sprint_planning_settings/${this.sprintPlanningSettingId}/backlog_setting/${this.backlogSettingId}`,
      formData
    )
    .subscribe((data: any) => {
      if (data && data.success == true) {
        this.backlogSettingId = data?.sprint_planning_setting?.id;
        this.sprintPlanningSettingId = data?.sprint_planning_setting?.sprint_planning_setting_id;
        this.setColumnSettingsData(data.sprint_planning_setting);
        this.cdr.detectChanges();
      }
    })
  }

  toggleActiveClass(column: string) {
    column = column.replace(/\s+/g, '')
    if(this.columnVisibilitySettings[column].value){
      this.handleColumnCollapsedClicked(column)
      this.columnVisibilitySettings[column].value = false
      this.columnVisibility[column].value = true
      this.updateColumnClick(this.columnVisibilitySettings[column].key,false,'show_column')
    }
    else{
      this.columnVisibilitySettings[column].value = true
      this.columnVisibility[column].value = false
      this.updateColumnClick(this.columnVisibilitySettings[column].key,true,'show_column')
    }

  }

  toggleActiveSettingClass(column: string) {
    column = column.replace(/\s+/g, '')
    if(this.columnVisibilitySettings[column].value){
      this.handleColumnCollapsedClicked(column)
      this.columnVisibilitySettings[column].value = false
      this.columnVisibility[column].value = true
    }
    else{
      this.columnVisibilitySettings[column].value = true
      this.columnVisibility[column].value = false
    }

  }

  applyBackgroundColor() {
    const wrapperDiv = this.e1.nativeElement.querySelector('.project-collapsed-columns-wrapper');

    if (wrapperDiv) {
      const childElements = wrapperDiv.children;

      for (let i = 0; i < childElements.length; i++) {
        const childElement = childElements[i] as HTMLElement;
        if (i % 2 !== 0) {
          childElement.style.backgroundColor = '#F3F3F3';
        }
        else{
          childElement.style.backgroundColor = '#fff';
        }
      }
    }
  }

  getContrastColor(hexColor: string): string {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    if (luminance > 0.5) {
        return '#000000';
    } else {
        return '#ffffff';
    }
  }

}
