<op-user-autocompleter
  class="inline-edit--field {{ handler.fieldName }}"
  *ngIf="url"
  [clearAfterSelection]="false"
  [url]="url"
  [inviteUserToProject]="resource.project?.id"
  [focusDirectly]="!(handler.inEditMode || isNew)"
  [openDirectly]="!(handler.inEditMode || isNew)"
  appendTo="body"
  [value]="value"
  [id]="handler.htmlId"
  (valueChange)="onModelChange($event)"
  (cancel)="handler.handleUserCancel()"
  (userInvited)="onModelChange($event)"
></op-user-autocompleter>
