import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "ticket-activity",
  templateUrl: "./ticket-activity.component.html",
  styleUrls: ["./ticket-activity.component.scss"],
})
export class TicketActivityComponent {
  @Input() activity: { [key: string]: any };
  @Input() ticketCreateActivity: Boolean;

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private el: ElementRef) {}

  getUserFullName(user: any): String {
    return user?.firstname + ' ' + user?.lastname || ''
  }

  getUserInitials(user: any): String {
    return ((user?.firstname[0] || '') + (user?.lastname[0] || '')).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  stringToDateConverter(dateString: string): any {
    const [month, day, year, time] = dateString.split('/');

    const [hour, minuteMeridium] = time.split(':');
    const [minute, meridium] = minuteMeridium.split(' ');

    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), parseInt(hour, 10), parseInt(minute, 10));
  }

  formatDateToString(activityDate: string): string {
    let date = this.stringToDateConverter(activityDate);
    date = this.changeDateTimezone(date, 5);
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const dateString = date.toLocaleDateString('en-US', dateOptions);
    const timeString = date.toLocaleTimeString('en-US', timeOptions);
    const activityStatus = this.ticketCreateActivity ? 'created' : 'updated';
    return `${activityStatus} on ${dateString} at ${timeString}`;
  }

  changeDateTimezone(dateToConvert: Date, hours: number): Date {
    let utcTime = dateToConvert.getTime();
    let offsetInMinutes = hours * 60;
    return new Date(utcTime + offsetInMinutes * 60000);
  }

}
