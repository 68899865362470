import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  OnInit
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ICKEditorContext,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor.types';
import {
  ICKEditorType,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor-setup.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';

@Component({
  selector: "ticket-detail",
  templateUrl: "./ticket-detail.component.html",
  styleUrls: ["./ticket-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketDetailComponent implements OnInit {
  @Input() ticket: { [key: string]: any };
  @Input() labels: any[] = [];
  @Input() colors: any[] = [];
  @Input() members: any[] = [];
  @Input() projectId: number;
  @Input() taskTypes: any[] = [];
  @Input() ticketStatus: any[] = [];
  @Input() taskPriorities: any[] = [];
  @Output() updateShowEvent = new EventEmitter<void>();
  @Output() updatedTicketData = new EventEmitter<{ [key: string]: any }>();
  @Output() errorMessage = new EventEmitter<string>();
  @Output() successMessage = new EventEmitter<string>();

  @ViewChild("watcherWrapper") watcherWrapper: ElementRef;
  @ViewChild("labelsDropdownContainer") labelsDropdownContainer: ElementRef;
  @ViewChild("memberDropdownWrapper") memberDropdownWrapper: ElementRef;
  @ViewChild("addChildMemberDropdown") addChildMemberDropdown: ElementRef;
  @ViewChild("childOptionWrapper") childOptionWrapper: ElementRef;
  @ViewChild("ticketStoryPoints") ticketStoryPoints: ElementRef;
  @ViewChild("createChildInput") createChildInput: ElementRef;
  @ViewChild("newLabelTitle") newLabelTitle: ElementRef;


  public isShowDetailsCollaps = true;
  public isShowWatcherDropdown = false;
  public isShowChildWrapper = "none";
  public isShowLabelDropdown = false;
  public isShowLabelCreateWrapper = false;
  public isShowLabelSelectWrapper = true;
  public isShowAddMemberListWrapper = false;
  public isShowParentChildField = false;
  public isShowChildMemberMenu = false;
  public isShowMenuDrop = false;
  public isShowChildOption = false;
  public isShowUpdates = false;
  public isMultiLabelVisible = "none";
  public searchedMembers: { [key: string]: any }[] = [];
  public searchedLabels: { [key: string]: any }[] = [];
  public seachedChildTickets: any[]= [];
  public selectedChildTicket: any;
  public childAddButtonVisible = false;
  public selectedColorId: null | number = null;
  public updatedStoryPoints: any = {};
  public updatedTicket: {
    priority_id: number,
    status_id: number,
    type_id: number,
    work_package_assignees_attributes: any[],
    story_points: number,
    subject: string,
    description: string,
    assignee_story_point: any,
    start_date: string,
    due_date: string,
  } = {
    priority_id: 0,
    status_id: 0,
    type_id: 0,
    work_package_assignees_attributes: [],
    story_points: 0,
    subject: "",
    description: "",
    assignee_story_point: 0,
    start_date: '',
    due_date: '',
  };

  ckEditorContext: ICKEditorContext = {
    type: "constrained" as ICKEditorType,
    resource: {} as HalResource,
    removePlugins: ['plugin1', 'plugin2'],
    macros: false,
    options: {
      rtl: false,
    },
    previewContext: undefined,
    disabledMentions: undefined,
  };
  


  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    this.searchedLabels = this.labels;
    this.updatedTicket = {
      priority_id: 0,
      status_id: 0,
      type_id: 0,
      story_points: 0,
      subject: "",
      description: "",
      work_package_assignees_attributes: [],
      assignee_story_point: 0,
      start_date: '',
      due_date: '',
    };
    this.setUpdatedTicketData();
    this.setEditedAssigneeStoryPoints();
  }

  onContentChange(value:string) {
    this.updatedTicket.description = value;
  }

  emitUpdatedTicketData() {
    this.updatedTicketData.emit(this.ticket);
  }

  emitErrorMessage(message: string) {
    this.errorMessage.emit(message);
  }

  emitSuccessMessage(message: string) {
    this.successMessage.emit(message);
  }

  setUpdatedTicketData() {
    if (this.ticket) {
      this.updatedTicket.priority_id = this.ticket?.priority?.id || 0;
      this.updatedTicket.status_id = this.ticket?.status?.id || 0;
      this.updatedTicket.type_id = this.ticket?.type?.id || 0;
      this.updatedTicket.story_points = this.ticket?.story_points || 0;
      this.updatedTicket.subject = this.ticket?.subject || '';
      this.updatedTicket.description = this.ticket?.description || '';
      this.updatedTicket.start_date = this.ticket?.start_date !== 'None' ? this.formatDateToYYYYMMDD(this.ticket?.start_date) : '';
      this.updatedTicket.due_date = this.ticket?.due_date !== 'None' ? this.formatDateToYYYYMMDD(this.ticket?.due_date) : '';
      this.updatedTicket.work_package_assignees_attributes = []
      this.updatedTicket.assignee_story_point = 0
      this.ticket?.all_assignees_with_story_points.forEach((assignee: any) => {
        if (assignee.id == null) {
          this.updatedTicket.assignee_story_point = assignee?.assignee_story_point || 0;
        } else {
          const existingAssigneeIndex = this.updatedTicket.work_package_assignees_attributes.findIndex(
            (existingAssignee: any) => existingAssignee.id == assignee?.id
          );

          if (existingAssigneeIndex !== -1) {
            this.updatedTicket.work_package_assignees_attributes[existingAssigneeIndex].assignee_story_point =
              assignee?.assignee_story_point || 0;
          } else {
            this.updatedTicket.work_package_assignees_attributes.push({
              id: assignee?.id,
              assignee_story_point: (assignee?.assignee_story_point || 0)
            });
          }
        }
      });
    }
  }

  setEditedAssigneeStoryPoints() {
    this.ticket?.all_assignees_with_story_points.forEach((assignee: any) => {
      this.updatedStoryPoints[assignee.assigned_to?.id] = assignee?.assignee_story_point || 0;
    });
    this.updatedStoryPoints[0] = this.ticket?.story_points
  }

  updateEditedAssigneeStoryPoints() {
    this.ticket?.all_assignees_with_story_points.forEach((assignee: any) => {
      if (assignee.id == null) {
        this.updatedTicket.assignee_story_point = this.updatedStoryPoints[assignee.assigned_to?.id] || 0;
      } else {
        const existingAssigneeIndex = this.updatedTicket.work_package_assignees_attributes.findIndex(
          (existingAssignee: any) => existingAssignee.id == assignee?.id
        );
        this.updatedTicket.work_package_assignees_attributes[existingAssigneeIndex].assignee_story_point =
          this.updatedStoryPoints[assignee.assigned_to?.id] || 0;
      }
    });
    this.updatedTicket.story_points = this.updatedStoryPoints[0];
  }

  validateDueDate(event: Event) {
    const errorMessage = "<ul><li>End Date should be greater than Start Date</li></ul>";
    if (this.updatedTicket.start_date && this.updatedTicket.due_date && (this.updatedTicket.start_date > this.updatedTicket.due_date)) {
      const dateInput = event.target as HTMLInputElement;
      dateInput.value = '';
      if (dateInput.id == 'edit-end-date-inp') {
        this.updatedTicket.due_date = '';
      } else {
        this.updatedTicket.start_date = '';
      }
      this.emitErrorMessage(errorMessage);
    }
  }

  formatDateToYYYYMMDD(dateString: string): string {
    const dateObject = new Date(dateString);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  removePrecedingZeros(inputString: string): number {
    return Number(inputString.replace(/^0+/, ''));
  }

  updateAssigneeStoryPoint(value: any, assignee: any) {
    if (assignee?.id == null) {
      if (value < 0) {
        this.updatedTicket.assignee_story_point = 0;
        this.el.nativeElement.querySelector(`.assignee-story-point-${assignee?.assigned_to?.id}`).value = 0;
        this.updatedStoryPoints[assignee?.assigned_to?.id] = 0;
      }
      else if (value == '') {
        this.updatedTicket.assignee_story_point = "";
        this.el.nativeElement.querySelector(`.assignee-story-point-${assignee?.assigned_to?.id}`).value = '';
        this.updatedStoryPoints[assignee?.assigned_to?.id] = 0;
      } else {
        this.updatedTicket.assignee_story_point = this.removePrecedingZeros(value);
        this.updatedStoryPoints[assignee?.assigned_to?.id] = this.removePrecedingZeros(value);
      }
    }
    else {
      const indexToUpdate = this.updatedTicket.work_package_assignees_attributes.findIndex(
        (entry: any) => entry.id === assignee?.id
      );
      if (indexToUpdate !== -1) {
        if (value < 0) {
          this.updatedTicket.work_package_assignees_attributes[indexToUpdate].assignee_story_point = 0;
          this.el.nativeElement.querySelector(`.assignee-story-point-${assignee?.assigned_to?.id}`).value = 0;
          this.updatedStoryPoints[assignee?.assigned_to?.id] = 0;
        } else if (value == '') {
          this.updatedTicket.work_package_assignees_attributes[indexToUpdate].assignee_story_point = "";
          this.el.nativeElement.querySelector(`.assignee-story-point-${assignee?.assigned_to?.id}`).value = '';
          this.updatedStoryPoints[assignee?.assigned_to?.id] = 0;
        } else {
          this.updatedTicket.work_package_assignees_attributes[indexToUpdate].assignee_story_point = this.removePrecedingZeros(value);
          this.updatedStoryPoints[assignee?.assigned_to?.id] = this.removePrecedingZeros(value);
        }
      }
    }
    this.updateTotalStoryPoints();
  }

  updateTotalStoryPoints() {
    const updatedTotalStoryPoints =
      +this.updatedTicket.assignee_story_point +
      this.updatedTicket.work_package_assignees_attributes.reduce(
        (sum, assignee) => sum + (+assignee.assignee_story_point || 0),
        0
      );
    if (this.updatedTicket.story_points < updatedTotalStoryPoints) {
      this.ticketStoryPoints.nativeElement.value = updatedTotalStoryPoints;
      this.updatedTicket.story_points = updatedTotalStoryPoints;
      this.updatedStoryPoints[0] = this.updatedTicket.story_points;
    }
  }

  validateInput() {
    const inputValue = this.el.nativeElement.querySelector('#story-points-inp').value;

    if (inputValue < 0) {
      this.updatedTicket.story_points = 0;
      this.el.nativeElement.querySelector('#story-points-inp').value = 0;
    } else if (inputValue == '') {
      this.updatedTicket.story_points = 0;
      this.el.nativeElement.querySelector('#story-points-inp').value = '';
    }
    this.updatedStoryPoints[0] = this.updatedTicket.story_points;
  }

  getAssigneeStoryPoint(assigneeId :number) {
    if (assigneeId) {
      const existingAssigneeIndex = this.updatedTicket.work_package_assignees_attributes.findIndex(
        (existingAssignee: any) => existingAssignee.id == assigneeId
      );
      if (existingAssigneeIndex !== -1) {
        return this.updatedTicket.work_package_assignees_attributes[existingAssigneeIndex].assignee_story_point;
      } else {
        return 0;
      }
    }
    return this.updatedTicket?.assignee_story_point;
  }

  clossDetailModel() {
    this.updateShowEvent.emit();
  }
  toggleDetailsCollapse() {
    this.isShowDetailsCollaps = !this.isShowDetailsCollaps;
  }
  toggleWatcherDropdown() {
    this.isShowWatcherDropdown = !this.isShowWatcherDropdown;
  }
  addNewChildWrapper(show: boolean) {
    if(show){
      this.isShowChildWrapper = "block"
    } else {
      this.setSearchChildInput(null);
      this.isShowChildWrapper = "none"
    }
  }
  toggleMultiLabelDetail(show: 'none' | 'block') {
    this.isMultiLabelVisible = show;
  }
  showLabelsDropdown(show: boolean) {
    this.isShowLabelDropdown = show;
    this.selectedColorId = null;
    this.newLabelTitle.nativeElement.value = ""
  }
  showLabelCreateWrapper(show: boolean) {
    this.isShowLabelCreateWrapper = show;
    if(show) {
      this.isShowLabelSelectWrapper = false;
    } else {
      this.isShowLabelSelectWrapper = true;
      this.selectedColorId = null;
      this.newLabelTitle.nativeElement.value = "";
    }
  }
  toggleMemberListWrapper() {
    this.isShowAddMemberListWrapper = !this.isShowAddMemberListWrapper
  }
  toggleParentChildField() {
    this.isShowParentChildField = !this.isShowParentChildField
  }
  toggleChildMemberMenu() {
    this.isShowChildMemberMenu = !this.isShowChildMemberMenu
  }
  toggleChildMenuDrop() {
    this.isShowMenuDrop = !this.isShowMenuDrop;
  }
  toggleChildOptionMenu() {
    this.isShowChildOption = !this.isShowChildOption
  }
  editTicketDetail(show: boolean) {
    if(show){
      this.setUpdatedTicketData();
      this.updateTotalStoryPoints();
      this.isShowUpdates = show;
      this.cdr.detectChanges();
    } else {
      this.onTicketChange();
      this.isShowUpdates = show;
    }
  }

  isMember(user: any):boolean {
    return (this.ticket?.all_assignees?.findIndex((member: any) => member?.id == user.id) > -1) ? true : false;
  }

  removeTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
  
    const formData = new FormData();
    formData.append('work_package_id', this.ticket?.id?.toString());
  
    this.http
      .delete(
        `projects/${this.projectId}/labels/${labelId}/remove_from_work_package`,
        { headers, body: formData }
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(labelId);
          if (labelIndex !== -1 && this.ticket) {
            this.ticket?.labels.splice(labelIndex, 1);
          }
          this.emitUpdatedTicketData();
        }
        this.cdr.detectChanges();
      });
  }

  renameTicketLabel(labelId: number, title: string): void {
    const formData = new FormData();
    formData.append('title', title);
    
    this.http
      .patch(
        `projects/${this.projectId}/labels/${labelId}/rename_label`,
        formData
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(data?.label?.id);
          const projectLabelIndex = this.projectLabelIndex(data?.label?.id);
          if (labelIndex !== -1 && this.ticket) {
            this.ticket.labels[labelIndex].title = data?.label?.title;
          }
          if (projectLabelIndex !== -1 && this.labels) {
            this.labels[projectLabelIndex].title = data?.label?.title;
            this.searchedLabels = this.labels;
          }
          this.emitUpdatedTicketData();
          this.emitSuccessMessage('Label Updated Successfully');
        } else if(data && data?.errors && data?.errors.length > 0) {
            let errorMessage = '<ul>';
            data.errors.forEach((error: string)=>{
              errorMessage += `<li>${error}</li>`
            })
            errorMessage += `</ul>`;
            this.emitErrorMessage(errorMessage);
        }
        this.cdr.detectChanges();
      });
  }

  createTicketLabel(title: string): void {
    const formData = new FormData();
    formData.append('title', title);
    if (this.selectedColorId) { formData.append('color', this.selectedColorId.toString()); }
    formData.append('workPackageId', this.ticket?.id);
    
    this.http
      .post(
        `projects/${this.projectId}/labels`,
        formData
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const colorIndex = this.colorIndex(data?.label?.color_id);
          this.ticket.labels.push(data?.label);
          this.labels.push(data?.label);
          this.searchedLabels = this.labels;
          if (colorIndex !== -1 && this.colors) {
            this.colors.splice(colorIndex, 1);
          }
          this.newLabelTitle.nativeElement.value = ""
          this.emitUpdatedTicketData();
          this.emitSuccessMessage('Label Created Successfully');
        }
        else if(data && data?.succes == false){
          if (data.errors && data.errors.length > 0) {
            let errorMessage = '<ul>';
            data.errors.forEach((error: string)=>{
              errorMessage += `<li>${error}</li>`
            })
            errorMessage += `</ul>`;
            this.emitErrorMessage(errorMessage);
          }
        }
        this.cdr.detectChanges();
      });
  }

  deleteTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  
    this.http
      .delete(
        `projects/${this.projectId}/labels/${labelId}`,
        { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(data?.label?.id);
          const projectLabelIndex = this.projectLabelIndex(data?.label?.id);
          if (labelIndex !== -1 && this.ticket) {
            this.ticket?.labels.splice(labelIndex, 1);
          }
          if (projectLabelIndex !== -1 && this.labels) {
            this.labels.splice(projectLabelIndex, 1);
            this.searchedLabels = this.labels;
          }
          this.emitUpdatedTicketData();
          this.emitSuccessMessage('Label Deleted Successfully');
        }
        this.cdr.detectChanges();
      });
  }

  addTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  
    this.http
      .put(
        `projects/${this.projectId}/labels/${labelId}?work_package_id=${this.ticket?.id}&checked=false`,
        { headers }
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticket.labels = data;
          this.emitUpdatedTicketData();
        }
        this.cdr.detectChanges();
      });
  }

  isTicketLabel(labelId: number):boolean {
    const labelIndex = this.labelIndex(labelId);
    return (labelIndex > -1) ? true : false;
  }

  isSelectedColor(colorId: number):boolean {
    return (colorId == this.selectedColorId) ? true : false;
  }

  labelIndex(labelId: number):number {
    return this.ticket?.labels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  colorIndex(colorId: number):number {
    return this.colors?.findIndex(
      (color: any) => color?.id == colorId
    );
  }

  projectLabelIndex(labelId: number):number {
    return this.labels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  selectLabelColor(colorId: number) {
    this.selectedColorId = colorId;
  }

  toggleLabel(labelId: number): void{
    if (this.isTicketLabel(labelId)) {
      this.removeTicketLabel(labelId)
    } else {
      this.addTicketLabel(labelId)
    }
  }

  toggleAssignee(user: any): void {
    if(this.isMember(user)) {
      this.removeAssignee(user)
    } else {
      this.addAssignee(user)
    }
  }

  removeAssignee(user: any): void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('project_id',this.projectId.toString());
    formData.append('assigned_to_id',user?.id?.toString());
    
    this.http
      .put(
        `api/v1/work_packages/${this.ticket?.id}/remove_assignee`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticket.all_assignees = data?.all_assignees;
          this.ticket.all_assignees_with_story_points = data?.all_assignees_with_story_points;
          this.setUpdatedTicketData();
          this.updatedStoryPoints[0] -= this.updatedStoryPoints[user?.id]
          this.updateEditedAssigneeStoryPoints();
          delete this.updatedStoryPoints[user?.id];
          this.emitUpdatedTicketData();
        }
        this.cdr.detectChanges();
      });
  }

  addAssignee(user: any): void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('assigned_to_id',user?.id?.toString());
    
    this.http
      .put(
        `api/v1/work_packages/${this.ticket?.id}/add_assignee`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticket.all_assignees = data?.all_assignees;
          this.ticket.all_assignees_with_story_points = data?.all_assignees_with_story_points;
          this.setUpdatedTicketData();
          this.updateEditedAssigneeStoryPoints();
          this.emitUpdatedTicketData();
        }
        this.cdr.detectChanges();
      });
  }

  onChildSearch(query: string):void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    
    this.http
      .get(
        `api/v4/work_packages/${this.ticket?.id}/search_issue?query=${query}`, {headers}
      )
      .subscribe((data: any) => {
        this.seachedChildTickets = data
        this.cdr.detectChanges();
      });
  }

  setSearchChildInput(child :any): void {
    this.selectedChildTicket = child;
    if (child) {
      this.createChildInput.nativeElement.value = `${this.selectedChildTicket.id}- ${this.selectedChildTicket.subject}`;
      this.childAddButtonVisible = true;
    } else {
      this.createChildInput.nativeElement.value = '';
    }
    this.seachedChildTickets = [];
  }

  setTicketChild() {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append(`work_package[parent_id]`, this.ticket?.id);
    
    this.http
      .patch(
        `api/v4/work_packages/${this.selectedChildTicket?.id}`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data?.success == true) {
          const childIndex = this.ticket?.children.findIndex((child: any) => child.id == this.selectedChildTicket?.id);
          if (childIndex == -1) {
            this.ticket?.children.push(data.work_package);
          } else {
            this.ticket.children[childIndex] = data.work_package;
          }
          this.addNewChildWrapper(false);
          this.emitUpdatedTicketData();
        }
        this.cdr.detectChanges();
      });
  }

  onTicketChange(): void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('read_only', true.toString());
    for (const key in this.updatedTicket) {
      if (this.updatedTicket.hasOwnProperty(key)) {
        if (key == 'work_package_assignees_attributes') {
          this.updatedTicket.work_package_assignees_attributes.forEach((assignee: any, index: number) => {
            formData.append(`work_package[${key}][${index}][id]`, assignee.id.toString());
            formData.append(`work_package[${key}][${index}][assignee_story_point]`, (assignee?.assignee_story_point || 0).toString());
          });
        } else if (['start_date', 'due_date'].includes(key)) {
          formData.append(`work_package[${key}]`, (this.updatedTicket as any)[key] ? (this.updatedTicket as any)[key].toString() : '');
        } else if (key == 'assignee_story_point') {
          formData.append(`work_package[${key}]`, ((this.updatedTicket as any)[key] || 0).toString());
        } else {
          formData.append(`work_package[${key}]`, (this.updatedTicket as any)[key].toString());
        }
      }
    }
    

    this.http
      .patch(
        `api/v4/work_packages/${this.ticket?.id}`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data && data.success == true) {
          this.updateTicketData(data);
          this.setEditedAssigneeStoryPoints();
          this.emitUpdatedTicketData(); 
          this.emitSuccessMessage('Task Updated Successfully');
        } else if(data && data.success == false) {
          if (data.errors && data.errors.length > 0) {
            let errorMessage = '<ul>';
            data.errors.forEach((error: string)=>{
              errorMessage += `<li>${error}</li>`
            })
            errorMessage += `</ul>`;
            this.emitErrorMessage(errorMessage);
          }
        }
        this.cdr.detectChanges();
      });
  }

  updateTicketData(data: any): void {
    this.ticket.priority = this.taskPriorities.find((priority) => priority.id == this.updatedTicket.priority_id);
    this.ticket.status = this.ticketStatus.find((status) => status.id == this.updatedTicket.status_id);
    this.ticket.type = this.taskTypes.find((type) => type.id == this.updatedTicket.type_id);
    this.ticket.story_points = data?.work_package?.story_points;
    this.ticket.subject = data?.work_package?.subject;
    this.ticket.description = data?.work_package?.description;
    this.ticket.all_assignees_with_story_points = data?.work_package?.all_assignees_with_story_points;
    this.ticket.due_date = data?.work_package?.due_date ? data?.work_package?.due_date : 'None';
    this.ticket.start_date = data?.work_package?.start_date ? data?.work_package?.start_date : 'None';
  }

  getUserFullName(user: any): String {
    return user?.firstname + ' ' + user?.lastname || ''
  }

  getUserInitials(user: any): String {
    return (user?.firstname[0] + user?.lastname[0]).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  parseCreatedDate(dateString: string): string {
    const inputDate = new Date(dateString);
  
    const formattedDate = inputDate.toISOString()
      .replace('T', ' ')
      .replace(/\.\d+Z/, '');
  
    const [hours, minutes, seconds] = formattedDate.split(' ')[1].split(':');
  
    let formattedHours = parseInt(hours, 10);
    const ampm = formattedHours >= 12 ? 'pm' : 'am';
    formattedHours = formattedHours % 12 || 12;
  
    const result = `${formattedDate.split(' ')[0]} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
    return result;
  }
  

  @HostListener("document:click", ["$event"])
  clickOutside(event: Event) {
    if (!this.watcherWrapper?.nativeElement.contains(event.target)) {
      this.isShowWatcherDropdown = false;
    }
    if (!this.labelsDropdownContainer?.nativeElement.contains(event.target)) {
      this.isShowLabelDropdown = false;
    }
    if (!this.memberDropdownWrapper?.nativeElement.contains(event.target)) {
      this.isShowAddMemberListWrapper = false;
    }
    if (!this.addChildMemberDropdown?.nativeElement.contains(event.target)) {
      this.isShowChildMemberMenu = false;
    }
    if (!this.childOptionWrapper?.nativeElement.contains(event.target)) {
      this.isShowChildOption = false;
    }
    
  }

  deleteAttachment(attachmentId: Number): void{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .delete(
        `api/v3/attachments/${attachmentId}`, {headers}
      )
      .subscribe(() => {
        this.deleteAttachmentData(attachmentId)
        this.emitSuccessMessage('Attachment deleted successfully.');
        this.cdr.detectChanges();
      });
  }

  deleteAttachmentData(attachmentId: Number): void{
    const attachmentIndex = this.ticket?.attachments.findIndex(
      (attachment: any) => attachment.id === attachmentId
    );
    if (attachmentIndex !== -1 && this.ticket) {
      this.ticket.attachments.splice(attachmentIndex, 1);
    }
  }

  onSelectWatcher(event: any): void {
    const selectedUserId = event.target.value;

    this.createWatcher(selectedUserId, this.ticket?.id);

    const selectElement = this.el.nativeElement.querySelector('#task-select-watcher-id');
    selectElement.value = '';
  }

  createWatcher(userId: Number, ticketId: Number): void{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = { user_id: userId };

    this.http
      .put(
        `api/v1/work_packages/${ticketId}/create_watcher`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.createWatcherData(data);
        this.cdr.detectChanges();
      });
  }

  createWatcherData(newWatcher: any): void{
    const watcherIndex = this.ticket?.watchers.findIndex(
      (watcher: any) => watcher.id === newWatcher?.id
    );
    if (watcherIndex == -1 && this.ticket) {
      this.ticket.watchers.push(newWatcher);
    }
  }

  deleteWatcher(userId: Number, ticketId: Number): void{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = { user_id: userId };

    this.http
      .delete(
        `api/v1/work_packages/${ticketId}/remove_watcher`, { headers, body: payload }
      )
      .subscribe((data: any) => {
        this.deleteWatcherData(data?.id);
        this.cdr.detectChanges();
      });
  }

  deleteWatcherData(watcherId: Number): void{
    const watcherIndex = this.ticket?.watchers.findIndex(
      (watcher: any) => watcher.id === watcherId
    );
    if (watcherIndex !== -1 && this.ticket) {
      this.ticket.watchers.splice(watcherIndex, 1);
    }
  }

  onFileSelected(event: any): void {
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;

    if (files && files.length > 0) {
      const file = files[0];

      const formData = new FormData();
      formData.append('file', file);
      formData.append('metadata', JSON.stringify({ fileName: file.name }));

      const headers = new HttpHeaders();
      headers.set('Content-Type', 'multipart/form-data');
      this.createAttachment(this.ticket?.id, formData, headers, fileInput);
    }
  }

  createAttachment(ticketId: number, formData: FormData, headers: HttpHeaders, fileInput: HTMLInputElement): void {
    this.http.post(`api/v3/work_packages/${ticketId}/attachments`, formData, { headers })
    .subscribe(
      (response) => {
        this.ticket?.attachments.push(response);
        this.emitSuccessMessage('Attachment uploaded successfully.');
        fileInput.value = '';
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error creating attachment', error);
      }
    );
  }

  deleteAssignee(ticketId: number, assigneeId: number): void {
    const formData = new FormData();
    formData.append('assigned_to_id', assigneeId.toString());
    formData.append('project_id', this.projectId.toString());

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    this.http.put(`api/v1/work_packages/${ticketId}/remove_assignee`, formData, { headers }).subscribe(
      (response: any) => {
        const childIndex = this.ticket?.children.findIndex(
          (child: any) => child.id === response?.id
        );
        if (childIndex !== -1 && this.ticket) {
          const assigneeIndex = this.ticket?.children[childIndex]?.all_assignees.findIndex(
            (assignee: any) => assignee.id == assigneeId
          );
          if (assigneeIndex !== -1) {
            this.ticket?.children[childIndex]?.all_assignees.splice(assigneeIndex, 1);
          }
        }
        this.emitUpdatedTicketData();
        this.cdr.detectChanges();
      },
      (error) => {
        console.error('Error deleting assignee', error);
      }
    );
  }

  onSelectChildAssignee(event: any, ticketId: Number): void {
    const selectedUserId = event.target.value;

    this.createChildAssignee(selectedUserId, ticketId, true);

    const selectElement = this.el.nativeElement.querySelector('#task-select-child-assignee-id');
    selectElement.value = '';
  }

  createChildAssignee(userId: Number, ticketId: Number, childAssignee: true | false): void{
    const formData = new FormData();
    formData.append('assigned_to_id', userId.toString());

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    this.http
      .put(
        `api/v1/work_packages/${ticketId}/add_assignee`, formData, { headers }
      )
      .subscribe((data: any) => {
        if (childAssignee) {
          this.createChildAssigneeData(data.all_assignees.find((assignee: any)=>assignee.id == userId));
        }
        else {

        }
        this.cdr.detectChanges();
      });
  }

  createChildAssigneeData(newAssignee: any): void{
    const childIndex = this.ticket?.children.findIndex(
      (child: any) => child.id === newAssignee?.id
    );
    if (childIndex !== -1 && this.ticket) {
      const assigneeIndex = this.ticket?.children[childIndex]?.all_assignees.findIndex(
        (assignee: any) => assignee.id == newAssignee?.id
      );
      if (assigneeIndex == -1) {
        this.ticket?.children[childIndex]?.all_assignees.push(newAssignee);
      }
    }
  }

  searchMembers(search: string): void {
    if (search.trim() == "") {
      this.searchedMembers = this.members;
    } else {
      this.searchedMembers = this.members.filter((member) => {
        const fullName = `${member?.user?.firstname} ${member?.user?.lastname}`.toLowerCase();
        return fullName.includes(search.toLowerCase());
      });
    }
  }

  searchLabels(search: string): void {
    if (search.trim() == "") {
      this.searchedLabels = this.labels;
    } else {
      this.searchedLabels = this.labels.filter((label) => {
        return label?.title.toLowerCase().includes(search.trim().toLowerCase())
      });
    }
  }

  toggleEditMode(labelId: number, updatedTitle: string | null = null): void {
    this.searchedLabels = this.searchedLabels.map(label => {
      if (label.id === labelId) {
        label.editMode = label.editMode == null ? true : !label.editMode;
        if (!label.editMode && updatedTitle && updatedTitle.length > 0) {
          this.renameTicketLabel(labelId, updatedTitle);
        }
      }
      return label;
    });
  }

  getInitials(firstName: string, lastName: string): string {
    return (firstName[0] + lastName[0]).toUpperCase();
  }

  getContrastColor(hexColor: string): string {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    if (luminance > 0.5) {
        return '#000000';
    } else {
        return '#ffffff';
    }
  }
}
