import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ChangeDetectorRef
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "project-labels",
  templateUrl: "./project-labels.component.html",
  styleUrls: ["./project-labels.component.scss"],
})
export class ProjectLabelsComponent {
  @Input() labels: any[] = [];
  @Input() colors: any[] = [];
  @Input() ticketLabels: any[] = [];
  @Input() projectId: number;
  @Input() ticketId: number;
  @Output() updatedLabels = new EventEmitter<any[]>();
  @Output() updatedTicketLabels = new EventEmitter<any[]>();
  @Output() errorMessage = new EventEmitter<string>();
  @Output() successMessage = new EventEmitter<string>();
  @Output() remove: EventEmitter<any> = new EventEmitter();

  @ViewChild("newLabelTitle") newLabelTitle: ElementRef;

  public searchedLabels: { [key: string]: any }[] = [];
  public isShowLabelCreateWrapper = false;
  public isShowLabelSelectWrapper = true;
  public selectedColorId: null | number = null;
  public isSearched: boolean = false;

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private el: ElementRef) {}

  closeLabelsDrop(){
    this.remove?.emit();
  }

  emitUpdatedLabels() {
    this.updatedLabels.emit(this.labels);
  }

  emitUpdatedTicketLabels() {
    this.updatedTicketLabels.emit(this.ticketLabels);
  }

  emitErrorMessage(message: string) {
    this.errorMessage.emit(message);
  }

  emitSuccessMessage(message: string) {
    this.successMessage.emit(message);
  }

  searchLabels(search: string): void {
    if (search.trim() == "") {
      this.searchedLabels = this.labels;
    } else {
      this.searchedLabels = this.labels.filter((label) => {
        return label?.title.toLowerCase().includes(search.trim().toLowerCase())
      });
    }
    this.isSearched = true;
  }

  showLabelCreateWrapper(show: boolean) {
    this.isShowLabelCreateWrapper = show;
    if(show) {
      this.isShowLabelSelectWrapper = false;
    } else {
      this.isShowLabelSelectWrapper = true;
      this.selectedColorId = null;
      this.newLabelTitle.nativeElement.value = "";
    }
  }

  selectLabelColor(colorId: number) {
    this.selectedColorId = colorId;
  }

  isTicketLabel(labelId: number):boolean {
    const labelIndex = this.labelIndex(labelId);
    return (labelIndex > -1) ? true : false;
  }

  isSelectedColor(colorId: number):boolean {
    return (colorId == this.selectedColorId) ? true : false;
  }

  labelIndex(labelId: number):number {
    return this.ticketLabels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  colorIndex(colorId: number):number {
    return this.colors?.findIndex(
      (color: any) => color?.id == colorId
    );
  }

  projectLabelIndex(labelId: number):number {
    return this.labels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  toggleLabel(labelId: number): void{
    if (this.isTicketLabel(labelId)) {
      this.removeTicketLabel(labelId)
    } else {
      this.addTicketLabel(labelId)
    }
  }

  toggleEditMode(labelId: number, updatedTitle: string | null = null): void {
    if (this.newLabelTitle.nativeElement.value == "" && this.searchedLabels.length == 0) {
      this.searchedLabels = this.labels
    }
    this.searchedLabels = this.searchedLabels.map(label => {
      if (label.id === labelId) {
        label.editMode = label.editMode == null ? true : !label.editMode;
        if (!label.editMode && updatedTitle && updatedTitle.length > 0) {
          this.renameTicketLabel(labelId, updatedTitle);
        }
      }
      return label;
    });
  }

  renameTicketLabel(labelId: number, title: string): void {
    const formData = new FormData();
    formData.append('title', title);
    
    this.http
      .patch(
        `projects/${this.projectId}/labels/${labelId}/rename_label`,
        formData
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(data?.label?.id);
          const projectLabelIndex = this.projectLabelIndex(data?.label?.id);
          if (labelIndex !== -1 && this.ticketLabels) {
            this.ticketLabels[labelIndex].title = data?.label?.title;
          }
          if (projectLabelIndex !== -1 && this.labels) {
            this.labels[projectLabelIndex].title = data?.label?.title;
            this.searchedLabels = this.labels;
          }
          this.emitUpdatedLabels();
          this.emitUpdatedTicketLabels();
          this.emitSuccessMessage('Label Updated Successfully');
        } else if(data && data?.errors && data?.errors.length > 0) {
            let errorMessage = '<ul>';
            data.errors.forEach((error: string)=>{
              errorMessage += `<li>${error}</li>`
            })
            errorMessage += `</ul>`;
            // this.emitErrorMessage(errorMessage);
        }
        this.cdr.detectChanges();
      });
  }

  createTicketLabel(title: string): void {
    const formData = new FormData();
    formData.append('title', title);
    if (this.selectedColorId) { formData.append('color', this.selectedColorId.toString()); }
    formData.append('workPackageId', this.ticketId.toString());
    
    this.http
      .post(
        `projects/${this.projectId}/labels`,
        formData
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const colorIndex = this.colorIndex(data?.label?.color_id);
          this.ticketLabels.push(data?.label);
          this.labels.push(data?.label);
          this.searchedLabels = this.labels;
          if (colorIndex !== -1 && this.colors) {
            this.colors.splice(colorIndex, 1);
          }
          this.newLabelTitle.nativeElement.value = ""
          this.emitUpdatedLabels();
          this.emitUpdatedTicketLabels();
          this.emitSuccessMessage('Label Created Successfully');
        }
        else if(data && data?.succes == false){
          if (data.errors && data.errors.length > 0) {
            let errorMessage = '<ul>';
            data.errors.forEach((error: string)=>{
              errorMessage += `<li>${error}</li>`
            })
            errorMessage += `</ul>`;
            this.emitErrorMessage(errorMessage);
          }
        }
        this.cdr.detectChanges();
      });
  }

  addTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  
    this.http
      .put(
        `projects/${this.projectId}/labels/${labelId}?work_package_id=${this.ticketId}&checked=false`,
        { headers }
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticketLabels = data;
          this.emitUpdatedLabels();
          this.emitUpdatedTicketLabels();
          this.emitSuccessMessage('Label Added Successfully');
        }
        this.cdr.detectChanges();
      });
  }

  removeTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
  
    const formData = new FormData();
    formData.append('work_package_id', this.ticketId?.toString());
  
    this.http
      .delete(
        `projects/${this.projectId}/labels/${labelId}/remove_from_work_package`,
        { headers, body: formData }
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(labelId);
          if (labelIndex !== -1 && this.ticketLabels) {
            this.ticketLabels.splice(labelIndex, 1);
          }
          this.emitUpdatedLabels();
          this.emitUpdatedTicketLabels();
          this.emitSuccessMessage('Label Removed Successfully');
        }
        this.cdr.detectChanges();
      });
  }

  deleteTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  
    this.http
      .delete(
        `projects/${this.projectId}/labels/${labelId}`,
        { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(data?.label?.id);
          const projectLabelIndex = this.projectLabelIndex(data?.label?.id);
          if (labelIndex !== -1 && this.ticketLabels) {
            this.ticketLabels.splice(labelIndex, 1);
          }
          if (projectLabelIndex !== -1 && this.labels) {
            this.labels.splice(projectLabelIndex, 1);
            this.searchedLabels = this.labels;
          }
          this.emitUpdatedLabels();
          this.emitUpdatedTicketLabels();
          this.emitSuccessMessage('Label Deleted Successfully');
        }
        this.cdr.detectChanges();
      });
  }

  getContrastColor(hexColor: string): string {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    if (luminance > 0.5) {
        return '#000000';
    } else {
        return '#ffffff';
    }
  }
}
