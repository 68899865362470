import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ChangeDetectorRef
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ICKEditorContext,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor.types';
import {
  ICKEditorType,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor-setup.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';

@Component({
  selector: "ticket-comment",
  templateUrl: "./ticket-comment.component.html",
  styleUrls: ["./ticket-comment.component.scss"],
})
export class TicketCommentComponent {
  @Input() comment: { [key: string]: any };
  @Output() updatedCommentData = new EventEmitter<{ [key: string]: any }>();

  public isCommentEditable: Boolean = false;
  public updatedComment: string|null = null;

  ckEditorContext: ICKEditorContext = {
    type: "constrained" as ICKEditorType,
    resource: {} as HalResource,
    removePlugins: ['plugin1', 'plugin2'],
    macros: false,
    options: {
      rtl: false,
    },
    previewContext: undefined,
    disabledMentions: undefined,
  };

  constructor(private http: HttpClient, private cdr: ChangeDetectorRef, private el: ElementRef) {}

  getUserFullName(user: any): String {
    return user?.firstname + ' ' + user?.lastname || ''
  }

  getUserInitials(user: any): String {
    return ((user?.firstname[0] || '') + (user?.lastname[0] || '')).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  editComment() {
    this.updatedComment = this.comment.notes;
    this.isCommentEditable = true;
  }

  updateComment() {
    this.isCommentEditable = false;
    this.updateTicketComment('notes',this.updatedComment);
  }

  discardComment() {
    this.isCommentEditable = false;
    this.updatedComment = this.comment.notes;
  }

  updateTicketComment(keyToUpdate: string, value: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    if (!this.comment || !this.comment.id) {
      return;
    }
  
    let payload: { [key: string]: any } = {};
    payload[keyToUpdate] = value;
  
    this.http
      .put(`/api/v1/work_packages/${this.comment.journable_id}/comments/${this.comment.id}`, payload, { headers })
      .subscribe((data: any) => {
        if (data && data.success == true) {
          let journal = data.journals.find((j: any) => j.id == this.comment.id)
          if (journal) {
            this.comment = journal;
            this.emitUpdatedComment(journal)
          } else {
            this.comment.notes = this.updatedComment;
            this.emitUpdatedComment(this.comment);
          }
          this.cdr.detectChanges();
        }
      });
    }

  onContentChange(value:string) {
    this.updatedComment = value;
  }

  emitUpdatedComment(comment: { [key: string]: any }) {
    this.updatedCommentData.emit(comment);
  }

  stringToDateConverter(dateString: string): any {
    const [month, day, year, time] = dateString.split('/');

    const [hour, minuteMeridium] = time.split(':');
    const [minute, meridium] = minuteMeridium.split(' ');

    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), parseInt(hour, 10), parseInt(minute, 10));
  }

  formatDateToString(commentCreatedDate: string, commentUpdatedDate: string): string {
    const createdDate = this.stringToDateConverter(commentCreatedDate);
    const updatedDate = this.stringToDateConverter(commentUpdatedDate);
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    let date = createdDate < updatedDate ? updatedDate : createdDate
    date = this.changeDateTimezone(date, 5);
    const dateString = date.toLocaleDateString('en-US', dateOptions);
    const timeString = date.toLocaleTimeString('en-US', timeOptions);
    const activityStatus = createdDate < updatedDate ? 'created' : 'updated';
    return `${activityStatus} on ${dateString} at ${timeString}`;
  }

  changeDateTimezone(dateToConvert: Date, hours: number): Date {
    let utcTime = dateToConvert.getTime();
    let offsetInMinutes = hours * 60;
    return new Date(utcTime + offsetInMinutes * 60000);
  }

}
