<div class="spot-list--item-floating-wrapper op-files-tab--file-list-item-floating-wrapper">
  <a
    class="spot-list--item-action spot-list--item-action_link op-files-tab--file-list-item-action"
    [href]="attachment._links.staticDownloadLocation.href"
    target="_blank"
    (dragstart)="setDragData($event)"
  >
    <span
      class="spot-icon spot-icon_{{fileIcon.icon}} op-files-tab--icon op-files-tab--icon_{{fileIcon.clazz}}"
    ></span>

    <span
      [textContent]="attachment.fileName"
      [title]="attachment.fileName"
      class="spot-list--item-title op-files-tab--file-list-item-title"
      data-qa-selector="op-files-tab--file-list-item-title"
    ></span>

    <span
      class="op-files-tab--file-list-item-text"
      [textContent]="timestampText"
    ></span>

    <div
      #avatar
      class="op-files-tab--file-list-item-avatar"
    ></div>
  </a>
  <div
    *ngIf="!!this.attachment._links.delete"
    class="spot-list--item-floating-actions op-files-tab--file-list-item-floating-actions hidden-for-mobile"
  >
    <button
      class="spot-link"
      [title]="deleteIconTitle"
      (click)="confirmRemoveAttachment()"
    >
      <span class="spot-icon spot-icon_delete"></span>
    </button>
  </div>
</div>

<!-- Needed, if attachment-list-item is used within a form, to provide the added attachment to the serializer. -->
<input type="hidden" name="attachments[{{index}}][id]" value="{{attachment.id}}">
