<div class="spot-modal op-enterprise-modal">
  <div class="spot-modal--header">{{headerText()}}</div>

  <div class="spot-divider"></div>

  <div [ngSwitch]="openWindow()" class="spot-modal--body spot-container">
    <!-- first modal window -->
    <div *ngSwitchCase="1">
      <enterprise-trial-form></enterprise-trial-form>
    </div>
    <!-- second modal window -->
    <div *ngSwitchCase="2">
      <enterprise-trial-waiting></enterprise-trial-waiting>
    </div>
    <!-- third modal window -->
    <div *ngSwitchCase="3">
      <div class="onboarding--video-block">
        <div class="onboarding--video-text">
          <span>{{ text.quick_overview }}</span>
        </div>
        <div class="onboarding--video iframe-target-wrapper">
          <iframe frameborder="0"
                  height="400"
                  width="100%"
                  [src]="trustedEEVideoURL"
                  allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <div *ngIf="!eeTrialService.status || eeTrialService.cancelled; else mailSubmitted"
           class="spot-action-bar--right">
        <button
          class="button button_no-margin spot-action-bar--action"
          data-qa-selector="confirmation-modal--cancel"
          (click)="closeModal($event)"
          [textContent]="text.button_cancel"
          [attr.title]="text.button_cancel"
        ></button>
        <button
          class="button button_no-margin -highlight spot-action-bar--action"
          data-qa-selector="confirmation-modal--confirmed"
          (click)="onSubmit()"
          [disabled]="!trialForm || trialForm.invalid"
          [textContent]="text.button_submit"
          [attr.title]="text.button_submit"
          [hidden]="eeTrialService.mailSubmitted && !eeTrialService.cancelled"
        ></button>
      </div>
      <ng-template #mailSubmitted>
        <div class="spot-action-bar--right">
          <button
            class="button button_no-margin -highlight spot-action-bar--action"
            data-qa-selector="confirmation-modal--confirmed"
            (click)="startEnterpriseTrial()"
            [textContent]="text.button_continue"
            [attr.title]="text.button_continue"
            [disabled]="!eeTrialService.confirmed"
            [hidden]="eeTrialService.trialStarted"
          ></button>
          <button
            *ngIf="eeTrialService.trialStarted"
            class="button button_no-margin -highlight spot-action-bar--action"
            data-qa-selector="confirmation-modal--confirmed"
            (click)="closeModal($event)"
            [textContent]="text.button_continue"
            [attr.title]="text.button_continue"
          ></button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
