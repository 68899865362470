import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  OnInit,
  HostListener,
  ElementRef,
  Renderer2,
  ViewChild,
  QueryList,
  ViewChildren
} from "@angular/core";
import { NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ICKEditorContext,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor.types';
import {
  ICKEditorType,
} from 'core-app/shared/components/editor/components/ckeditor/ckeditor-setup.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';


@Component({
  selector: "",
  templateUrl: "./ticket-preview.component.html",
  styleUrls: ["./ticket-preview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketPreviewComponent implements OnInit  {
  @ViewChild("labelDropdownBtnWrapper") labelDropdownBtnWrapper: ElementRef;
  @ViewChild("statusDropdown") statusDropdown: ElementRef;
  @ViewChild("storyPointsDetails") storyPointsDetails: ElementRef;
  @ViewChild("memberDetails") memberDetails: ElementRef;
  @ViewChild("titleButton") titleButton: ElementRef;
  @ViewChild("dateDetails") dateDetails: ElementRef;
  @ViewChild("estimatedDetail") estimatedDetail: ElementRef;
  @ViewChild("people") people: ElementRef;
  @ViewChild("accountDrop") accountDrop: ElementRef;
  @ViewChild("dropDate") dropDate: ElementRef;
  @ViewChild("rightChecklist") rightChecklist: ElementRef;
  @ViewChild("checklistDropShow") checklistDropShow: ElementRef;
  @ViewChild("checklistDropShowBtn") checklistDropShowBtn: ElementRef;
  @ViewChild("progress") progress: ElementRef;
  @ViewChild("priority") priority: ElementRef;
  @ViewChild("version") version: ElementRef;
  @ViewChild("watcher") watcher: ElementRef;
  @ViewChild("getTitle") getTitle: ElementRef;
  @ViewChild("getEstimate") getEstimate: ElementRef;
  @ViewChild("storypoints_edit_inp") storypoints_edit_inp: ElementRef;
  @ViewChild("start_date_view") start_date_view: ElementRef;
  @ViewChild("due_date_view") due_date_view: ElementRef;
  @ViewChild("start_date_view_right") start_date_view_right: ElementRef;
  @ViewChild("due_date_view_right") due_date_view_right: ElementRef;
  @ViewChild("searchMembersInput") searchMembersInput: ElementRef;
  @ViewChild("searchVersionsInput") searchVersionsInput: ElementRef;
  @ViewChild("searchMembersSidebarInput") searchMembersSidebarInput: ElementRef;
  @ViewChild("progressInput") progressInput: ElementRef;
  @ViewChild("searchAccountableInput") searchAccountableInput: ElementRef;
  @ViewChild("newChecklistTitle") newChecklistTitle: ElementRef;
  @ViewChildren('checkListTitle') checkListTitles: QueryList<ElementRef>;
  @ViewChildren('newChecklistItem') newChecklistItems: QueryList<ElementRef>;
  @ViewChildren('checklistItemTitle') checklistItemTitle: QueryList<ElementRef>;

  public isShowLabelDropdown = false;
  public isShowStatusList = false;
  public isEditCardTitle = true;
  public isCancelSaveCardTitle = false;
  public isShowStoryPointsDropdown = false;
  public isShowLabelOne = true;
  public isShowLabelTwo = false;
  public isShowMemberDropMenu = false;
  public isShowRelationDropMenu = false;
  public availableRelations: any[] = [];
  searchValue: string = '';
  public isShowOptionDropdown = false;
  public isShowDateDrop = false;
  public isShowEstimete = false;
  public isShowSpentDrop = false;
  public isShowSpanModel = false;
  public isShowActivityModal = true;
  public isShowFileModal = false;
  public isShowWatcherModal = false;
  public isShowRelationModal = false;
  public isShowCommentModal = false;
  public isShowMemberDrops = false;
  public isShowAccountableDrop = false;
  public isShowDatesDrop = false;
  public isShowChecklistDrop = false;
  public isShowProgressDrop = false;
  public isShowProrityDrop = false;
  public isShowVersionnDrop = false;
  public isDescriptionEditable = false;
  public isCommentEditable = false;
  public ticketId: number;
  public projectId: number;
  public ticketLabels: any = [];
  public ticket: any = {};
  public isShowWatcherList = false;
  public colors: any[] = [];
  public labels: any[] = [];
  public priorityList: any[] = [];
  public members: any[] = [];
  public activities: any[] = [];
  public statuses: any[] = [];
  public comments: any[] = [];
  public searchedMembers: { [key: string]: any }[] = [];
  public searchedVersions: any[] = []
  public dateError = false ;
  public isShowSpentEditDrop = false;
  selectedEntry: any;
  public updatedTicketDescription: string|null = null;
  public updatedTicketComment: string = "";
  showInputProgDropdown: boolean = false;
  public referrerLink: string = "";
  form: NgForm | undefined;
  maxDate: String;
  isWatcherButtonDisabled: boolean = false;
  public deleteConfirmMessage: string = "";
  public deletionConfirmed: Boolean = false;
  public removeSpentId: any = '';
  public removeDataCalled: any = '';
  public isLoading = true;

  customTooltipStyle = 300;

  ckEditorContext: ICKEditorContext = {
    type: "constrained" as ICKEditorType,
    resource: {} as HalResource,
    removePlugins: ['plugin1', 'plugin2'],
    macros: false,
    options: {
      rtl: false,
    },
    previewContext: undefined,
    disabledMentions: undefined,
  };
  public dateText: string = ''
  public dateColor: any = ''
  public addChildRelation = false;

  formData: {
    spent_on: any;
    hours: number;
    activity_id: string;
    comments: string;
    [key: string]: string | number; // Index signature
  } = {
    spent_on: '',
    hours: 0,
    activity_id: '',
    comments: '',
  };
  constructor(private renderer: Renderer2, private http: HttpClient, private cdr: ChangeDetectorRef) {}
  
  isFieldInvalid(field: string): boolean {
    return (
      (this.formData[field] === '' || this.formData[field] === null) &&
      (this.form?.controls[field]?.touched || this.submitted)
    );
  }

  isActivitySelected(id: number): Boolean {
    return this.formData.activity_id == id.toString();
  }
  submitted = false;
  submitForm(form: NgForm) {
    this.form = form;
    this.submitted = true;

    if (this.form?.valid) {
      this.formData.hours = Number(this.formData.hours.toFixed(2))
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });

      const payload = this.formData;

      this.http
        .post(`/api/v1/work_packages/${this?.ticket?.id}/log_time`, payload, {
          headers,
        })
        .subscribe((data: any) => {
          this.ticket.time_entries = data?.time_entries;
          this.activities = data?.activity_items;
          this.ticket.spent_hours = this.ticket.time_entries.reduce(
            (totalHours: any, activity: { hours: any }) =>
              totalHours + activity.hours,
            0
          );
          this.formData = {
            spent_on: '',
            hours: 0,
            activity_id: this.ticket.time_entry_activities[0].id,
            comments: '',
          };
          this.formData.spent_on = null;
          if (this.form?.controls['spent_on']) {
            this.form.controls['spent_on'].reset();
          }
          if (this.form?.controls['hours']) {
            this.form.controls['hours'].reset();
          }
          this.isShowSpentDrop = false;
          this.submitted = false
          this.cdr.detectChanges();
        });
    } else {
      console.log('Form is invalid. Display error messages.');
    }
  }

  formDataUpdate = {
    spent_on: '',
    hours: '',
    activity_id: '',
    comments: '',
  };

  onContentChange(value:string) {
    this.updatedTicketDescription = value;
  }

  onNewCommentContentChange(value: string) {
    this.updatedTicketComment = value;
  }

  addNewComment() {
    this.isCommentEditable = false;
    if (this.updatedTicketComment) {
      this.addComment({comment: {raw: this.updatedTicketComment}});
    }
  }

  discardNewComment() {
    this.isCommentEditable = false;
    this.updatedTicketComment = "";
  }

  editNewComment() {
    this.updatedTicketComment = "";
    this.isCommentEditable = true;
  }

  addComment(payload: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    if (!this.ticket || !this.ticket.id) {
      return;
    }
  
    this.http
      .post(`/api/v3/work_packages/${this.ticket.id}/activities`, payload, { headers })
      .subscribe((data: any) => {
        if (data) {
          this.updatedTicketComment = "";
          this.getTicketComments(data.id);
          this.cdr.detectChanges();
        }
      });
  }

  getTicketComments(id: number) {
    if (!this.ticket || !this.ticket.id) {
      return;
    }
  
    this.http
      .get(`/api/v1/work_packages/${this.ticket.id}/comments`)
      .subscribe((data: any) => {
        if (data) {
          this.updatedTicketComment = "";
          let journalExists = this.ticket.journals.some((j: any)=> j.id == id)
          if (!journalExists) {
            let newJournal = data.journals.find((j: any)=> j.id == id)
            this.ticket.journals.unshift(newJournal);
            this.comments.unshift(newJournal);
            this.fetchActivities();
          }
          this.cdr.detectChanges();
        }
      });
  }

  updateDescription() {
    this.isDescriptionEditable = false;
    this.updateTicketData('description',this.updatedTicketDescription);
  }

  discardDescription() {
    this.isDescriptionEditable = false;
    this.updatedTicketDescription = this.ticket.description;
  }

  editDescription() {
    this.isDescriptionEditable = true;
  }

  updateTicketData(keyToUpdate: string, value: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    if (!this.ticket || !this.ticket.id) {
      return;
    }
  
    let payload: { [key: string]: any } = {};
    payload[keyToUpdate] = value;
  
    this.http
      .put(`/api/v1/work_packages/${this.ticket.id}`, payload, { headers })
      .subscribe((data: any) => {
        this.ticket[keyToUpdate] = data[keyToUpdate];
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }  

  fetchActivities() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    if (!this.ticket || !this.ticket.id) {
      return;
    }
  
    this.http
      .get(`/api/v1/work_packages/${this.ticket.id}/activities`, { headers })
      .subscribe((data: any) => {
        if (data.hasOwnProperty('activity_items')) {
          this.ticket['activity_items'] = data['activity_items'];
          this.activities = data?.activity_items;
          this.cdr.detectChanges();
        }
      });
  }

  onSubmitEditLogs() {
    const payload = {
      ...this.formDataUpdate,
      time_entry_id: this.selectedEntry.id
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}/edit_log_time`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.ticket.time_entries = data?.time_entries
        this.ticket.spent_hours = this.ticket.time_entries.reduce((totalHours: any, activity: { hours: any; }) => totalHours + activity.hours, 0);
        this.showSpentEditDropModal(false, null);
        this.fetchActivities();
        this.cdr.detectChanges();
      });
  }

  // deleteConfirmation() {
  //   if(this.removeSpentId){
  //     // this.isShowSpanModel = false ;
  //     this.removeSpentTimeLogs(this.removeSpentId)
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth" // Smooth scrolling animation
  //     });
  //     window.scrollTo(0, 0);
  //     this.cdr.detectChanges();
  //   }

  // }
  // discardDeletion() {
  //   this.deleteConfirmMessage = "";
  //   this.cdr.detectChanges();
  // }

  removeTimeLogs(id: number) {
    if (id) {
      const payload = {
        time_entry_id: id
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    const formData = new FormData();
    formData.append('time_entry_id', id.toString());
  
      this.http
        .delete(`/api/v1/work_packages/${this.ticket.id}/remove_time_log`, { headers, body: payload })
        .subscribe((data: any) => {
          this.ticket.time_entries = data?.time_entries;
          this.ticket.spent_hours = this.ticket.time_entries.reduce((totalHours: any, activity: { hours: any; }) => totalHours + activity.hours, 0);
          this.fetchActivities();
          this.cdr.detectChanges();
        });
    } else {
      console.error('ticket.id is undefined.');
    }
  }

  findActivityName(id: number): string[] {
    const namesArray = this.ticket.time_entry_activities
      .filter((obj: { id: number; }) => obj.id === id)
      .map((obj: { name: any; }) => obj.name);
    return namesArray;
  }
  
  ngOnInit(): void {
    this.isLoading = true;
    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];
    this.getTicketIdFromUrl();
    this.geProjectIdFromUrl();
    this.setReferrer();
    this.loadTicket();
    this.getTicketLables();
    this.loadProjectInfo();
  }

  setReferrer() {
    let previousPage = document.referrer
    if (previousPage.includes('project_tickets')) {
      this.referrerLink = previousPage;
    } else if (previousPage.includes('sprints')) {
      this.referrerLink = previousPage.split('?')[0]; // retrieve url without query parameters
    } else {
      this.referrerLink = previousPage.split('?')[0]
    }
    this.cdr.detectChanges();
  }
  goBackButton(){
    window.location.href = this.referrerLink;
  }
  getDateLabels(data:any) {
    let startDateFormat: any = ''
    let dueDateFormat : any = ''
    if(this.ticket && this.ticket.id){
      startDateFormat = this.ticket.start_date ? new Date(this.ticket.start_date) : null;
      dueDateFormat = this.ticket.due_date ? new Date(this.ticket.due_date) : null;
    }
    else{
      startDateFormat = data.start_date ? new Date(data.start_date) : null;
      dueDateFormat = data.due_date ? new Date(data.due_date) : null;
    }

    if (!startDateFormat && !dueDateFormat) {
      this.dateText = "Set Date";
      this.dateColor = "#d9d9d9";
    } else if (!dueDateFormat && startDateFormat) {
      this.dateText = "Set Date";
      this.dateColor = "#d9d9d9";
    } else {
      let currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      dueDateFormat!.setHours(0, 0, 0, 0);
      startDateFormat!.setHours(0, 0, 0, 0);
  
      if (dueDateFormat < currentDate) {
        this.dateText = this.getOverdueDaysDifference(currentDate, dueDateFormat);
        this.dateColor = "rgb(245, 117, 117)";
      } else if (dueDateFormat.getDate() === currentDate.getDate()) {
        this.dateText = "Due Today";
        this.dateColor = "#FFA500";
      } else {
        this.dateColor = "#00AF3C";
        this.dateText = "Due Date";
      }
    }
  }

  getOverdueDaysDifference(initialDate: Date,finalDate: Date): string {
    let time_difference = initialDate.getTime() - finalDate.getTime();

    let days_difference = time_difference / (1000 * 60 * 60 * 24);
    days_difference = Number(days_difference.toFixed(0))
    return days_difference +( days_difference == 1 ? " day late" :" days late");
  }
  
isUserWatcher(userId: number): any {
  if (this?.ticket?.watchers) {
    return this.ticket.watchers.some((watcher: any) => watcher.user.id === userId);
  }
}


  updateTicketLabels(data: any): void {
    this.ticketLabels = data
  }

  updateLabels(data: any): void {
    this.labels = data
  }

  loadProjectInfo(): void {
    this.http.get(`/api/v1/projects/${this.projectId}/project_info`).subscribe((data: any) => {
      this.labels = data.project.labels;
      this.priorityList = data.task_priorities;
      this.colors = data.project.available_label_colors;
      this.members = data.project.members;
      this.searchedMembers = this.members;
      this.statuses = data.ticket_status;
      this.cdr.detectChanges();
    });
  }

  isMember(user: any):boolean {
    return (this.ticket?.assignees_list?.findIndex((memberUser: any) => memberUser?.id == user.id) > -1) ? true : false;
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  getUserFullName(user: any): String {
    return user?.firstname + ' ' + user?.lastname || ''
  }

  getUserInitials(user: any): String {
    return (user?.firstname[0] + user?.lastname[0]).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  toggleAssignee(user: any): void {
    if(this.isMember(user)) {
      this.removeAssignee(user)
    } else {
      this.addAssignee(user)
    }
  }

  isAccountable(id: number): boolean {
    return this.ticket?.responsible_id == id;
  }
  updateAccountable(id: number): void {
    this.updateWorkPackage('responsible_id', id);
    this.ticket.responsible_id = id;
  }

  updateVersion(id: number|null = null): void {
    this.updateWorkPackage('version_id', id);
    this.ticket.version_id = id;
    if (id) {
      this.ticket.version = this.ticket.project_versions.find((v: any) => v.id == id);
    } else {
      this.ticket.version = null;
    }
  }

  toggleArchive() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    let url = `api/v1/work_packages/${this?.ticket?.id}/`
    url += this.ticket.is_archived ? 'unarchived_work_packages' : 'archived_work_packages'
    this.http
      .put(
        url, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.success) {
          this.ticket.is_archived = data?.work_package?.work_package?.is_archived;
          this.activities = data?.activity_items;
        }
        this.cdr.detectChanges();
      });
  }

  deleteTicket() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .put(
        `api/v1/work_packages/${this?.ticket?.id}/deleted_work_packages`, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.success) {
          this.ticket.is_deleted = data?.work_package?.work_package?.is_deleted;
          window.location.href = `${document.baseURI}projects/${this.projectId}/project_tickets`;
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  removeAssignee(user: any): void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('project_id',this.projectId.toString());
    formData.append('assigned_to_id',user?.id?.toString());
    
    this.http
      .put(
        `api/v1/work_packages/${this.ticket?.id}/remove_assignee`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticket.assignees_list = data?.all_assignees;
          this.ticket.all_assignees_with_story_points = data?.all_assignees_with_story_points;
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  addAssignee(user: any): void {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');

    const formData = new FormData();
    formData.append('assigned_to_id',user?.id?.toString());
    
    this.http
      .put(
        `api/v1/work_packages/${this.ticket?.id}/add_assignee`, formData, {headers}
      )
      .subscribe((data: any) => {
        if (data) {
          this.ticket.assignees_list = data?.all_assignees;
          this.ticket.all_assignees_with_story_points = data?.all_assignees_with_story_points;
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  searchMembers(search: string): void {
    if (search.trim() == "") {
      this.searchedMembers = this.members;
    } else {
      this.searchedMembers = this.members.filter((member) => {
        const fullName = `${member?.user?.firstname} ${member?.user?.lastname}`.toLowerCase();
        return fullName.includes(search.toLowerCase());
      });
    }
  }

  searchVersions(search: string): void {
    if (search.trim() == "") {
      this.searchedVersions = this.ticket.project_versions;
    } else {
      this.searchedVersions = this.ticket.project_versions.filter((member:any) => {
        const fullName = `${member?.name}`.toLowerCase();
        return fullName.includes(search.toLowerCase());
      });
    }
  }

  loadTicket(): void {
    const url = `/api/v1/work_packages/${this.ticketId}`
    this.http.get(url).subscribe((data: any) => {
      if(data){        
        this.ticket = data
        this.isLoading = false;
        this.ticket.project_versions.push({'name':'Backlog'});
        this.activities = this.ticket?.activity_items
        this.updatedTicketDescription = this.ticket?.description;
        this.getDateLabels( data );
        this.searchedVersions = data.project_versions;
        this.comments = this.ticket?.journals.filter((j: any)=> j?.notes?.toString() !== "")
        this.formData.activity_id = this.ticket?.time_entry_activities.length > 0 ? this.ticket?.time_entry_activities[0].id : '';
      }
      this.cdr.detectChanges();
    })
  }

  wpStatus(status: boolean): void {
   const url = `/api/v1/work_packages/${this.ticketId}`;
   const body = {closed: status};
   this.http.put(url, body).subscribe((data: any) => {
    this.activities = data?.activity_items;
    console.log(`Ticket status has been changed to ${status}`)
   });
  } 

  addWatcher(id:any = null): void {
    let userId = id;
    if(userId == null){
      userId = this.ticket?.current_user_id
    }
    this.isWatcherButtonDisabled = true;
    const url = `/api/v1/work_packages/${this.ticketId}/create_watcher?user_id=${userId}`;
    const body = {user_id: userId}
    this.http.put<any>(url, body).subscribe((data: any) => {
      this.ticket.watchers.unshift(data);
      if (userId == this.ticket?.current_user_id) {
        this.ticket.is_watcher = true;
      }
      this.isWatcherButtonDisabled = false;
      this.watcherList(false);
      this.fetchActivities();
      this.cdr.detectChanges();
    });
  }

  removeWatcher(id: any = null): void {
    let userId = id;
    if (id == null) {
      userId = this.ticket?.current_user_id;
    }
    this.isWatcherButtonDisabled = true;
    const url = `/api/v1/work_packages/${this.ticketId}/remove_watcher?user_id=${userId}`;
    this.http.delete(url).subscribe((data: any) => {
      this.ticket.watchers = this.ticket.watchers.filter((watcher: any) => watcher.user_id !== data.user_id);
      if (userId == this.ticket?.current_user_id) {
        this.ticket.is_watcher = false;
      }
      this.isWatcherButtonDisabled = false;
      this.watcherList(false);
      this.fetchActivities();
      this.cdr.detectChanges();
    });
  }

  removeRealtion(id:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .put(
        `/api/v1/work_packages/${id}`, { parent_id: null }, { headers }
      )
      .subscribe((data: any) => {
        this.ticket.children = this.ticket.children.filter((child:any)=>child.id !== id)
        this.fetchActivities();
        this.cdr.detectChanges();
      });
  }
  

  labelIndex(labelId: number):number {
    return this.ticketLabels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  projectLabelIndex(labelId: number):number {
    return this.labels?.findIndex(
      (label: any) => label?.id == labelId
    );
  }

  removeTicketLabel(labelId: number): void {
    const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
  
    const formData = new FormData();
    formData.append('work_package_id', this.ticketId?.toString());
  
    this.http
      .delete(
        `projects/${this.projectId}/labels/${labelId}/remove_from_work_package`,
        { headers, body: formData }
      )
      .subscribe((data: any) => {
        if (data && data?.success == true) {
          const labelIndex = this.labelIndex(labelId);
          if (labelIndex !== -1 && this.ticket) {
            this.ticketLabels.splice(labelIndex, 1);
          }
          // this.emitUpdatedTicketData();
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  uploadAttachment(event: any) {
    // Get the file input element
    const fileInput = event.target;


    // Ensure a file is selected
    if (fileInput.files && fileInput.files.length > 0) {

      const formData = new FormData();
      formData.append('file', fileInput.files[0]);
      formData.append("metadata", JSON.stringify({ fileName: fileInput.files[0].name }))

      this.http.post(`/api/v3/work_packages/${this.ticket.id}/attachments`, formData).subscribe(
        (response: any) => {
          this.ticket.attachments.unshift(response);
          this.fetchActivities();
          this.cdr.detectChanges();
        },
        (error: string) => {
          console.error('Error uploading attachment:', error);
        }
      );
    } else {
      console.warn('No file selected for attachment.');
    }
  }

  getTicketLables(): void {
    const url = `/projects/${this.projectId}/labels.json?work_package_id=${this.ticketId}`
    this.http.get(url).subscribe((data: any) => {
      this.ticketLabels = data
      this.fetchActivities();
      this.cdr.detectChanges();
    })
  }

  showLabelDropdownWrapper() {
    this.isShowLabelDropdown = true;
  }

  addChildToggle() {
    this.isShowRelationDropMenu = !this.isShowRelationDropMenu;
    this.availableRelations = [];
    this.searchValue = '';
  }

  onSearchRelationInput(event: Event): void {

    const inputValue = (event.target as HTMLInputElement).value;

    if (inputValue.length > 0) {
      const filters = [
        { "project_id": { "operator": "=", "values": this.projectId } }
      ];
      const url = `/api/v3/work_packages/${this.ticket.id}/available_relation_candidates?query=${inputValue}&filters=${encodeURIComponent(JSON.stringify(filters))}&type=children`;
      this.http.get(url).subscribe((data: any) => {
        this.availableRelations = data?._embedded?.elements
        this.cdr.detectChanges();
      })
    } else {
      this.availableRelations = [];
    }
  }

  createRelation(id:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const payload = {
      work_package: {
        parent_id: this.ticket.id,
      },
      read_only: true
    };

    this.http
      .patch(
        `/api/v4/work_packages/${id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.success) {
          this.ticket.children.push(data?.work_package);
          this.fetchActivities();
          this.addChildToggle();
        }
        this.cdr.detectChanges();
      });
  }

  createNewLabel(show: boolean) {
    if (show) {
      this.isShowLabelTwo = true;
      this.isShowLabelOne = false;
    } else {
      this.isShowLabelTwo = false;
      this.isShowLabelOne = true;
    }
  }
  closeLabelDropdownWrapper() {
    this.isShowLabelDropdown = false;
    this.isShowLabelTwo = false;
    this.isShowLabelOne = true;
  }
  editCardTitle() {
    this.isEditCardTitle = false;
    this.isCancelSaveCardTitle = true;
  }
  saveCardTitle() {
    this.isCancelSaveCardTitle = false;
    this.isEditCardTitle = true;
    const getCardTitle = this.getTitle.nativeElement;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = { subject: getCardTitle?.value?.trim() };

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }
  editCancelCardTitle() {
    this.isCancelSaveCardTitle = false;
    this.isEditCardTitle = true;
  }
  toggleStatusList() {
    this.isShowStatusList = !this.isShowStatusList;
  }
  statusById(id: number): string {
    let status = this.statuses.find((status)=>status.id == id)
    if (status) {
      return status?.name?.toUpperCase();
    }
    return '';
  }
  updateTicketStatus(id: number) {
    if(id == this.ticket?.status_id) {
      return
    }
    this.isShowStatusList = false;
    this.updateWorkPackage('status_id', id);
    this.ticket.status_id = id;
  }
  updateWorkPackage(key: string, value: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let payload: { [key: string]: any } = {};
    payload[key] = value;

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }
  toggleStoryPointsDropdown() {
    this.isShowStoryPointsDropdown = !this.isShowStoryPointsDropdown;
  }

  closeStoryPointsDetails() {
    this.isShowStoryPointsDropdown = false;
    this.storypoints_edit_inp.nativeElement.value = this.ticket.story_points;
  }

  remainingTime(estimated_hours: number, spent_hours: number): number {
    let remainingTime = estimated_hours - spent_hours
    return remainingTime < 0 ? 0 : remainingTime
  }

  validateStoryPoints(value: any) {
    if (value == '') {
      this.storypoints_edit_inp.nativeElement.value = ''
    } else if (value < 0) {
      this.storypoints_edit_inp.nativeElement.value = 0
    }
  }

  updateStoryPointsDetails() {

    this.isShowStoryPointsDropdown = false;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const getCardStory = this.storypoints_edit_inp.nativeElement;
    let updatedStoryPoints: number|null = null;
    if (getCardStory.value < 0) {
      updatedStoryPoints = 0;
    } else if(getCardStory.value.trim() == '') {
      updatedStoryPoints = null;
    } else {
      updatedStoryPoints = getCardStory.value.trim();
    }
    const payload = {
      work_package: {
        story_points: updatedStoryPoints,
      },
      read_only: true
    };

    this.http
      .patch(`/api/v4/work_packages/${this?.ticket?.id}`, payload, { headers })
      .subscribe((data: any) => {
        this.ticket.story_points = data?.work_package?.story_points
        this.activities = data?.work_package?.activity_items
        this.cdr.detectChanges();
      });
  }

  showMemberDropMenu(show: boolean) {
    this.isShowMemberDropMenu = show;
  }
  showOptionDropdown() {
    this.isShowOptionDropdown = true;
  }
  dateDrop(show: boolean) {
    this.isShowDateDrop = show;
    this.start_date_view.nativeElement.value = this.ticket.start_date
    this.due_date_view.nativeElement.value = this.ticket.due_date
  }

  dateUpdate(editDate:string = 'center') {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    interface Payload {
      start_date: string; // Assuming start_date is a string, adjust as needed
      due_date: string;   // Assuming due_date is a string, adjust as needed
    }

    let payload: Payload = {
      start_date: '',
      due_date: ''
    };

    if(editDate == 'center'){
      payload = {
        start_date: this.start_date_view.nativeElement.value,
        due_date: this.due_date_view.nativeElement.value
      };
    }
    else if(editDate == 'right')
    {
      payload = {
        start_date: this.start_date_view_right.nativeElement.value,
        due_date: this.due_date_view_right.nativeElement.value
      };
    }
    if(payload.start_date > payload.due_date && payload.due_date !== ""){
        this.dateError = true
        return 
    }     

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.ticket.due_date = data?.due_date
        this.ticket.start_date = data?.start_date
        this.isShowDateDrop = false;
        this.isShowDatesDrop = false;
        this.getDateLabels(this.ticket);
        this.dateError = false
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }

  estimateDrop(show: boolean) {
    this.isShowEstimete = show;
  }

  setCurrentDate(type: string) {
    if (type == 'start') {
      this.start_date_view.nativeElement.value = new Date().toISOString().split("T")[0];
    }
    else if (type == 'end') {
      this.due_date_view.nativeElement.value = new Date().toISOString().split("T")[0];
    }
    else if (type == 'startClose') {
      this.start_date_view.nativeElement.value = ''; 
    }
    else if (type == 'endClose') {
      this.due_date_view.nativeElement.value = '';
    }
  }
  setCurrentDateRight(type: string) {
    if (type == 'start') {
      this.start_date_view_right.nativeElement.value = new Date().toISOString().split("T")[0];
    }
    else if (type == 'end') {
      this.due_date_view_right.nativeElement.value = new Date().toISOString().split("T")[0];
    }
    else if (type == 'startClose') {
      this.start_date_view_right.nativeElement.value = '';
    }
    else if (type == 'endClose') {
      this.due_date_view_right.nativeElement.value = '';
    }

  }

  estimateDropApi() {
    const getCardEstimate = this.getEstimate.nativeElement;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = { estimated_hours: getCardEstimate?.value?.trim() };

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.isShowEstimete = false;
        this.ticket.estimated_hours = getCardEstimate?.value?.trim()
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }

  spenDrop(show: boolean) {
    this.isShowSpentDrop = show;
    if (!show) {
      this.submitted = false
    }
  }

  showSpentEditDropModal(show: boolean, selectedEntry: any) {
    this.isShowSpentEditDrop = show;

    if (!show) {
      this.selectedEntry = null;
    }
    if (selectedEntry) {
        this.selectedEntry = selectedEntry;
        this.formDataUpdate = {
            spent_on: selectedEntry.spent_on,
            hours: selectedEntry?.hours,
            activity_id: selectedEntry?.activity_id,
            comments: selectedEntry?.comments,
        };
    }
  }

  showSpanModel(show: boolean) {
    this.isShowSpanModel = show;
  }
  activeTabs(tag: string) {
    if (tag === "activity") {
      this.isShowActivityModal = true;
      this.isShowFileModal = false;
      this.isShowWatcherModal = false;
      this.isShowRelationModal = false;
      this.isShowCommentModal = false;
    } else if (tag === "files") {
      this.isShowActivityModal = false;
      this.isShowFileModal = true;
      this.isShowWatcherModal = false;
      this.isShowRelationModal = false;
      this.isShowCommentModal = false;
    } else if (tag === "watcher") {
      this.isShowActivityModal = false;
      this.isShowFileModal = false;
      this.isShowWatcherModal = true;
      this.isShowRelationModal = false;
      this.isShowCommentModal = false;
    } else if (tag === "relation") {
      this.isShowActivityModal = false;
      this.isShowFileModal = false;
      this.isShowWatcherModal = false;
      this.isShowRelationModal = true;
      this.isShowCommentModal = false;
    } else if (tag === "comments") {
      this.isShowActivityModal = false;
      this.isShowFileModal = false;
      this.isShowWatcherModal = false;
      this.isShowRelationModal = false;
      this.isShowCommentModal = true;
    }
  }

  memberDrops(show: boolean) {
    this.resetSearchedMembers();
    this.isShowMemberDrops = show;
  }

  resetSearchedMembers() {
    this.searchMembersInput.nativeElement.value = ''
    this.searchMembersSidebarInput.nativeElement.value = ''
    this.searchedMembers = this.members
  }
  accountableDrop(show: boolean) {
    this.searchAccountableInput.nativeElement.value = ''
    this.searchedMembers = this.members
    this.isShowAccountableDrop = show;
  }
  datesDrop(show: boolean) {
    this.isShowDatesDrop = show;
    this.start_date_view_right.nativeElement.value = this.ticket.start_date
    this.due_date_view_right.nativeElement.value = this.ticket.due_date
  }
  checkListDropdown(show: boolean) {
    this.isShowChecklistDrop = show;
  }
  createChecklist(value: string) {
    if (value.length == 0) {
      return
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      title: value,
      work_package_id: this.ticketId
    }

    this.http
      .post(
        '/api/v3/checklists/', payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.checklist) {
          data.checklist.checklist_items = [];
          this.ticket?.checklist.push(data.checklist);
        }
        this.newChecklistTitle.nativeElement.value = '';
        this.checkListDropdown(false)
        this.fetchActivities();
        this.cdr.detectChanges();
      });
  }

  updateChecklist(id: number, value: string, index: number) {
    if (value.length == 0) {
      return
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      title: value,
      work_package_id: this.ticketId
    }

    this.http
      .put(
        `/api/v3/checklists/${id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.checklist) {
          let checkListIndex = this.ticket?.checklist?.findIndex((c: any)=>c.id == id)
          this.ticket.checklist[checkListIndex].title = data?.checklist?.title;
          this.isEditMode[index] = false;
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  deleteChecklist(event: MouseEvent, id: number, index: number) {
    const button = event.currentTarget as HTMLButtonElement;
    if (button) button.disabled = true;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .delete(
        `/api/v3/checklists/${id}`, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.message && data?.message == "Checklist deleted") {
          this.ticket?.checklist[index] && this.ticket?.checklist?.splice(index, 1);
          this.isEditMode[index] && this.isEditMode.splice(index, 1);
          this.isChecklistOpen[index] && this.isChecklistOpen.splice(index, 1);
          this.originalChecklistTitles[index] && this.originalChecklistTitles.splice(index, 1);
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  createChecklistItem(id: number, value: string) {
    if (value.length == 0) {
      return
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      title: value,
      checklist_id: id
    }

    this.http
      .post(
        '/api/v3/checklist_items', payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.checklist_item) {
          const index = this.ticket?.checklist.findIndex((c: any)=>c.id == id);
          this.ticket.checklist[index].checklist_items.push(data.checklist_item)
          this.newChecklistItems.toArray()[index].nativeElement.value = '';
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  updateChecklistItemStatus(id: number, checklist_id: number, value: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      status: value,
      checklist_id: checklist_id
    }

    this.http
      .put(
        `/api/v3/checklist_items/${id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.checklist) {
          const checkListIndex = this.ticket?.checklist.findIndex((c: any)=>c.id == checklist_id);
          const checkListItemIndex = this.ticket.checklist[checkListIndex].checklist_items.findIndex((c: any)=>c.id == id);
          this.ticket.checklist[checkListIndex].checklist_items[checkListItemIndex] = data.checklist;
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  deleteChecklistItem(event: MouseEvent, id: number, checklist_id: number) {
    const button = event.currentTarget as HTMLButtonElement;
    if (button) button.disabled = true;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .delete(
        `/api/v3/checklist_items/${id}`, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.message && data?.message == "Checklist item deleted") {
          const checkListIndex = this.ticket?.checklist.findIndex((c: any)=>c.id == checklist_id);
          const checkListItemIndex = this.ticket.checklist[checkListIndex].checklist_items.findIndex((c: any)=>c.id == id);
          if (checkListItemIndex > -1) {
            this.ticket.checklist[checkListIndex].checklist_items.splice(checkListItemIndex, 1);
          }
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  checklistProgress(index: number): number {
    const itemsCount = this.ticket?.checklist[index].checklist_items.length
    if (itemsCount == 0) {
      return 0;
    }
    return Math.floor(this.ticket?.checklist[index].checklist_items.filter((i: any) => i.status).length / itemsCount * 100);
  }
  
  isEditMode: boolean[] = [];
  isChecklistOpen: boolean[] = [];
  originalChecklistTitles: string[] = [];

  toggleChecklist(index: number) {
    this.isChecklistOpen[index] = !this.isChecklistOpen[index];
  }

  toggleEditMode(index: number) {
    this.originalChecklistTitles[index] = this.ticket.checklist[index].title;
    this.isEditMode[index] = !this.isEditMode[index];
  }

  cancelEdit(index: number) {
    if (index >= 0 && index < this.ticket.checklist.length) {
      const specificInputElement = this.checkListTitles.toArray()[index];
      specificInputElement.nativeElement.value = this.originalChecklistTitles[index];
    }
    this.isEditMode[index] = false;
  }

  isNewItemVisible: boolean[] = this.ticket && this.ticket?.checklist ? new Array(this.ticket?.checklist.length).fill(false) : []

  toggleNewItem(index: number) {
    this.isNewItemVisible[index] = !this.isNewItemVisible[index];
  }

  cancelNewItem(index: number) {
    this.isNewItemVisible[index] = false;
  }

  editableChecklistItems: number[] = [];

  editChecklistItemTitle(itemId: number) {
    const index = this.editableChecklistItems.indexOf(itemId);

    if (index == -1) {
      this.editableChecklistItems.push(itemId);
    }
  }

  discardChecklistItem(itemId: number, checklistId: number) {
    let originalTitle = ''
    const checklist = this.ticket.checklist.find((c: any) => c.id == checklistId);
    if (checklist) {
      const checklistItem = checklist?.checklist_items.find((ci: any) => ci.id == itemId)
      originalTitle = checklistItem ? checklistItem.title : '';
      const specificInputElement = this.checklistItemTitle.find(child => child.nativeElement.classList.contains(`checklist-item-title-${itemId}`));
      if (specificInputElement) specificInputElement.nativeElement.value = originalTitle;
    }
    const index = this.editableChecklistItems.indexOf(itemId);

    if (index !== -1) {
      this.editableChecklistItems.splice(index, 1);
    }
  }

  updateChecklistItemTitle(itemId: number, checklist_id: number, title: string = "") {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const payload = {
      title: title,
      checklist_id: checklist_id
    }

    this.http
      .put(
        `/api/v3/checklist_items/${itemId}`, payload, { headers }
      )
      .subscribe((data: any) => {
        if (data && data?.checklist) {
          const checkListIndex = this.ticket?.checklist.findIndex((c: any)=>c.id == checklist_id);
          const checkListItemIndex = this.ticket.checklist[checkListIndex].checklist_items.findIndex((c: any)=>c.id == itemId);
          this.ticket.checklist[checkListIndex].checklist_items[checkListItemIndex] = data.checklist;
          const index = this.editableChecklistItems.indexOf(itemId);

          if (index !== -1) {
            this.editableChecklistItems.splice(index, 1);
          }
          this.fetchActivities();
        }
        this.cdr.detectChanges();
      });
  }

  getChecklistItemsCount(checklist: any): string {
    if (checklist) {
      const checklistItemsCount = checklist?.checklist_items.length;
      const completedChecklistItemsCount = checklist?.checklist_items.filter((item: any)=> item.status == true ).length;
      return `(${completedChecklistItemsCount}/${checklistItemsCount})`
    }
    return '(0/0)';
  }

  progressDrop(show: boolean) {
    this.isShowProgressDrop = show;
    this.showInputProgDropdown = false;
  }

  showInputProgressDropdown () {
    this.showInputProgDropdown = !this.showInputProgDropdown
  }
  validateEstimateInput(inputValue: any){
    if (inputValue < 0) {
      this.getEstimate.nativeElement.value = 0;
    } else if (inputValue == '') {
      this.getEstimate.nativeElement.value = '';
    } else if (inputValue > 100) {
      let divisor = Math.pow(10, Math.floor(Math.log10(inputValue)))/10;
      this.getEstimate.nativeElement.value = Math.floor(inputValue / divisor);
    }
  }


  validateProgressInput(inputValue: any){
    if (inputValue < 0) {
      this.progressInput.nativeElement.value = 0;
    } else if (inputValue == '') {
      this.progressInput.nativeElement.value = '';
    } else if (inputValue > 100) {
      let divisor = Math.pow(10, Math.floor(Math.log10(inputValue)))/10;
      this.progressInput.nativeElement.value = Math.floor(inputValue / divisor);
    }
  }

  updateProgress () {

    const payload = {
      done_ratio: parseInt(this.progressInput.nativeElement.value)
    };
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.http
      .put(
        `/api/v1/work_packages/${this?.ticket?.id}`, payload, { headers }
      )
      .subscribe((data: any) => {
        this.ticket.done_ratio = data?.done_ratio
        this.showInputProgDropdown = false;
        this.activities = data?.activity_items;
        this.cdr.detectChanges();
      });
  }

  priorityDrop(show: boolean) {
    this.isShowProrityDrop = show;
  }

  priorityUpdate(id:number){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.http
    .put(
      `/api/v1/work_packages/${this.ticket.id}`, { priority_id: id }, { headers }
    )
    .subscribe((data: any) => {
      this.ticket.priority_id = data.priority_id
      const foundPriority = this.priorityList.find(item => item.id === id);
      this.ticket.priority = foundPriority
      this.activities = data?.activity_items;
      this.cdr.detectChanges();
    });
  }

  versionDrop(show: boolean) {
    this.isShowVersionnDrop = show;
    this.searchVersionsInput.nativeElement.value = ''
    this.searchedVersions = this.ticket.project_versions
  }
  watcherList(show: boolean) {
    this.isShowWatcherList = show;
  }

  @HostListener("document:click", ["$event"])
  clickOutside(event: Event) {
    if (!this.labelDropdownBtnWrapper.nativeElement.contains(event.target)) {
      this.isShowLabelDropdown = false;
    }
    if (!this.statusDropdown.nativeElement.contains(event.target)) {
      this.isShowStatusList = false;
    }
    if (!this.storyPointsDetails.nativeElement.contains(event.target)) {
      this.isShowStoryPointsDropdown = false;
    }
    if (!this.memberDetails.nativeElement.contains(event.target)) {
      this.isShowMemberDropMenu = false;
    }
    if (!this.titleButton.nativeElement.contains(event.target)) {
      this.isShowOptionDropdown = false;
    }
    if (!this.dateDetails.nativeElement.contains(event.target)) {
      this.isShowDateDrop = false;
    }
    if (!this.estimatedDetail.nativeElement.contains(event.target)) {
      this.isShowEstimete = false;
    }
    if (!this.people.nativeElement.contains(event.target)) {
      this.isShowMemberDrops = false;
    }
    if (!this.accountDrop.nativeElement.contains(event.target)) {
      this.isShowAccountableDrop = false;
    }
    if (!this.dropDate.nativeElement.contains(event.target)) {
      this.isShowDatesDrop = false;
    }
    if (!this.rightChecklist.nativeElement.contains(event.target)) {
      this.isShowChecklistDrop = false;
    }
    // if (!this.checklistDropShow.nativeElement.contains(event.target) && !this.checklistDropShowBtn.nativeElement.contains(event.target)) {
    //   this.isChecklistOpen = false;
    // }
    if (!this.progress.nativeElement.contains(event.target)) {
      this.isShowProgressDrop = false;
    }
    if (!this.priority.nativeElement.contains(event.target)) {
      this.isShowProrityDrop = false;
    }
    if (!this.version.nativeElement.contains(event.target)) {
      this.isShowVersionnDrop = false;
    }
    if (!this.watcher.nativeElement.contains(event.target)) {
      this.isShowWatcherList = false;
    }
  }

  getTicketIdFromUrl() {
    const url = window.location.href;
    const match = url.match(/\/projects\/\d+\/tickets\/(\d+)/);
    this.ticketId = match ? parseInt(match[1], 10) : -1;
  }

  geProjectIdFromUrl() {
    const url = window.location.href;
    const match = url.match(/\/projects\/(\d+)/);
    this.projectId = match ? parseInt(match[1], 10) : -1;
  }

  formatDates(inputDate: string): string {
    if (!inputDate) {
      return '';
    }

    const date = new Date(inputDate);
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
    return formattedDate;
  }

  removeHtmlTags(description: string): string {
    if (description) {
      const descriptionWithHtml = description;
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = descriptionWithHtml;
      const descriptionWithoutHtml = tempDiv.textContent || tempDiv.innerText;
      return descriptionWithoutHtml;
    }
    return ''
  }

  toggleWpStatus(event: any): void {
    // const checkbox = event.target;
    if (this.ticket?.closed) {
      // checkbox.checked = true;
      this.wpStatus(false);
      this.loadTicket();

    } else {
      // checkbox.checked = false;
      this.wpStatus(true);
      this.loadTicket();

    }
  }

  updateJournal(comment: { [key: string]: any }) {
    let journalIndex = this.ticket.journals.findIndex((j: any) => j.id == comment.id);
    if (journalIndex > -1) {
      this.ticket.journals[journalIndex] = comment;
    }
  }

  attachmentContent(attachment: { [key: string]: any }) {
    let fileType: string = attachment?.content_type || attachment?.contentType;
    let isImage: boolean = /^(image)\//i.test(fileType);

    if (isImage) {
      return `<img class="attachment-image" src="api/v3/attachments/${attachment.id}/content" />`;
    } else {
      let fileExt: string | undefined = fileType?.split("/").pop();

      if (fileExt && fileExt.split(".").length) {
        let extension: string[] = fileExt.split(".");
        fileExt = extension[extension.length - 1]?.slice(0, 3);
      }

      return `<p>${fileExt?.toUpperCase() || "File"}</p>`;
    }
  }

  deleteAttachment(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  
    this.http
      .delete(`/api/v3/attachments/${id}`, { headers })
      .subscribe(() => {
        this.ticket.attachments = this.ticket.attachments.filter((attachment: { [key: string]: any }) => attachment.id != id);
        this.fetchActivities();
        this.cdr.detectChanges();
      }); 
  }

  attachmentAddedDate(created_at: string) {
    const regex = /\b(?:AM|PM)\b/;
    let currentUTC = new Date(Date.now());
    let current = currentUTC.getTime();
    if (regex.test(created_at)) {
      created_at = created_at.replace(/\b(?:AM|PM)\b/, '');
      current = current - (5 * 60 * 60 * 1000);
    }
    let previous = Date.parse(created_at);

    const msPerMinute: number = 60 * 1000;
    const msPerHour: number = msPerMinute * 60;
    const msPerDay: number = msPerHour * 24;
    const msPerMonth: number = msPerDay * 30;
    const msPerYear: number = msPerDay * 365;
  
    const elapsed: number = current - previous;
  
    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }

  getContrastColor(hexColor: string): string {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    if (luminance > 0.5) {
        return '#000000';
    } else {
        return '#ffffff';
    }
  }
}
