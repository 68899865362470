<div class="labels-select-wrapper" [style.display]="isShowLabelSelectWrapper ? 'block': 'none'">
  <span (click)="closeLabelsDrop()" class="label_close">×</span>
  <div class="d-flex align-items-center search-labels-inp">
    <img src="/assets/search_label.svg" alt="">
    <input type="text" placeholder="Search Labels.." autocomplete="off" id="search-label-input" #searchLabelsInput (input)="searchLabels(searchLabelsInput.value)">
  </div>
  <div class="search-labels-inp-border"></div>
  <div class="all-labels-listing">
    <span *ngIf="isSearched && searchedLabels.length == 0" class="errorMessageSearch">
      No labels found
    </span>
    <ng-container *ngIf="isSearched">
      <ng-container *ngFor="let label of searchedLabels">
        <div class="listed-label-wrapper listed-label-2330">
          <div class="editable-label-wrap">
            <div class="selectable-label-wrap " label-id="label?.id" [style.background-color]="label?.color?.hexcode" (click)="toggleLabel(label?.id)" [hidden]="label.editMode">
              <span class="selectable-label-text" [style.color]="getContrastColor(label?.color?.hexcode)">{{ label?.title }}</span>
              <div *ngIf="isTicketLabel(label?.id)" class="member-selected label-selected" [style.color]="getContrastColor(label?.color?.hexcode)">✓</div>
            </div>
            <input class="edit-label-inp" type="text" autocomplete="off"
              placeholder="Enter label title" #editLabelInput [value]="label?.title" [hidden]="!label.editMode">
          </div>
          <div class="editable-label-btns-wrap d-flex align-items-center">
            <button class="edit-label-btn label-edit-btn" (click)="toggleEditMode(label.id)" [hidden]="label.editMode">
              <img src="/assets/tiitle_edit.svg" alt="">
            </button>
            <button class="edit-label-btn update-label-btn" (click)="toggleEditMode(label.id, editLabelInput.value)" [hidden]="!label.editMode">
              <img src="/assets/save_title.svg" alt="">
            </button>
            <button class="edit-label-btn mr-0 discard-update-label" (click)="toggleEditMode(label.id)" [hidden]="!label.editMode">
              <img alt="delete" src="/assets/cross-icon.svg">
            </button>
            <button class="edit-label-btn mr-0 delete-label" (click)="deleteTicketLabel(label.id)" [hidden]="label.editMode">
              <img alt="delete" src="/assets/cross-icon.svg">
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isSearched">
      <ng-container *ngFor="let label of labels">
        <div class="listed-label-wrapper listed-label-2330">
          <div class="editable-label-wrap">
            <div class="selectable-label-wrap " label-id="label?.id" [style.background-color]="label?.color?.hexcode" (click)="toggleLabel(label?.id)" [hidden]="label.editMode">
              <span class="selectable-label-text" [style.color]="getContrastColor(label?.color?.hexcode)">{{ label?.title }}</span>
              <div *ngIf="isTicketLabel(label?.id)" class="member-selected label-selected" [style.color]="getContrastColor(label?.color?.hexcode)">✓</div>
            </div>
            <input class="edit-label-inp" type="text" autocomplete="off"
              placeholder="Enter label title" #editLabelInput [value]="label?.title" [hidden]="!label.editMode">
          </div>
          <div class="editable-label-btns-wrap d-flex align-items-center">
            <button class="edit-label-btn label-edit-btn" (click)="toggleEditMode(label.id)" [hidden]="label.editMode">
              <img src="/assets/tiitle_edit.svg" alt="">
            </button>
            <button class="edit-label-btn update-label-btn" (click)="toggleEditMode(label.id, editLabelInput.value)" [hidden]="!label.editMode">
              <img src="/assets/save_title.svg" alt="">
            </button>
            <button class="edit-label-btn mr-0 discard-update-label" (click)="toggleEditMode(label.id)" [hidden]="!label.editMode">
              <img alt="delete" src="/assets/cross-icon.svg">
            </button>
            <button class="edit-label-btn mr-0 delete-label" (click)="deleteTicketLabel(label.id)" [hidden]="label.editMode">
              <img alt="delete" src="/assets/cross-icon.svg">
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="mt-3 mx-3">
    <button class="create-label-btn" (click)="showLabelCreateWrapper(true)">
      <img class="pl-4 pr-3" src="/assets/plus.svg" alt="">
      Create New Label
    </button>
  </div>
</div>
<div class="labels-create-wrapper d-none" [style.display]="isShowLabelCreateWrapper ? 'block': 'none'">
  <div class="d-flex align-items-center labels-create-top">
    <button class="labels-back-btn" (click)="showLabelCreateWrapper(false)">
      <img src="/assets/back.svg" alt="">
    </button>
    <h3>Create label</h3>
  </div>
  <div class="create-labels-top-border"></div>
  <div class="create-labels-inp-field">
    <input type="text" placeholder="Title" #newLabelTitle autocomplete="off" id="ticket-new-label-name">
  </div>
  <h4 class="select-clr-title">Select a Color</h4>
  <div class="create-labels-colors-grid">
    <ng-container *ngFor="let color of colors">
      <button class="label-color-btn" [style.background-color]="color?.hexcode" (click)="selectLabelColor(color?.id)">
        <div *ngIf="isSelectedColor(color?.id)" class="member-selected create-label-selected" [style.color]="getContrastColor(color?.hexcode)">✓</div>
      </button>
    </ng-container>
  </div>
  <div class="create-labels-footer">
    <button class="label-create-btn" id="ticket-create-new-label" (click)="createTicketLabel(newLabelTitle.value)">Create</button>
  </div>
</div>