import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ViewChild,
  ViewChildren,
  QueryList,
  OnInit,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "member-tooltip",
  templateUrl: "./member-tooltip.component.html",
  styleUrls: ["./member-tooltip.component.scss"],
})
export class MemberTooltipComponent {
  @Input() members: any[] = [];
  @Input() users: any[] = [];
  @Input() isDeleteable: boolean = false;
  @Input() tooltipStyle: any;
  @Output() remove: EventEmitter<any> = new EventEmitter();

  public members_: any;
  public storyPointsPopulated: boolean = false;
  public isLoading: boolean = false;

  ngOnInit(): void {
    this.members_ = this.members;
  }
  removeMembers(user: any): void {
    this.remove.emit(user);
  }

  hoveredMember(event: MouseEvent, show: boolean) {
    if (this.isDeleteable) {
      const target = event.currentTarget as HTMLElement;
      const button = target.children[0] as HTMLElement;
      if (show) {
        button.style.setProperty('background', 'var(--btn-icon-bg)', 'important');
      } else {
        button.style.removeProperty('background');
      }
    }
  }

  showCloseButton(event: MouseEvent): void {
    if(this.isDeleteable){
      const target = event.currentTarget as HTMLElement;
      target.classList.add('memberTooltipHover');
      const closeButton = target.querySelector('.members_close_btn') as HTMLElement;
      closeButton.style.display = 'block';
    }
  }

  hideCloseButton(event: MouseEvent): void {
    if(this.isDeleteable){
      const target = event.currentTarget as HTMLElement;
      target.classList.remove('memberTooltipHover');
      const closeButton = target.querySelector('.members_close_btn') as HTMLElement;
      closeButton.style.display = 'none';
    }
  }

  @ViewChildren("singleTooltip") singleTooltip: QueryList<ElementRef>;
  @ViewChild("multiTooltip") multiTooltip: ElementRef;
  @ViewChildren("singleAvatarTooltip") singleAvatarTooltip: QueryList<ElementRef>;
  @ViewChild("multiAvatarTooltip") multiAvatarTooltip: ElementRef;

  showTooltip: boolean[] = [false, false, false];

  public divHeight = 0;

  toggleTooltip(index: number, show: boolean) {
    this.showTooltip[index] = show;
  }

  onSingleAvatarTooltipEnter(event: MouseEvent, index: number, show: boolean) {
    // console.log("I'm hare");
    this.showSingleAvatarTooltip(event, index);
  }

  onSingleAvatarTooltipLeave(index: number, show: boolean) {
    this.hideSingleAvatarTooltip(index);
    // console.log("I'm hare leave");
  }

  showSingleAvatarTooltip(event: MouseEvent, index: any) {
    const divs = this.singleAvatarTooltip.toArray();
    const div = divs[index].nativeElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const right = window.innerWidth - rect.left - 15;
    let top = rect.top + 30 - window.scrollY;

    if (top + div.offsetHeight + 100 > window.innerHeight + window.scrollY) {
      top = rect.top - 82;
    }

    this.renderer.setStyle(div, "right", `${right}px`);
    this.renderer.setStyle(div, "top", `${top}px`);
    this.renderer.setStyle(div, "display", "block");
    this.getMemberStoryPoints();
  }
  hideSingleAvatarTooltip(index: any) {
    const divs = this.singleAvatarTooltip.toArray();
    const div = divs[index].nativeElement;
    this.renderer.setStyle(div, "display", "none");
  }
  onMultiAvatarTooltipEnter(event: MouseEvent, index: number, show: boolean) {
    this.showMultiAvatarTooltip(event, index);
    this.showTooltip[index] = show;
  }

  onMultiAvatarTooltipLeave(index: number, show: boolean) {
    this.hideMultiAvatarTooltip(index);
    this.showTooltip[index] = show;
  }

  calculateTopValue(rect: any, membersLength: number): number {
    let top = 0;
    if ((window.innerHeight - rect.bottom ) < 55) {
      if (membersLength == 3) {
        top = rect.top - rect.width - rect.height - 40;
      } else if (membersLength == 4) {
        top = rect.top - rect.width - rect.height - 114;
      } else if (membersLength >= 5) {
        top = rect.top - rect.width - rect.height - 186;
      }
    } else if ((window.innerHeight - rect.bottom) < 100 && (window.innerHeight - rect.bottom) > 55) {
      if (membersLength == 3) {
        top = rect.top - rect.width - rect.height - 40;
      } else if (membersLength == 4) {
        top = rect.top - rect.width - rect.height - 114;
      } else if (membersLength >= 5) {
        top = rect.top - rect.width - rect.height - 189;
      }
    } else if ((window.innerHeight - rect.bottom) < 170 && (window.innerHeight - rect.bottom) > 100) {
      if (membersLength == 3) {
        top = rect.top - rect.width - rect.height - 43;
      } else if (membersLength == 4) {
        top = rect.top - rect.width - rect.height - 111;
      } else if (membersLength >= 5) {
        top = rect.top - rect.width - rect.height - 186;
      }
    }
    return top;
  }
  
  showMultiAvatarTooltip(event: MouseEvent, index: any) {
    const div = this.multiAvatarTooltip.nativeElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    let right = window.innerWidth - rect.left - 15;
    let top = rect.top + 30 - window.scrollY;

    setTimeout(() => {
      this.divHeight = div.offsetHeight;
    });
    if (top + this.divHeight > window.innerHeight + window.scrollY) {
      top = rect.top - this.divHeight;
      this.renderer.setStyle(div, "right", `${right}px`);
      this.renderer.setStyle(div, "top", `${top}px`);
      this.renderer.setStyle(div, "display", "block");
    } else {
      let newposition = this.calculateTopValue(rect, this.members.length);
      top = newposition > 0 ? newposition : top
      this.renderer.setStyle(div, "right", `${right}px`);
      this.renderer.setStyle(div, "top", `${top}px`);
      this.renderer.setStyle(div, "display", "block");
    }
    this.getMemberStoryPoints()
  }
  hideMultiAvatarTooltip(index: any) {
    const div = this.multiAvatarTooltip.nativeElement;
    this.renderer.setStyle(div, "display", "none");
  }

  onSingleUserTooltipEnter(event: MouseEvent, index: number, show: boolean) {
    if (this.tooltipStyle) {
      let memberTooltipDiv = document.querySelector('.member_detail');
      let distanceToLeftSide = memberTooltipDiv?.getBoundingClientRect().left;
      this.tooltipStyle = distanceToLeftSide;
      this.cdr.detectChanges();
    }
    this.showSingleTooltip(event, index);
  }

  onSingleUserTooltipLeave(index: number, show: boolean) {
    this.hideSingleTooltip(index);
  }

  showSingleTooltip(event: MouseEvent, index: any) {
    const divs = this.singleTooltip.toArray();
    const div = divs[index].nativeElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const right = window.innerWidth - rect.left - 15;
    let top = rect.top + 30 - window.scrollY;

    if (top + div.offsetHeight + 100 > window.innerHeight + window.scrollY) {
      top = rect.top - 82;
    }

    this.renderer.setStyle(div, "right", `${right}px`);
    this.renderer.setStyle(div, "top", `${top}px`);
    this.renderer.setStyle(div, "display", "block");
  }
  hideSingleTooltip(index: any) {
    const divs = this.singleTooltip.toArray();
    const div = divs[index].nativeElement;
    this.renderer.setStyle(div, "display", "none");
  }

  onMultiUserTooltipEnter(event: MouseEvent, index: number, show: boolean) {
    if (this.tooltipStyle) {
      let memberTooltipDiv = document.querySelector('.member_detail');
      let distanceToLeftSide = memberTooltipDiv?.getBoundingClientRect().left;
      this.tooltipStyle = distanceToLeftSide;
      this.cdr.detectChanges();
    }
    this.showMultiTooltip(event, index);
    this.showTooltip[index] = show;
  }

  onMultiUserTooltipLeave(index: number, show: boolean) {
    this.hideMultiTooltip(index);
    this.showTooltip[index] = show;
  }

  showMultiTooltip(event: MouseEvent, index: any) {
    const div = this.multiTooltip.nativeElement;
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();

    const right = window.innerWidth - rect.left - 15;
    let top = rect.top + 30 - window.scrollY;

    // setTimeout(() => {
      this.divHeight = div.offsetHeight;
    // });

    if (top + this.divHeight + 10 > window.innerHeight + window.scrollY) {
      top = rect.top - this.divHeight;
      this.renderer.setStyle(div, "right", `${right}px`);
      this.renderer.setStyle(div, "top", `${top}px`);
      this.renderer.setStyle(div, "display", "block");
    } else {
      let newposition = this.calculateTopValue(rect, this.users.length);
      top = newposition > 0 ? newposition : top
      this.renderer.setStyle(div, "right", `${right}px`);
      this.renderer.setStyle(div, "top", `${top}px`);
      this.renderer.setStyle(div, "display", "block");
    }
  }
  hideMultiTooltip(index: any) {
    const div = this.multiTooltip.nativeElement;
    this.renderer.setStyle(div, "display", "none");
  }

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef, private http: HttpClient) {}

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  getInitials(firstName: string, lastName: string): string {
    return (firstName[0] + lastName[0]).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`;
  }

  getMemberStoryPoints() {
    if (this.storyPointsPopulated || this.isLoading) { return }
    interface Member {
      user_id: string;
      story_points?: number;
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.isLoading = true;
    let memberIds = this.members.map((member)=> member.user_id);
    let projectId = this.members[this.members.length -1].project_id;
    if (!memberIds || !projectId) {
      return;
    }
    this.http
      .get(`/api/v1/projects/${projectId}/member_story_points?assignee_ids=${memberIds}`, { headers })
      .subscribe((data: any) => {
        if (data) {
          data.forEach((assignee_data: { assigned_to_id: string; total_assignee_story_points: number }) => {
            this.members_.forEach((member: Member) => {
              if (member.user_id === assignee_data.assigned_to_id) {
                member.story_points = Math.ceil(assignee_data.total_assignee_story_points);
              }
            });
          });
          this.storyPointsPopulated = true;
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      },
      (error: any) => {
        console.error('An error occurred:', error);
        this.isLoading = false;
        this.storyPointsPopulated = false;
        this.cdr.detectChanges();
      });
  }
}
