import { ChangeDetectionStrategy, Component, ChangeDetectorRef, AfterViewInit, HostListener, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { I18nService } from "core-app/core/i18n/i18n.service";
import { EMPTY, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MemberTooltipComponent } from '../member-tooltip-component/member-tooltip.component';

@Component({
  templateUrl: "./project-dashboard.component.html",
  styleUrls: ["./project-dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDashboardComponent implements AfterViewInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  public projects: any[] = [];
  public stats: any = {
    total_projects: 0,
    above_average_count: 0,
    average_count: 0,
    below_average_count: 0,
    red_zone_count: 0,
    above_average_percentage: 0,
    average_percentage: 0,
    below_average_percentage: 0,
    red_zone_percentage: 0,
  };
  selectedCategory = '';
  private isScrolling: boolean = false;
  private searchText: string = "";
  public currentPage = 1;
  public perPage = 10;
  private order: string = 'asc';
  private searchTimeout: any;
  private isSearching: boolean = false;
  private hasMorePages: boolean = true;
  public isLoading: boolean = true;
  public shouldLoad: boolean = true;
  public isSpinning: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(private I18n: I18nService, private http: HttpClient, private cdr: ChangeDetectorRef) {}
  ngAfterViewInit(): void {
    this.order = this.getQueryParameter('order') || this.order;
    this.searchText = this.getQueryParameter('search') || this.searchText;
    this.selectedCategory = this.getQueryParameter('selected') || this.selectedCategory;
    this.searchInput.nativeElement.value = this.searchText;
    this.loadProjects();
    this.http.get("/api/v1/projects/project_list_stats").subscribe((data: any) => {
      this.stats = { ...data };
      this.calculatePercentages();
      this.cdr.detectChanges();
    });
  }

  onSearch(value: string): void {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.hasMorePages = true;
    this.isSearching = true;
    const trimmedValue = value.trim();
    if (trimmedValue === this.searchText) {
      return;
    }

    this.searchTimeout = setTimeout(() => {
      this.searchText = trimmedValue;
      this.currentPage = 1;
      this.perPage = 10;
      value ? this.handlePageState('search', trimmedValue) : this.removeQueryParameter('search');
      this.shouldLoad = true;
      this.loadProjects();
    }, 500);
  }

  toggleCategory(category: string) {
    if (this.selectedCategory === category) {
      this.selectedCategory = '';
    } else {
      this.selectedCategory = category;
    }
    this.currentPage = 1;
    this.perPage = 10;
    this.hasMorePages = true;
    this.isScrolling = false;
    this.isSearching = true;
    if (this.selectedCategory != '') { this.handlePageState('selected', category)} else {this.removeQueryParameter('selected'); }
    this.shouldLoad = true;
    this.loadProjects();
  }

  private calculatePercentages(): void {
    const totalProjects = this.stats.total_projects;

    this.stats.above_average_percentage = Math.round((this.stats.above_average_count / totalProjects) * 100);
    this.stats.average_percentage = Math.round((this.stats.average_count / totalProjects) * 100);
    this.stats.below_average_percentage = Math.round((this.stats.below_average_count / totalProjects) * 100);
    this.stats.red_zone_percentage = Math.round((this.stats.red_zone_count / totalProjects) * 100);
  }

  // loadProjects(): void {
  //   this.destroy$.next();
  //   if (this.hasMorePages && !this.isScrolling) {
  //     if(this.shouldLoad){
  //       this.isLoading = true;
  //     }
  //     this.isScrolling = true;
  //     this.http
  //       .get(`/api/v1/projects/projects_list?page=${this.currentPage}&per_page=${this.perPage}&name=${this.searchText}&order=${this.order}&selected=${this.selectedCategory}`)
  //       .subscribe((data: any) => {
  //         if (this.isSearching) {
  //           // this.projects = [];
  //           this.projects = data;
  //           // this.isSearching = false;
  //           console.log('--------------------------------')
  //         } else {
  //           console.log('--------------22222------------------')
  //           this.projects = this.projects.concat(data);
  //         }
  //         this.isScrolling = false;
  //         this.currentPage++;
  //         if (data.length < this.perPage) {
  //           this.hasMorePages = false;
  //         }
  //         this.isLoading = false;
  //         this.isSpinning = false;
  //         this.cdr.detectChanges();
  //       });
  //   }
  // }
  loadProjects(): void {
  // Cancel previous requests
  this.destroy$.next();

  // Make the new HTTP call
  this.http
    .get(`/api/v1/projects/projects_list?page=${this.currentPage}&per_page=${this.perPage}&name=${this.searchText}&order=${this.order}&selected=${this.selectedCategory}`)
      .pipe(
      switchMap((data: any) => {
        // Handle the data and reset isScrolling flag
        this.isScrolling = false;
        this.currentPage++;

        // Check if more pages are available
        if (data.length < this.perPage) {
          this.hasMorePages = false;
        }

        // Set projects based on whether it's a search or not
        if (this.isSearching) {
          this.projects = data;
        } else {
          this.projects = this.projects.concat(data);
        }

        // Set loading flags
        this.isLoading = false;
        this.isSpinning = false;

        // Trigger change detection
        this.cdr.detectChanges();

        // Return an empty observable to prevent errors in switchMap
        return EMPTY;
      }),
      takeUntil(this.destroy$)
    )
    .subscribe();
  }

  getAvatarBackgroundColor(firstName: string, lastName: string): string {
    let name = [firstName || "", lastName || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  getInitials(firstName: string, lastName: string): string {
    return (firstName[0] + lastName[0]).toUpperCase();
  }

  getMemberAvatarURL(userId: number): string {
    return `/api/v3/users/${userId}/avatar`
  }

  roundUpCompletedStoryPoints(completedStoryPoints: number): number {
    return Math.ceil(completedStoryPoints);
  }

  getRoadMapFilters(type: string): string {
    let params = new HttpParams();
    if(type == "allSprints") {
      params = params.set('filters', JSON.stringify({status: ['started', 'closed']}));
    } else if(type == "openSprints") {
      params = params.set('filters', JSON.stringify({status: ['started']}));

    } else {
      params = params.set('filters', JSON.stringify({status: ['closed']}));
    }
    return params.toString();
  }

  getTasksListFilters(type: string): string {
    const filters = {
      story_points: [type]
    };
    return encodeURIComponent(JSON.stringify({ filters }));
  }

  getFilterParams(type: string): string {
    const filters = this.getTasksListFilters(type);
    return `filterParams=${filters}`;
  }
  

  setURLParams(key: string, value: string): string {
    let params = new HttpParams();
    params = params.set(key, value);
    return params.toString();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const scrollContainer = event.target as HTMLElement;
    const scrollHeight = scrollContainer.scrollHeight;
    const scrollTop = scrollContainer.scrollTop;
    const clientHeight = scrollContainer.clientHeight;

    const triggerThreshold = 50;
    if (scrollHeight - scrollTop - clientHeight < triggerThreshold) {
      if (this.hasMorePages) {
        this.isSearching = false;
        this.shouldLoad = false;
        this.isSpinning = true;
        this.loadProjects();
      }
    }
  }

  sortProjects(order: 'asc' | 'desc' ): void {
    this.currentPage = 1;
    this.perPage = 10;
    this.order = order;
    this.hasMorePages = true;
    this.isScrolling = false;
    this.isSearching = true;
    this.handlePageState('order', order);
    this.shouldLoad = true;
    this.loadProjects();
  }

  getProgressBarColor(overallProgress: number): string {
    if (overallProgress <= 25) {
      return '#F77568';
    } else if (overallProgress <= 50) {
      return '#EE9231';
    } else if (overallProgress <= 75) {
      return '#FCCD44';
    } else {
      return '#1AD598';
    }
  }

  handlePageState(paramName: string, paramValue: string): void {
    let currentURL = new URL(window.location.href);
    let searchParams = currentURL.searchParams;

    if (searchParams.has(`${paramName}`)) searchParams.delete(`${paramName}`);
    searchParams.append(paramName, paramValue);
    currentURL.search = searchParams.toString();

    window.history.pushState(
      {
        html: "response.html",
        pageTitle: "response.pageTitle",
      },
      "",
      currentURL.href
    );
  }

  removeQueryParameter(paramName: string):void {
    let currentURL = new URL(window.location.href);
    let searchParams = currentURL.searchParams;

    searchParams.delete(paramName);

    currentURL.search = searchParams.toString();

    window.history.pushState(
      {
        html: "response.html",
        pageTitle: "response.pageTitle",
      },
      "",
      currentURL.href
    );
  }

  getQueryParameter(paramName: string):any {
    let currentURL = new URL(window.location.href);
    let searchParams = currentURL.searchParams;

    return searchParams.get(`${paramName}`);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
