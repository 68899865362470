<button
  type="button"
  class="button"
  (click)="markAllRead()"
>
  <op-icon icon-classes="button--icon icon-mark-all-read">
  </op-icon>
  <span
    class="button--text"
    [textContent]="text.mark_all_read"
  >
          </span>
</button>