<div
  [style.visibility]="isDivVisible"
  id="mySidenav"
  class="sidenav"
  data-status="null"
  style="top: 113px; max-height: calc(100% - 125px)"
  #filterSidenav
>
  <div class="filter">
    <div class="filter_heading">
      <h3>Filters</h3>
    </div>
    <div
      class="filter_close" (click)="hideDiv()">
      <a href="javascript:void(0)" id="closeNav" class="closebtn filter-close-btn">×</a>
    </div>
  </div>
  <hr class="horizantal-line-1"/>
  <div class="scrollbar" id="style-1">
    <div>
      <div class="filter_content force-overflow">
        <div class="filter_field">
          <div class="filter_text mt-3">
            <input
              type="text"
              id="searchBox"
              name="textfield"
              value=""
              placeholder="Enter a Keyword..."
              autocomplete="off"
              [(ngModel)]="filters.subject.subject"
              ng-model-options="{debounce: 1000}"
              (input)="handleSearchInput()"
            />
          </div>
        </div>
        <div class="selected-filters-wrapper">
          <div class="selected-filter" *ngIf="filters?.subject?.subject?.length > 0">
            <div class="selected-member selected-label d-flex">
              <h6 style="margin-left: 5px">
                {{ filters.subject.subject }}
              </h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('subject')">
              <img src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.closed == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg"/>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">Show Closed Tickets</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('closed')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>
          <div *ngIf="filters.show_all == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg"/>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">Show All Tickets</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('show_all')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>
          <div *ngIf="isAllMembersSelected()" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg"/>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">All Members</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('all_members')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="isCurrentUserSelected()" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger" [style.background-color]="getAvatarBackground(currentUser)">
                    <ng-container
                      *ngIf="checkIfImageExists('/api/v3/users/' + currentUser.id + '/avatar'); else defaultImage"
                    >
                      <img
                        class="op-avatar op-avatar_default"
                        src="/api/v3/users/{{ currentUser.id }}/avatar"
                        alt="OpenProject Admin"
                      />
                    </ng-container>
                    <ng-template #defaultImage>
                      <span>{{ getMemberShortName(currentUser) }}</span>
                    </ng-template>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">Card Assigned to me</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('assigned_to_id', currentUser.id)">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="!isAllMembersSelected() && this.filters.assigned_to_id.length > 0 && !isOnlyCurrentUserSelected()" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg"/>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">Select Members</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('all_members')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.no_member == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg"/>
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">No Members</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('no_member')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.due_date_filters.overDate == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar red_icon"><img src="/assets/filter-over-due.svg" alt=""/></div>
              <h6 style="margin-left: 5px">Over Due</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('overDate')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.due_date_filters.noDate == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar "><img src="/assets/filter-calendar-icon.svg" alt=""/></div>
              <h6 style="margin-left: 5px">No Dates</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('noDate')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.due_date_filters.dueInNextDay == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar yellow_icon"><img src="/assets/filter-next-due.svg" alt=""/></div>
              <h6 style="margin-left: 5px">Due in the next day</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('dueInNextDay')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.due_date_filters.dueInNextWeek == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar"><span class="icon-context icon-time"></span></div>
              <h6 style="margin-left: 5px">Due in the Next Week</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('dueInNextWeek')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.due_date_filters.dueInNextMonth == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar"><span class="icon-context icon-time"></span></div>
              <h6 style="margin-left: 5px">Due in the Next Month</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('dueInNextMonth')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.no_label == true" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="avatar">
                <div class="avatar-icon hover-icon card-avatars">
                  <div class="avatar_bg TTTrigger">
                    <img src="/assets/member-icon.svg" />
                  </div>
                </div>
              </div>
              <h6 style="margin-left: 5px">No Label</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('no_label')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngFor="let labelId of filters.label_ids" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <div class="label-dot" [style.background-color]="getLabel(labelId).color.hexcode"></div>
              <h6 style="margin-left: 5px">
                {{ getLabel(labelId).title }}
              </h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('label_ids', labelId)">
              <img src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngFor="let type of filters.type_id" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <h6 style="margin-left: 5px">
                {{ typeName(type).name }}
              </h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('type_id', type)">
              <img src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngFor="let status of filters.status_id" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <h6 style="margin-left: 5px">
                {{ statusName(status).name }}
              </h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('status_id', status)">
              <img src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngFor="let sprintStatus of filters.story_points" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <h6 style="margin-left: 5px">
                {{ sprintStatus }}
              </h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('story_points', sprintStatus)">
              <img src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>

          <div *ngIf="filters.order.length > 0" class="selected-filter">
            <div class="selected-member selected-label d-flex">
              <h6 style="margin-left: 5px">{{ filters.order }}</h6>
            </div>
            <div class="close-selected-filter" (click)="cancelFilter('order', '')">
              <img style="margin-top: -7px" src="/assets/selected-filter-close.svg" alt="close button"/>
            </div>
          </div>
        </div>
        <div class="filter_field">
          <h4>Show Closed List</h4>
          <div class="filter_member">
            <input
              id="isClosed"
              type="checkbox"
              [(ngModel)]="filters.closed"
              (change)="updateFilters('isClosedInput')"
              #isClosedInput
              class="filter_input"
            />
            <div class="avatar">
              <img src="/assets/member-icon.svg" alt=""/>
            </div>
            <h5>Show Closed Tickets</h5>
          </div>
          <div class="filter_member">
            <input
              id="showAll"
              type="checkbox"
              [(ngModel)]="filters.show_all"
              (change)="updateFilters('showAllInput')"
              #showAllInput
              class="filter_input"
            />
            <div class="avatar">
              <img src="/assets/member-icon.svg" alt=""/>
            </div>
            <h5>Show All Tickets</h5>
          </div>
        </div>
        <hr class="horizantal-line-3"/>
        <div class="filter_field">
          <h4>Members</h4>
          <div class="filter_member">
            <input
              id="noMember"
              type="checkbox"
              [(ngModel)]="filters.no_member"
              (ngModelChange)="onFilterChange()"
              class="filter_input"
            />
            <div class="avatar">
              <img src="/assets/member-icon.svg" alt=""/>
            </div>
            <h5>No Members</h5>
          </div>
          <div class="filter_member" *ngIf="currentUser" id="filter-member-assigned-to-me">
            <input
              id="assignedToMe"
              type="checkbox"
              value="check"
              class="filter_input"
              [checked]="isCurrentUserSelected()"
              (change)="selectMember(currentUser.id, $event)"
            />
            <div class="avatar assignedToMeAvt">
              <div class="avatar_bg TTTrigger" [style.background-color]="getAvatarBackground(currentUser)">
                <ng-container
                  *ngIf="checkIfImageExists('/api/v3/users/' + currentUser.id + '/avatar'); else defaultImage"
                >
                  <img
                    class="op-avatar op-avatar_default"
                    src="/api/v3/users/{{ currentUser.id }}/avatar"
                    alt="OpenProject Admin"
                  />
                </ng-container>
                <ng-template #defaultImage>
                  <span>{{ getMemberShortName(currentUser) }}</span>
                </ng-template>
              </div>
            </div>
            <h5>Card Assign to me</h5>
          </div>
          <div class="filter_member">
            <input
              type="checkbox"
              value="check"
              id="selectMembers"
              class="filter_input"
              [checked]="isAllDropDownMembersSelected()"
              (change)="selectAllMembers($event)"
            />
            <form>
              <div
                #filterMemberCheckbox
                class="multiselect filter_member_checkbox">
                <div class="selectBox" id="showCheckBoxes" (click)="toggleMemberDiv()">
                  <select>
                    <option>{{ memberSelectedMessage() }}</option>
                  </select>
                  <div class="overSelect"></div>
                </div>
                <div
                  class="invoboard-filter-member-wrapper"
                  id="checkboxes"
                  [style.visibility]="isSelectMemberOptionVisible"
                >
                  <div class="search-members-field-wrapper">
                    <input
                      type="text"
                      id="boardFilterMemberSearch"
                      placeholder="Search members"
                      class="sprint-search-field"
                      #searchMembersInput
                      (input)="searchMembers(searchMembersInput.value)"
                    />
                  </div>
                  <ng-container *ngIf="searchMembersInput.value.length > 0">
                    <label *ngFor="let member of searchedMembers" class="member">
                      <label class="filter-checkbox-wrapper">
                        <input
                          type="checkbox"
                          class="filter_input"
                          id="selectedUser"
                          [checked]="isMemberSelected(member.id)"
                          (change)="selectMember(member.id, $event)"
                        />
                      </label>
                      <span class="member_avatar">
                        <span class="avatar" [style.background-color]="getAvatarBackground(member)">
                          <ng-container
                            *ngIf="checkIfImageExists('/api/v3/users/' + member.id + '/avatar'); else defaultImage"
                          >
                            <img
                              class="op-avatar op-avatar_default"
                              src="/api/v3/users/{{ member.id }}/avatar"
                              alt="OpenProject Admin"
                            />
                          </ng-container>
                          <ng-template #defaultImage>
                            <span>{{ getMemberShortName(member) }}</span>
                          </ng-template>
                        </span>
                      </span>
                      <h5 class="filter-member-heading">{{ member.firstname }} {{ member.lastname }}</h5>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="searchMembersInput.value.length == 0">
                    <label *ngFor="let member of membersList()" class="member">
                      <label class="filter-checkbox-wrapper">
                        <input
                          type="checkbox"
                          class="filter_input"
                          id="selectedUser"
                          [checked]="isMemberSelected(member.id)"
                          (change)="selectMember(member.id, $event)"
                        />
                      </label>
                      <span class="member_avatar">
                        <span class="avatar" [style.background-color]="getAvatarBackground(member)">
                          <ng-container
                            *ngIf="checkIfImageExists('/api/v3/users/' + member.id + '/avatar'); else defaultImage"
                          >
                            <img
                              class="op-avatar op-avatar_default"
                              src="/api/v3/users/{{ member.id }}/avatar"
                              alt="OpenProject Admin"
                            />
                          </ng-container>
                          <ng-template #defaultImage>
                            <span>{{ getMemberShortName(member) }}</span>
                          </ng-template>
                        </span>
                      </span>
                      <h5 class="filter-member-heading">{{ member.firstname }} {{ member.lastname }}</h5>
                    </label>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr class="horizantal-line-3"/>
        <div class="filter_field">
          <h4>Due Date</h4>
          <div class="filter_date">
            <input
              type="checkbox"
              [(ngModel)]="filters.due_date_filters.noDate"
              (ngModelChange)="onFilterChange()"
              id="noDate"
              class="filter_input"
            />
            <div class="avatar"><img src="/assets/filter-calendar-icon.svg" alt=""/></div>
            <h5 class="due_dates">No Dates</h5>
          </div>
          <div class="filter_date">
            <input
              id="overdue"
              type="checkbox"
              [(ngModel)]="filters.due_date_filters.overDate"
              (ngModelChange)="onFilterChange()"
              class="filter_input"
            />
            <div style="background-color: #e95b4c" class="avatar red_icon">
              <img src="/assets/filter-over-due.svg" alt=""/>
            </div>
            <h5 class="due_dates">Overdue</h5>
          </div>
          <div class="filter_date">
            <input
              id="dueNextDay"
              type="checkbox"
              [(ngModel)]="filters.due_date_filters.dueInNextDay"
              (ngModelChange)="onFilterChange()"
              class="filter_input"
            />
            <div style="background: #f1d52f" class="avatar yellow_icon">
              <img src="/assets/filter-next-due.svg" alt=""/>
            </div>
            <h5 class="due_dates">Due in the next day</h5>
          </div>
          <div class="filter_showMore">
            <button id="filter_button" (click)="filterShowMore()" [style.display]="isFilterShowMore ? 'block' : 'none'">
              Show More Options <span class="icon-context icon-add"></span>
            </button>
            <div class="more_option" [style.display]="isMoreFilterDiv ? 'block' : 'none'">
              <div class="filter_date">
                <input
                  id="dueNextWeek"
                  type="checkbox"
                  [(ngModel)]="filters.due_date_filters.dueInNextWeek"
                  (ngModelChange)="onFilterChange()"
                  class="filter_input"
                />
                <div class="avatar more-icon">
                  <span class="icon-context icon-time"></span>
                </div>
                <h5 class="due_dates">Due in the Next week</h5>
              </div>
              <div class="filter_date">
                <input
                  id="dueNextMonth"
                  type="checkbox"
                  [(ngModel)]="filters.due_date_filters.dueInNextMonth"
                  (ngModelChange)="onFilterChange()"
                  class="filter_input"
                />
                <div class="avatar"><span class="icon-context icon-time"></span></div>
                <h5 class="due_dates">Due in the Next Month</h5>
              </div>
            </div>
          </div>
        </div>
        <hr class="horizantal-line-3"/>
        <div class="filter_field">
          <h4>Sprints</h4>
          <div class="filter_sprint">
            <input
              id="completedSprint"
              type="checkbox"
              value="check"
              class="filter_input"
              [checked]="isSprintSelected('completed')"
              (change)="handleSprintChange('completed', $event)"
            />
            <h5>Completed Story Points</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="completedMostExpensive"
              type="radio"
              name="sorting-order"
              value="desc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Most Expensive Task</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="completedLeastExpensive"
              type="radio"
              name="sorting-order"
              value="asc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Least Expensive Task</h5>
          </div>
          <div class="filter_sprint">
            <input
              id="openSprint"
              type="checkbox"
              value="check"
              class="filter_input"
              [checked]="isSprintSelected('closed')"
              (change)="handleSprintChange('closed', $event)"
            />
            <h5>Closed Story Points</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="OpenMostExpensive"
              type="radio"
              name="sorting-order"
              value="desc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Most Expensive Task</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="openLeastExpensive"
              type="radio"
              name="sorting-order"
              value="asc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Least Expensive Task</h5>
          </div>
          <div class="filter_sprint">
            <input
              id="plannedSprint"
              type="checkbox"
              value="check"
              class="filter_input"
              [checked]="isSprintSelected('planned')"
              (change)="handleSprintChange('planned', $event)"
            />
            <h5>Planned Story Points</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="plannedMostExpensive"
              type="radio"
              name="sorting-order"
              value="desc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Most Expensive Task</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="plannedLeastExpensive"
              type="radio"
              name="sorting-order"
              value="asc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Least Expensive Task</h5>
          </div>
          <div class="filter_sprint">
            <input
              id="backlogStoryPoints"
              type="checkbox"
              value="check"
              class="filter_input"
              [checked]="isSprintSelected('backlog')"
              (change)="handleSprintChange('backlog', $event)"
            />
            <h5>Backlog Story Points</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="backlogMostExpensive"
              type="radio"
              name="sorting-order"
              value="desc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Most Expensive Task</h5>
          </div>
          <div class="sub-filter_sprint">
            <input
              id="backlogLeastExpensive"
              type="radio"
              name="sorting-order"
              value="asc"
              (change)="handleSortChange($event)"
              class="sub_filter_input"
            />
            <h5>Least Expensive Task</h5>
          </div>
        </div>
        <hr class="horizantal-line-3"/>

        <div class="filter_field label_filter_field" id="label_filter_field">
          <h4>Labels</h4>
          <div class="filter-labels-wrapper">
            <div class="filter_member">
              <input
                id="noMember"
                type="checkbox"
                [(ngModel)]="filters.no_label"
                (ngModelChange)="onFilterChange()"
                class="filter_input"
              />
              <div class="avatar">
                <img src="/assets/label-icon.svg" alt=""/>
              </div>
              <h5>No Label</h5>
            </div>

            <div *ngFor="let label of labels" class="filter_member">
              <input
                id="label-679"
                type="checkbox"
                value="check"
                class="filter_input"
                [checked]="isLabelSelected(label.id)"
                (change)="handleLabelChange(label.id, $event)"
              />
              <div class="filter_label" [style.background-color]="label.color.hexcode" [style.color]="getContrastColor(label.color.hexcode)">
                {{ label.title }}
              </div>
            </div>
          </div>
        </div>
        <hr class="horizantal-line-3"/>
        <div class="filter_field">
          <h4>Status</h4>
          <div class="filter_member">
            <input
              type="checkbox"
              value="check"
              id="selectStatus"
              class="filter_input"
              [checked]="isAllStatusSelected()"
              (change)="selectAllStatuses($event)"
            />
            <form>
              <div
                #filterStatusCheckbox
                class="multiselect filter_status_checkbox">
                <div class="selectBox" id="showStatusCheckBoxes" (click)="toggleStatusDiv()">
                  <select>
                    <option>Select Status</option>
                  </select>
                  <div class="overSelect"></div>
                </div>
                <div id="type_checkboxes" [style.visibility]="isSelectStatusOptionVisible">
                  <label *ngFor="let status of ticketStatus" class="member">
                    <label class="filter-checkbox-wrapper">
                      <input
                        type="checkbox"
                        class="filter_input"
                        [checked]="isStatusSelected(status.id)"
                        (change)="handleStatusChange(status.id, $event)"
                      />
                    </label>
                    <h5>{{ status.name }}</h5>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr class="horizantal-line-3"/>
        <div class="filter_field label_filter_field">
          <h4>Types</h4>
          <div class="filter_member">
            <input
              type="checkbox"
              value="check"
              id="selectTypes"
              class="filter_input"
              [checked]="isAllTypesSelected()"
              (change)="selectAllTypes($event)"
            />
            <form>
              <div
                #filterTypeCheckbox
                class="multiselect filter_type_checkbox">
                <div class="selectBox" id="showTypeCheckBoxes" (click)="toggleTypeDiv()">
                  <select>
                    <option>Select Type</option>
                  </select>
                  <div class="overSelect"></div>
                </div>
                <div id="type_checkboxes" [style.visibility]="isSelectTypeOptionVisible">
                  <label *ngFor="let taskType of taskTypes" class="member">
                    <label class="filter-checkbox-wrapper">
                      <input
                        id="type-1"
                        type="checkbox"
                        class="filter_input"
                        [checked]="isTypeSelected(taskType.id)"
                        (change)="handleTypeChange(taskType.id, $event)"
                      />
                    </label>
                    <h5>{{ taskType.name }}</h5>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="filter_match pb-3">
      <select name="match" id="match" [(ngModel)]="filters.subject.search_type">
        <option value="any_match">Any Match</option>
        <option value="exact_match">Exact Match</option>
      </select>
      <hr class="horizantal-line-3"/>
    </div>
  </div>
</div>
