<div class="spot-modal spot-modal_wide op-wp-table-export">

  <div class="spot-modal--header">{{text.title}}</div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container">
    <ul *ngIf="exportOptions" class="op-export-options" [ngClass]="{'-hidden': isLoading}">
      <li
        *ngFor="let option of exportOptions"
        class="op-export-options--option"
      >
        <a
          [attr.href]="option.url"
          class="op-export-options--option-link"
          (click)="triggerByLink(option.url, $event)"
          id="export-{{ option.identifier }}"
        >
          <op-icon icon-classes="icon-export-{{ option.identifier }} icon-big"></op-icon>
          <span class="op-export-options--option-label" [textContent]="option.label"></span>
        </a>
      </li>
    </ul>
  </div>
  <div class="spot-action-bar hidden-for-mobile">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button button_no-margin spot-modal--cancel-button spot-action-bar--action"
        (click)="closeMe()"
        >
        {{ text.cancelButton }}
     </button>
    </div>
  </div>
</div>
