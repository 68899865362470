<div
  *ngIf="resource && $attachments | async as attachments"
>
  <ul
    *ngIf="attachments.length > 0"
    class="spot-list spot-list_compact op-files-tab--file-list">
    <li
      *ngFor="let attachment of attachments; let i = index;"
      op-attachment-list-item
      class="spot-list--item op-files-tab--file-list-item"
      data-qa-selector="op-attachment-list-item"
      [attachment]="attachment"
      [index]="i"
      (removeAttachment)="removeAttachment(attachment)"
    ></li>
  </ul>
</div>
