import {
  ChangeDetectionStrategy,
  Component,
  AfterViewInit,
  NgModule,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  HostListener,
} from "@angular/core";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: "filter-component",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements AfterViewInit {
  @Input() members: { [key: string]: any }[];
  @Input() labels: { [key: string]: any }[];
  @Input() taskTypes: { [key: string]: any }[];
  @Input() ticketStatus: { [key: string]: any }[];
  @Input() showFilter: boolean;
  @Input() currentUser: { [key: string]: any };
  @Output() updateShowEvent = new EventEmitter<void>();
  @Output() filterParamsEvent = new EventEmitter<string>();
  @Output() sortParamsEvent = new EventEmitter<string>();
  @Input() filterButton: any;

  @ViewChild("filterMemberCheckbox") filterMemberCheckbox: ElementRef;
  @ViewChild("filterStatusCheckbox") filterStatusCheckbox: ElementRef;
  @ViewChild("filterTypeCheckbox") filterTypeCheckbox: ElementRef;
  @ViewChild("filterSidenav") filterSidenav: ElementRef;
  @ViewChild('isClosedInput') isClosedInput: any;
  @ViewChild('showAllInput') showAllInput: any;
  @ViewChild('allMemberInput') allMemberInput: any;

  public isSelectMemberDivVisible = false;
  public isSelectMemberOptionVisible: string = "hidden";
  public isSelectTypeDivVisible = false;
  public isSelectTypeOptionVisible: string = "hidden";
  public isSelectStatusDivVisible = false;
  public isSelectStatusOptionVisible: string = "hidden";
  public isDivVisible: string = "hidden";
  public isFilterShowMore = true;
  public isMoreFilterDiv = false;
  public searchedMembers: { [key: string]: any }[] = [];
  public filters: {
    closed: boolean;
    show_all: boolean;
    assigned_to_id: number[];
    no_member: boolean;
    members: any[]; // You can replace 'any' with the actual type of members if you have a specific type.
    due_date_filters: {
      noDate: boolean;
      overDate: boolean;
      dueInNextDay: boolean;
      dueInNextWeek: boolean;
      dueInNextMonth: boolean;
    };
    no_label: boolean;
    label_ids: number[];
    subject: {
      subject: string;
      search_type: string;
    };
    type_id: number[];
    status_id: number[];
    story_points: string[];
    order: string;
  } = {
    closed: false,
    show_all: true,
    assigned_to_id: [],
    no_member: false,
    members: [],
    due_date_filters: {
      noDate: false,
      overDate: false,
      dueInNextDay: false,
      dueInNextWeek: false,
      dueInNextMonth: false,
    },
    no_label: false,
    label_ids: [],
    subject: {
      subject: "",
      search_type: "any_match",
    },
    type_id: [],
    status_id: [],
    story_points: [],
    order: "",
  };

  constructor() {}

  ngAfterViewInit(): void {
    if (this.showFilter) {
      this.isDivVisible = "visible";
    } else {
      this.isDivVisible = "hidden";
    }

    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const filtersValue = urlSearchParams.get("filterParams");
    if (filtersValue) {
      let decodedFilters = decodeURIComponent(filtersValue).split("=");
      let filtersObject =
        decodedFilters.length > 1 ? JSON.parse(decodedFilters[1]) : JSON.parse(decodedFilters[0]).filters;
      this.presistQueryParamFilters(filtersObject);
      this.onFilterChange();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showFilter) {
      if (changes.showFilter.currentValue) {
        this.isDivVisible = "visible";
      } else {
        this.isDivVisible = "hidden";
      }
    }
  }

  hideDiv() {
    this.isDivVisible = "hidden";
    this.isSelectMemberOptionVisible = "hidden";
    this.isSelectTypeOptionVisible = "hidden";
    this.isSelectStatusOptionVisible = "hidden";
    this.isSelectMemberDivVisible = false;
    this.isSelectTypeDivVisible = false;
    this.isSelectStatusDivVisible = false;
    this.updateShowEvent.emit();
  }

  @HostListener("document:click", ["$event"])
  clickOutside(event: Event) {
    if (!this.filterMemberCheckbox.nativeElement.contains(event.target)) {
      this.isSelectMemberDivVisible = !this.isSelectMemberDivVisible;
      this.isSelectMemberOptionVisible = "hidden";
    }
    if (!this.filterStatusCheckbox.nativeElement.contains(event.target)) {
      this.isSelectStatusDivVisible = !this.isSelectStatusDivVisible;
      this.isSelectStatusOptionVisible = "hidden";
    }
    if (!this.filterTypeCheckbox.nativeElement.contains(event.target)) {
      this.isSelectTypeDivVisible = !this.isSelectTypeDivVisible;
      this.isSelectTypeOptionVisible = "hidden";
    }
    if (
      !this.filterSidenav.nativeElement.contains(event.target) &&
      !this.filterButton.nativeElement.contains(event.target)
    ) {
      this.updateShowEvent.emit();
    }
  }

  searchMembers(query: string): void {
    if (query == "") {
      this.searchedMembers = this.members;
    } else {
      this.searchedMembers = this.members.filter((member) => {
        const fullName = `${member.firstname} ${member.lastname}`.toLowerCase();
        return fullName.includes(query.toLowerCase());
      });
    }
  }

  toggleMemberDiv() {
    this.isSelectMemberDivVisible = !this.isSelectMemberDivVisible;
    if (this.isSelectMemberDivVisible) {
      this.isSelectMemberOptionVisible = "hidden";
    } else {
      this.isSelectMemberOptionVisible = "visible";
    }
  }

  toggleTypeDiv() {
    this.isSelectTypeDivVisible = !this.isSelectTypeDivVisible;
    if (this.isSelectTypeDivVisible) {
      this.isSelectTypeOptionVisible = "hidden";
    } else {
      this.isSelectTypeOptionVisible = "visible";
    }
  }

  toggleStatusDiv() {
    this.isSelectStatusDivVisible = !this.isSelectStatusDivVisible;
    if (this.isSelectStatusDivVisible) {
      this.isSelectStatusOptionVisible = "hidden";
    } else {
      this.isSelectStatusOptionVisible = "visible";
    }
  }

  filterShowMore() {
    this.isFilterShowMore = !this.isFilterShowMore;
    this.isMoreFilterDiv = !this.isMoreFilterDiv;
  }

  trimInput(value: string): string {
    return value?.trim();
  }

  updateFilters(inputName: string): void {
    if (inputName === 'isClosedInput') {
      this.filters.show_all = !this.isClosedInput.nativeElement.checked
    } else if (inputName === 'showAllInput') {
      this.filters.closed = !this.showAllInput.nativeElement.checked;
    }
    this.onFilterChange();
  }

  debounce(callback: (...args: any[]) => void, wait: number): (...args: any[]) => void {
    let timeoutId: any = null;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  }

  // delay the search request by 800 ms
  handleSearchInput = this.debounce((ev: any) => {
    this.onFilterChange();
  }, 800);



  onFilterChange() {
    const trimmedValue = this.trimInput(this.filters.subject.subject);
    this.filters.subject.subject = trimmedValue;
    const filteredParams = this.filterObject(this.filters);
    const paramsString = JSON.stringify(filteredParams);
    let params = new HttpParams().set("filters", paramsString).toString();

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("filterParams", params);
    window.history.replaceState({}, "", url.toString());

    this.filterParamsEvent.emit(params);
  }

  presistQueryParamFilters(filtersObject: any): void {
    Object.keys(filtersObject).forEach((key) => {
      if (key in this.filters) {
        (this.filters as any)[key] = Array.isArray(filtersObject[key]) ? [...filtersObject[key]] : filtersObject[key];
      } else if (key === "due_date_filters" && typeof filtersObject[key] === "object") {
        const nestedKeys = Object.keys(filtersObject[key]);
        nestedKeys.forEach((nestedKey) => {
          if (nestedKey in this.filters.due_date_filters) {
            (this.filters.due_date_filters as any)[nestedKey] = filtersObject[key][nestedKey];
          }
        });
      }
    });
  }

  checkIfImageExists(url: string): boolean {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };

      img.onerror = () => {
        return false;
      };
    }
    return false;
  }

  handleTypeChange(taskTypeId: number, event: any): void {
    if (event.target.checked) {
      this.filters.type_id.push(taskTypeId);
    } else {
      let indexToRemove = this.filters.type_id.indexOf(taskTypeId);

      if (indexToRemove !== -1) {
        this.filters.type_id.splice(indexToRemove, 1);
      }
    }

    this.onFilterChange();
  }

  handleLabelChange(labelId: number, event: any): void {
    if (event.target.checked) {
      this.filters.label_ids.push(labelId);
    } else {
      let indexToRemove = this.filters.label_ids.indexOf(labelId);

      if (indexToRemove !== -1) {
        this.filters.label_ids.splice(indexToRemove, 1);
      }
    }

    this.onFilterChange();
  }

  handleStatusChange(statusId: number, event: any): void {
    if (event.target.checked) {
      this.filters.status_id.push(statusId);
    } else {
      let indexToRemove = this.filters.status_id.indexOf(statusId);

      if (indexToRemove !== -1) {
        this.filters.status_id.splice(indexToRemove, 1);
      }
    }

    this.onFilterChange();
  }

  handleSprintChange(sprintStatus: string, event: any): void {
    if (event.target.checked) {
      this.filters.story_points.push(sprintStatus);
    } else {
      let indexToRemove = this.filters.story_points.indexOf(sprintStatus);

      if (indexToRemove !== -1) {
        this.filters.story_points.splice(indexToRemove, 1);
      }
    }

    this.onFilterChange();
  }

  handleSortChange(event: any): void {
    if (event.target.checked) {
      this.sortParamsEvent.emit(event.target.value);
    }
  }

  selectMember(memberId: number, event: any): void {
    if (event.target.checked) {
      this.filters.assigned_to_id.push(memberId);
    } else {
      let indexToRemove = this.filters.assigned_to_id.indexOf(memberId);

      if (indexToRemove !== -1) {
        this.filters.assigned_to_id.splice(indexToRemove, 1);
      }
    }

    this.onFilterChange();
  }

  getMember(assigneeId: number) {
    return this.members.find((obj) => obj.id === assigneeId) || this.currentUser;
  }

  getAvatarBackground(user: any): string {
    let name = [user?.firstname || "", user?.lastname || ""].join(" ").trim();
    let hash = 0;

    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    return `hsl(${hash % 360}, 50%, 50%)`;
  }

  typeName(typeId: number) {
    return this.taskTypes.find((obj) => obj.id === typeId);
  }

  statusName(statusId: number) {
    return this.ticketStatus.find((obj) => obj.id === statusId);
  }

  getLabel(labelId: number) {
    return this.labels.find((obj) => obj.id === labelId);
  }

  getLabelBackground(labelId: number) {
    return this.labels.find((obj) => obj.id === labelId);
  }

  getMemberName(memberID: number): string {
    const member = this.getMember(memberID);

    if (member) {
      return member.firstname + " " + member.lastname;
    }

    return "Not found";
  }

  isMemberSelected(memberId: number): boolean {
    return this.filters.assigned_to_id.includes(memberId);
  }

  isStatusSelected(statusId: number): boolean {
    return this.filters.status_id.includes(statusId);
  }

  isLabelSelected(labelId: number): boolean {
    return this.filters.label_ids.includes(labelId);
  }

  isSprintSelected(sprintStatus: string): boolean {
    return this.filters.story_points.includes(sprintStatus);
  }

  getMemberShortName(member: any): string {
    return member?.firstname[0] + member?.lastname[0];
  }

  selectAllTypes(event: any): void {
    if (event.target.checked) {
      this.filters.type_id = this.taskTypes.map((item) => item.id);
    } else {
      this.filters.type_id = [];
    }
    this.onFilterChange();
  }

  selectAllStatuses(event: any): void {
    if (event.target.checked) {
      this.filters.status_id = this.ticketStatus.map((item) => item.id);
    } else {
      this.filters.status_id = [];
    }
    this.onFilterChange();
  }

  isAllTypesSelected(): boolean {
    return JSON.stringify(this.filters.type_id.sort()) === JSON.stringify(this.taskTypes.map((item) => item.id).sort());
  }

  isAllStatusSelected(): boolean {
    return (
      JSON.stringify(this.filters.status_id.sort()) === JSON.stringify(this.ticketStatus.map((item) => item.id).sort())
    );
  }

  isTypeSelected(typeId: number): boolean {
    return this.filters.type_id.includes(typeId);
  }

  isAllMembersSelected(): boolean {
    return (
      JSON.stringify(this.filters.assigned_to_id.sort()) === JSON.stringify(this.members.map((item) => item.id).sort())
    );
  }

  isAllDropDownMembersSelected(): boolean {
    return (
      JSON.stringify(this.filters.assigned_to_id.filter((m)=> {return m !== this.currentUser.id} ).sort()) === JSON.stringify(this.membersList().map((item) => item.id).sort())
    );
  }

  selectAllMembers(event: any): void {
    if (event.target.checked) {
      if (this.isCurrentUserSelected()) {
        this.filters.assigned_to_id = this.membersList().map((item) => item.id);
        this.filters.assigned_to_id.push(this.currentUser.id);
      } else {
        this.filters.assigned_to_id = this.membersList().map((item) => item.id);
      }
    } else {
      if (this.isCurrentUserSelected()) {
        this.filters.assigned_to_id = [this.currentUser.id];
      } else {
        this.filters.assigned_to_id = [];
      }
    }

    this.onFilterChange();
  }

  isCurrentUserSelected() {
    return (this.filters.assigned_to_id.includes(this.currentUser?.id));
  }

  isOnlyCurrentUserSelected() {
    return (this.filters.assigned_to_id.includes(this.currentUser?.id) && this.filters.assigned_to_id.length === 1);
  }

  membersList() {
    return this.members.filter((member) => {
      return member.id !== this.currentUser.id;
    })
  }

  memberSelectedMessage() {
    let selectedMembersCount = this.filters.assigned_to_id.filter((assigned_to) => {
      return assigned_to !== this.currentUser.id;
    }).length;
    if (selectedMembersCount > 0) {
      if (selectedMembersCount === 1) {
        return `${selectedMembersCount} member selected`;
      } else {
        return `${selectedMembersCount} members selected`;
      }
    } else {
      return 'Select members';
    }
  }

  

  cancelFilter(type: string, idValue: any = null): void {
    if (type == "closed") {
      this.filters.closed = false;
    } else if (type == "show_all") {
      this.filters.show_all = false;
    } else if (type == "subject") {
      this.filters.subject.subject = "";
    } else if (type == "assigned_to_id") {
      this.filters.assigned_to_id = this.filters.assigned_to_id.filter((item) => item !== idValue);
    } else if (type == "no_member") {
      this.filters.no_member = false;
    } else if (type == "overDate") {
      this.filters.due_date_filters.overDate = false;
    } else if (type == "noDate") {
      this.filters.due_date_filters.noDate = false;
    } else if (type == "dueInNextDay") {
      this.filters.due_date_filters.dueInNextDay = false;
    } else if (type == "dueInNextWeek") {
      this.filters.due_date_filters.dueInNextWeek = false;
    } else if (type == "dueInNextMonth") {
      this.filters.due_date_filters.dueInNextMonth = false;
    } else if (type == "no_label") {
      this.filters.no_label = false;
    } else if (type == "label_ids") {
      this.filters.label_ids = this.filters.label_ids.filter((item) => item !== idValue);
    } else if (type == "type_id") {
      this.filters.type_id = this.filters.type_id.filter((item) => item !== idValue);
    } else if (type == "status_id") {
      this.filters.status_id = this.filters.status_id.filter((item) => item !== idValue);
    } else if (type == "story_points") {
      this.filters.story_points = this.filters.story_points.filter((item) => item !== idValue);
    } else if (type == "order") {
      this.filters.order = idValue;
      this.unselectAllRadios();
    } else if (type == "all_members") {
      if (this.isCurrentUserSelected()) {
        this.filters.assigned_to_id = [this.currentUser.id];
      } else {
        this.filters.assigned_to_id = [];
      }
    }

    this.onFilterChange();
  }

  unselectAllRadios() {
    const radioButtons = document.getElementsByName("sorting-order");
    radioButtons.forEach((radio: any) => {
      radio.checked = false;
    });
  }

  filterObject(obj: any): any {
    return Object.entries(obj).reduce((acc: { [key: string]: any }, [key, value]) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        const nestedFiltered = this.filterObject(value);
        if (Object.keys(nestedFiltered).length > 0) {
          acc[key] = nestedFiltered;
        }
      } else if (value !== false && value !== "" && !(Array.isArray(value) && value.length === 0)) {
        acc[key] = value;
      }
      return acc;
    }, {});
  }

  getContrastColor(hexColor: string): string {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    if (luminance > 0.5) {
        return '#000000';
    } else {
        return '#ffffff';
    }
  }
}
