<widget-header
    [name]="widgetName"
    (onRenamed)="renameWidget($event)">

  <widget-menu
      slot="menu"
      [resource]="resource">
  </widget-menu>
</widget-header>

<ng-container wp-isolated-query-space>
  <op-wp-calendar
      [static]="true"></op-wp-calendar>
</ng-container>
