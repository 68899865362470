<ng-content select="[slot=trigger]"></ng-content>
<div
  [ngClass]="['spot-drop-modal--body', 'spot-container', alignmentClass]"
  (click)="onBodyClick($event)"
  cdkTrapFocus
  *ngIf="open"
>
  <ng-content select="[slot=body]"></ng-content>

  <button
    class="spot-drop-modal--close-button spot-button"
    type="button"
    [attr.aria-label]="text.close"
    (click)="close()"
  >
    <span class="spot-icon spot-icon_close"></span>
  </button>
</div>
