<ng-container *ngFor="let member of members_?.slice(0, 3); let i = index">
  <div>
    <div (mouseenter)="onSingleAvatarTooltipEnter($event, i, true)" (mouseleave)="onSingleAvatarTooltipLeave(i, false)">
      <div class="member-circle" [style.z-index]="member.length + i" *ngIf="i < 2">
        <div class="member">
          <ng-container *ngIf="member.get_user_avatar">
            <img style="clip-path: circle()" [src]="getMemberAvatarURL(member.user_id)"
              alt="{{ member.user.firstname }} {{ member.user.lastname }}" />
          </ng-container>
          <ng-container *ngIf="!member.get_user_avatar">
            <div [style.background-color]="getAvatarBackgroundColor(member.user.firstname, member.user.lastname)"
              class="sprint-member">
              {{ getInitials(member.user.firstname, member.user.lastname) }}
            </div>
          </ng-container>
        </div>
        <div [style.left.px]="i == 1 ? tooltipStyle + 20 : tooltipStyle" class="single-avatar-tooltip-wrapper" #singleAvatarTooltip>
          <div class="avatar-tooltip">
            <div id="sprint-loading-indicator--background" class="loading-indicator--background" *ngIf="isLoading">
              <div class="loading-indicator mt-3">
                <div class="block-1"></div>
                <div class="block-2"></div>
                <div class="block-3"></div>
                <div class="block-4"></div>
                <div class="block-5"></div>
              </div>
            </div>
            <ng-container *ngIf="member.get_user_avatar">
              <img [src]="getMemberAvatarURL(member.user_id)"
                alt="{{ member.user.firstname }} {{ member.user.lastname }}" />
  
            </ng-container>
            <ng-container *ngIf="!member.get_user_avatar">
              <div style="width: 70px; height: 70px; border-radius: 8px"
                [style.background-color]="getAvatarBackgroundColor(member.user.firstname, member.user.lastname)"
                class="avatar-initials">
                {{ getInitials(member.user.firstname, member.user.lastname) }}
              </div>
            </ng-container>
           
            <div class="multi-member-tooltip-data">
              <div class="multi-member-tooltip-name">{{ member.user.firstname }}{{ member.user.lastname }}</div>
              <div class="multi-member-tooltip-email">{{ member.user.mail }}</div>
              <div class="multi-member-tooltip-storypoints">{{ member?.story_points || 0 }} Story points</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div (mouseenter)="onMultiAvatarTooltipEnter($event, i, true)" (mouseleave)="onMultiAvatarTooltipLeave(i, false)">
      <div class="member-circle" *ngIf="i == 2">
        <div class="sprint-member" style="background-color: grey">
          +{{ members.length - 2 }}
        </div>
        <div [style.left.px]="tooltipStyle + 45" class="multi-avatar-tooltip-wrapper" #multiAvatarTooltip>
          <div class="avatar-tooltip-multi" [ngClass]="{ 'd-block': showTooltip[i], 'd-none': !showTooltip[i] }">
            <div id="sprint-loading-indicator--background" class="loading-indicator--background" *ngIf="isLoading">
              <div class="loading-indicator">
                <div class="block-1"></div>
                <div class="block-2"></div>
                <div class="block-3"></div>
                <div class="block-4"></div>
                <div class="block-5"></div>
              </div>
            </div>
            <div class="multi-avatars-list" *ngFor="let m of members_.slice(2, members_.length)">
              <ng-container *ngIf="m.get_user_avatar">
                <img style="width: 70px; border-radius: 8px" [src]="getMemberAvatarURL(m.user_id)"
                  alt="{{ m.user.firstname }} {{ m.user.lastname }}" />
              </ng-container>
              <ng-container *ngIf="!m.get_user_avatar">
                <div style="width: 70px; height: 70px; border-radius: 8px"
                  [style.background-color]="getAvatarBackgroundColor(m.user.firstname, m.user.lastname)"
                  class="avatar-initials">
                  {{ getInitials(m.user.firstname, m.user.lastname) }}
                </div>
              </ng-container>
              <div class="multi-member-tooltip-data">
                <div class="multi-member-tooltip-name">{{ m.user.firstname }}{{ m.user.lastname }}</div>
                <div class="multi-member-tooltip-email">{{ m.user.mail }}</div>
                <div class="multi-member-tooltip-storypoints">{{ m?.story_points || 0 }} Story points</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</ng-container>
<ng-container *ngFor="let user of users?.slice(0, 3); let i = index">
  <div>
    <div (mouseenter)="onSingleUserTooltipEnter($event, i, true)" (mouseleave)="onSingleUserTooltipLeave(i, false)">
      <div class="member-circle" [style.z-index]="user.length + i" *ngIf="i < 2">
        <div class="member">
          <ng-container *ngIf="user.get_user_avatar">
            <img style="clip-path: circle()" [src]="getMemberAvatarURL(user.id)"
              alt="{{ user.firstname }} {{ user.lastname }}" />
          </ng-container>
          <ng-container *ngIf="!user.get_user_avatar">
            <div [style.background-color]="getAvatarBackgroundColor(user.firstname, user.lastname)"
              class="sprint-member">
              {{ getInitials(user.firstname, user.lastname) }}
            </div>
          </ng-container>
        </div>
        <div [style.left.px]="i == 1 ? tooltipStyle + 20 : tooltipStyle" class="single-avatar-tooltip-wrapper" #singleTooltip>
          <div class="avatar-tooltip" (mouseenter)="hoveredMember($event, true)" (mouseleave)="hoveredMember($event, false)">
            <div class="avatar-tooltip-wrap">
              <ng-container *ngIf="user.get_user_avatar">
                <img [src]="getMemberAvatarURL(user.id)" alt="{{ user.firstname }} {{ user.lastname }}" />

              </ng-container>
              <button (click)="removeMembers(user)" *ngIf="isDeleteable" class="members_close_btn">&times;</button>
              <ng-container *ngIf="!user.get_user_avatar">
                <div style="width: 70px; height: 70px; border-radius: 8px"
                  [style.background-color]="getAvatarBackgroundColor(user.firstname, user.lastname)"
                  class="avatar-initials">
                  {{ getInitials(user.firstname, user.lastname) }}
                </div>
              </ng-container>
              <div class="multi-member-tooltip-data">
                <div class="multi-member-tooltip-name">{{ user.firstname }}{{ user.lastname }}</div>
                <div class="multi-member-tooltip-email">{{ user.mail }}</div>
                <div class="multi-member-tooltip-storypoints">{{ user?.assignee_story_point || 0 }} Story points</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div (mouseenter)="onMultiUserTooltipEnter($event, i, true)" (mouseleave)="onMultiUserTooltipLeave(i, false)">
      <div class="member-circle" *ngIf="i == 2">
        <div class="sprint-member" style="background-color: grey">
          +{{ users.length - 2 }}
        </div>
        <div [style.left.px]="tooltipStyle + 45" class="multi-avatar-tooltip-wrapper" #multiTooltip>
          <div class="avatar-tooltip-multi" [ngClass]="{ 'd-block': showTooltip[i], 'd-none': !showTooltip[i] }">
            <div (mouseenter)="showCloseButton($event)" (mouseleave)="hideCloseButton($event)" class="multi-avatars-list" *ngFor="let u of users.slice(2, users.length)">
              <ng-container *ngIf="u.get_user_avatar">
                <img style="width: 70px; border-radius: 8px" [src]="getMemberAvatarURL(u.id)"
                  alt="{{ u.firstname }} {{ u.lastname }}" />
              </ng-container>
              <button (click)="removeMembers(u)" *ngIf="isDeleteable" class="members_close_btn">&times;</button>
              <ng-container *ngIf="!u.get_user_avatar">
                <div style="width: 70px; height: 70px; border-radius: 8px"
                  [style.background-color]="getAvatarBackgroundColor(u.firstname, u.lastname)" class="avatar-initials">
                  {{ getInitials(u.firstname, u.lastname) }}
                </div>
              </ng-container>
              <div class="multi-member-tooltip-data">
                <div class="multi-member-tooltip-name">{{ u.firstname }}{{ u.lastname }}</div>
                <div class="multi-member-tooltip-email">{{ u.mail }}</div>
                <div class="multi-member-tooltip-storypoints">{{ u?.assignee_story_point || 0 }} Story points</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>