<div [attr.data-tooltip]="trialRequested ? text.confirmation_info(created, email) : ''" [ngClass]="{'tooltip--top': trialRequested}">
  <button
    type="button"
    [disabled]="trialRequested"
    class="button -alt-highlight"
    (click)="openTrialModal()"
  >
    {{ text.button_trial }}
  </button>
</div>
