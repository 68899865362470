<div id="sprint-loading-indicator--background" class="loading-indicator--background" *ngIf="isLoading">
  <div class="loading-indicator">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
</div>
<div>
  <filter-component [members]="assignees" [labels]="labels" [showFilter]="showFilter" [taskTypes]="taskTypes"
    [currentUser]="currentUser" [ticketStatus]="ticketStatus" (updateShowEvent)="hideFilterMenu()"
    (filterParamsEvent)="updateQueryParams($event)" (sortParamsEvent)="sortProjects('spent_hours', $event)"
    [filterButton]="filterButton"></filter-component>
</div>
<div class="ShowMessageToast">
  <div #showErrorMessage *ngIf="errorMessage.length > 0" class="op-toast -error messageShow showErrorMessageTicket">
    <a href="#" title="close" class="op-toast--close icon-context icon-close"></a>
    <div class="op-toast--content" id="error-message" [innerHTML]="errorMessage">
    </div>
  </div>

  <div #showSuccessMessage *ngIf="successMessage.length > 0" class="op-toast -success messageShow showSuccessMessageTicket">
    <a href="#" title="close" class="op-toast--close icon-context icon-close"></a>
    <div class="op-toast--content" id="success-message" [innerHTML]="successMessage">
    </div>
  </div>
</div>

<div class="sprint-list-search-wrapper activeDetailsFilterSpacing" >
  <div class="sprint-list-title-wrapper">
    <h6 class="sprint-heading">Task List</h6>
    <span class="sprint-task-count">({{ totalTickets }} Tasks)</span>
  </div>
  <div #onFilterButton class="filter-options">
    <div class="sprint-filter-wrapper">
      <button class="sprint-filter-btn" (click)="showFilterMenu()">
        <img alt="filter" class="mr-1" src="/assets/filter-icon.svg" />
        Filters
      </button>
    </div>
  </div>
</div>
<div class="projects-setting-dropdown" [ngClass]="{'activeDetails': activeDetailTab}">
  <div class="sprint-settings-dropdown-wrapper sprint-settings" data-sprint-setting="6">
    <button class="sprint-settings-dropdown-btn dashboard-settings-dropdown-btn" #collapseSettingButton type="button" id="sprintSettingsMenuBtn" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="/assets/sprint-table-settings.svg" alt="">
    </button> 
    <div [ngClass]="{'settingVisibility': !isCollapseSettingsVisible}" #collapseSettingDropDown class="dropdown-menu sprint-settings-dropdown-menu project-settings-dropdown-menu" aria-labelledby="sprintSettingsMenuBtn">
        <div class="sprint-settings-dropdown-wrapper">
            <div>
                <h6>Get Started</h6>
                <p>Select columns to show</p>
                <button (click)="handleSelectAll()" class="select-all-btn">Select All</button>
            </div>
            <div class="sprints-table-column-wrapper">
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Assignee.value}"
                  (click)="toggleActiveClass('Assignee')"
                  data-key="Assignee"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Assignee
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Status.value}"
                  (click)="toggleActiveClass('Status')"
                  data-key="Status"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Status
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Type.value}"
                  (click)="toggleActiveClass('Type')"
                  data-key="Type"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Types
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.HoursSpent.value}"
                  (click)="toggleActiveClass('Hours Spent')"
                  data-key="Hours_Spent"
                >
                  <img src="/assets/column-tick.svg" alt="">
                  Hours Spent
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Priority.value}"
                  (click)="toggleActiveClass('Priority')"
                  data-key="Priority"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Priority
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Points.value}"
                  (click)="toggleActiveClass('Points')"
                  data-key="Points"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Points
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Label.value}"
                  (click)="toggleActiveClass('Label')"
                  data-key="label"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Label
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.StartDate.value}"
                  (click)="toggleActiveClass('Start Date')"
                  data-key="Start_date"
                >
                    <img src="/assets/column-tick.svg" alt="">
                    Start Date
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.DueDate.value}"
                  (click)="toggleActiveClass('Due Date')"
                  data-key="Due_date">
                    <img src="/assets/column-tick.svg" alt="">
                    Due Date
                </button>
                <button class="sprint-table-column-btn"
                  [ngClass]="{'active': columnVisibilitySettings.Progress.value}"
                  (click)="toggleActiveClass('Progress')"
                  data-key="Progress">
                    <img src="/assets/column-tick.svg" alt="">
                    Progress
                </button>
            </div>
        </div>
    </div>
  </div>
</div>

<div class="sprints-planning-container">
  <div class="backlog-list-wrapper backlog-area backload-single-redering ticket-listing-wrapper"
    (scroll)="onScroll($event)">
    <div class="sprint-table-wrapper">
      <div class="sprint-table-top-headings">
        <div class="sprint-table-title">
          Title
          <div class="sorting-btn-wrapper">
            <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('subject', 'asc')"></button>
            <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('subject', 'desc')"></button>
          </div>
        </div>
        <div class="sprint-table-tags-heading">
          <div class="sprint-table-tags-title sprint-column-Assignee" key="Assignee" *ngIf='!columnVisibility.Assignee.value'>
            <span (click)="handleColumnClicked('Assignee')" class="sprint-column-Assignee">Assignee</span>
          </div>
          <div class="sprint-table-tags-title sprint-column-Status" key="Status" *ngIf='!columnVisibility.Status.value'>
            <span (click)="handleColumnClicked('Status')" class="sprint-column-Status">Status</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('status_id', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('status_id', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title sprint-column-Type" key="Type" *ngIf='!columnVisibility.Type.value'>
            <span (click)="handleColumnClicked('Type')" class="sprint-column-Type">Type</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('type_id', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('type_id', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title sprint-column-Priority" key="Priority" *ngIf='!columnVisibility.Priority.value'>
            <span (click)="handleColumnClicked('Priority')" class="sprint-column-Priority">Priority</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('priority_id', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('priority_id', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title sprint-column-label" key="label" *ngIf='!columnVisibility.Label.value'>
            <span (click)="handleColumnClicked('Label')" class="sprint-column-label">Label</span></div>
          <div class="sprint-table-tags-title sprint-column-Progress" key="Progress" *ngIf='!columnVisibility.Progress.value'>
            <span (click)="handleColumnClicked('Progress')" class="sprint-column-Progress">Progress</span></div>
          <div class="sprint-table-tags-title sprint-column-hours sprint-column-hours-start" key="Start_date" *ngIf='!columnVisibility.StartDate.value'>
            <span (click)="handleColumnClicked('Start Date')" class="sprint-column-hours-start">Start Date</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('start_date', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('start_date', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title sprint-column-hours sprint-column-hours-due" key="Due_date" *ngIf='!columnVisibility.DueDate.value'>
            <span (click)="handleColumnClicked('Due Date')" class="sprint-column-hours-due">Due Date</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('due_date', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('due_date', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title-number sprint-table-tags-title sprint-column-id" key="id">
            <span style="margin-right: 1px" class="sprint-column-id">Id</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn sprintSortingBtn" (click)="sortProjects('id', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('id', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title-number sprint-table-tags-title sprint-column-hours sprint-column-hours-spent" key="id" *ngIf='!columnVisibility.HoursSpent.value'>
            <span (click)="handleColumnClicked('Hours Spent')" class="sprint-column-hours-spent">Hours Spent</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn asc-sort-sp sprintSortingBtn"
                (click)="sortProjects('spent_hours', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('spent_hours', 'desc')"></button>
            </div>
          </div>
          <div class="sprint-table-tags-title-number sprint-table-tags-title sprint-column-heading-Points" key="Points" *ngIf='!columnVisibility.Points.value'>
            <span (click)="handleColumnClicked('Points')" class="sprint-column-heading-Points">Points</span>
            <div class="sorting-btn-wrapper">
              <button class="sort-up-btn asc-sort-sp sprintSortingBtn"
                (click)="sortProjects('story_points', 'asc')"></button>
              <button class="sort-down-btn sprintSortingBtn" (click)="sortProjects('story_points', 'desc')"></button>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="tickets.length > 0; else noProjectsMessage">
        <div *ngFor="let ticket of tickets; let i = index"
          class="2 sprint-list sprint-list-area-field enable-drag non-epic-task ui-sortable-handle" id="task-id-24"
          parent-id="" data-sprint-id="3" [style.background-color]="i % 2 === 0 ? '#f3f3f3' : '#ffffff'" data="24"
          draggable="true"
          #sprintLists
          (click)="showDetailsMenu(ticket)"
          >
          <div class="sprint-list-heading sprint-column-title">
            <img class="mr-2 list-icon" src="/assets/Group.svg" />

            <p>{{ ticket.title }}</p>
          </div>
          <div class="sprint-list-tags">
            <div class="assigned-members-tag sprint-column-Assignee" *ngIf='!columnVisibility.Assignee.value'>
              <div class="assigned-members-list">
                <member-tooltip [users]="ticket?.assignees_list" style="display: flex"></member-tooltip>
              </div>
            </div>

            <div class="sprint-version-tag sprint-column-Status" *ngIf='!columnVisibility.Status.value'>
              <div class="tag-color" style="background: #ffa8a8"></div>
              <div class="tag-text">{{ ticket.status.name }}</div>
            </div>
            <div class="sprint-variant-tag sprint-column-Type" *ngIf='!columnVisibility.Type.value'>
              <div class="tag-color" style="background: #f03e3e"></div>
              <div class="tag-text">{{ ticket.type.name }}</div>
            </div>
            <div class="priority-type-tag sprint-column-Priority" *ngIf='!columnVisibility.Priority.value'>
              <div class="tag-color" style="background: #74c0fc"></div>
              <div class="tag-text">{{ ticket.priority.name }}</div>
            </div>
            <div class="sprint-label-tag sprint-column-label" *ngIf='!columnVisibility.Label.value'>
              <div *ngIf="ticket?.labels.length > 0" class="sprint-task-label sprint-task-label-2317"
                [style.background-color]="ticket?.labels[0]?.color?.hexcode"
                [style.color]="getContrastColor(ticket?.labels[0]?.color?.hexcode)">
                {{ ticket?.labels[0]?.title }}
              </div>
              <div *ngIf="ticket?.labels.length > 1" class="sprint-task-multiLabel-wrapper ml-1">
                <div (mouseover)="onSprintTaskMultiLabelEnter($event, ticket)"
                  (mouseleave)="onSprintTaskMultiLabelLeave(ticket)" class="task-multiLabel-text">+{{
                  ticket?.labels.length - 1 }}</div>
                <div class="task-multiLabel-tooltip" [ngClass]="'task-'+ticket?.id" #taskMultiLabelTooltip>
                  <div *ngFor="let label of ticket?.labels?.slice(1, ticket?.labels.length)" class="sprint-task-label"
                    [style.background-color]="label?.color?.hexcode"
                    [style.color]="getContrastColor(label?.color?.hexcode)">
                    {{ label?.title }}
                  </div>
                </div>
              </div>
            </div>
            <div class="sprint-progress-tag sprint-column-Progress" *ngIf='!columnVisibility.Progress.value'>
              <div class="sprint-task-progress" (mouseover)="onSprintTaskProgressEnter($event, ticket)"
                (mouseleave)="onSprintTaskProgressLeave(ticket)">
                <div class="task-progress-completed" [style.width.%]="ticket?.progress?.complete_percentage"
                  [style.background]="getProgressColor(ticket)"></div>
                <div class="task-progress-details" #taskProgressDetails>
                  <div class="prog-details-item">
                    <div class="prog-details-item-title green-title">Completed</div>
                    <div class="prog-details-item-value ticket-complete-hours">
                      {{ ticket?.progress?.ticket_completed_hours }}
                      Hr(s)
                    </div>
                  </div>
                  <div class="prog-details-item">
                    <div class="prog-details-item-title gray-title">Remaining</div>
                    <div class="prog-details-item-value ticket-remaining-hours">
                      {{ ticket?.progress?.ticket_remaining_hours }}
                      Hr(s)
                    </div>
                  </div>
                  <div class="prog-details-item">
                    <div class="prog-details-item-title red-title">Extra Hours</div>
                    <div class="prog-details-item-value ticket-extra-hours">
                      {{ ticket?.progress?.extra_time_hours }}
                      Hr(s)
                    </div>
                  </div>
                  <div class="prog-details-item">
                    <div class="prog-details-item-title text-title">Total</div>
                    <div class="prog-details-item-value ticket-total-hours">
                      {{ ticket?.progress?.total_spent_hours }}
                      Hr(s)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sprint-startdate-tag sprint-column-hours" *ngIf='!columnVisibility.StartDate.value'>
              <div class="tag-text" *ngIf="ticket.start_date !== null">{{ ticket.start_date }}</div>
              <div class="tag-text" *ngIf="ticket.start_date === null">None</div>
            </div>
            <div class="sprint-duedate-tag sprint-column-hours" *ngIf='!columnVisibility.DueDate.value'>
              <div class="tag-text" *ngIf="ticket.due_date !== null">{{ ticket.due_date }}</div>
              <div class="tag-text" *ngIf="ticket.due_date === null">None</div>
            </div>
            <div class="sprint-number-tag sprint-column-id">{{ ticket.id }}</div>
            <div class="sprint-hours-tag" *ngIf='!columnVisibility.HoursSpent.value'>{{ ticket?.progress?.spent_hours }}</div>
            <div class="sprint-issues-tag sprint-column-Points" *ngIf='!columnVisibility.Points.value'>{{ ticket.story_points }}</div>
          </div>
        </div>
      </ng-container>
      <ng-template #noProjectsMessage>
        <div>
          <div class="no-projects-text">Data Not Found</div>
        </div>
      </ng-template>

      <div class="sprint-list sprint-list-area-field enable-drag temporary-drag ui-sortable-handle"
        style="display: none; min-height: 50px"></div>
    </div>
    <div class="sprint-collapsed-columns-wrapper project-collapsed-columns-wrapper">
    </div>
  </div>

  <div *ngIf="showDetails" [ngClass]="{'activeDetailsSpacing': activeDetailTab}">
    <ticket-detail [ticket]="selectedTicket" [taskTypes]="taskTypes" [ticketStatus]="ticketStatus"
        [taskPriorities]="taskPriorities" [labels]="labels" [colors]="colors" [members]="members" [projectId]="projectId" 
        (updateShowEvent)="hideDetailsMenu()" (updatedTicketData)="updateTicket($event)" (errorMessage)="setErrorMessage($event)"
        (successMessage)="setSuccessMessage($event)">
    </ticket-detail>
  </div>



</div>