<div class="scroll-container">
  <div class="project-summary-dashboard">
    <div class='projects-dashboard-title'>
      <h1>Projects Summary</h1>
    </div>
    <div class="projects-stats-container">
      <div class="projects-stats-wrapper above_average" (click)="toggleCategory('above_average')"
           [ngClass]="{ 'selected': selectedCategory === 'above_average' }">
        <h6>Above average</h6>
        <div class="projects-progress-detail-wrap">
          <div class="projects-progress-bar"
               [style.background]="'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#1AD598 ' + stats.above_average_percentage + '%, white 0)'"
               *ngIf="stats">
            <span>{{stats.above_average_percentage || 0}}%</span>
          </div>
          <div class='projects-progress-detail' *ngIf="stats">
            <p>
              {{stats.above_average_count}} out of {{stats.total_projects}} Projects are above
              average
            </p>
          </div>
        </div>
      </div>
      <div class="projects-stats-wrapper average" (click)="toggleCategory('average')"
           [ngClass]="{ 'selected': selectedCategory === 'average' }">
        <h6>Average</h6>
        <div class="projects-progress-detail-wrap">
          <div class="projects-progress-bar"
               [style.background]="'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#FCCD44 ' + stats.average_percentage + '%, white 0)'">
            <span>{{stats.average_percentage || 0}}%</span>
          </div>
          <div class='projects-progress-detail' *ngIf="stats">
            <p>
              {{stats.average_count}} out of {{stats.total_projects}} Projects are running average
            </p>
          </div>
        </div>
      </div>
      <div class="projects-stats-wrapper below_average" (click)="toggleCategory('below_average')"
           [ngClass]="{ 'selected': selectedCategory === 'below_average' }">
        <h6>Below average</h6>
        <div class="projects-progress-detail-wrap">
          <div class="projects-progress-bar"
               [style.background]="'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#EE9231 ' + stats.below_average_percentage + '%, white 0)'">
            <span>{{stats.below_average_percentage || 0}}%</span>
          </div>
          <div class='projects-progress-detail' *ngIf="stats">
            <p>
              {{stats.below_average_count}} out of {{stats.total_projects}} Projects are below average
            </p>
          </div>
        </div>
      </div>
      <div class="projects-stats-wrapper red_zone" (click)="toggleCategory('red_zone')"
           [ngClass]="{ 'selected': selectedCategory === 'red_zone' }">
        <h6>Red Zone</h6>
        <div class="projects-progress-detail-wrap">
          <div class="projects-progress-bar"
               [style.background]="'radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(#F77568 ' + stats.red_zone_percentage + '%, white 0)'">
            <span>{{stats.red_zone_percentage || 0}}%</span>
          </div>
          <div class='projects-progress-detail' *ngIf="stats">
            <p>
              {{stats.red_zone_count}} out of {{stats.total_projects}} Projects are in red zone
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class='projects-search-container'>
      <div class='project-search-inp-wrap'>
        <input placeholder='search...' #searchInput autocomplete='off' (input)="onSearch($event.target.value)"/>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M18.2111 20.1797L13.7 15.7442C12.0051 17.0573 9.86412 17.6843 7.71462 17.4969C5.56512 17.3096 3.56957 16.322 2.13625 14.7362C0.702931 13.1504 -0.0599308 11.0862 0.00368098 8.96581C0.0672928 6.84541 0.952643 4.829 2.47853 3.32899C4.00443 1.82899 6.05554 0.958729 8.21254 0.896197C10.3695 0.833664 12.4694 1.58359 14.0825 2.99259C15.6957 4.40159 16.7004 6.3632 16.891 8.47624C17.0816 10.5893 16.4438 12.6941 15.1081 14.3601L19.619 18.7946C19.7257 18.8821 19.8127 18.9904 19.8744 19.1127C19.9361 19.235 19.9713 19.3686 19.9776 19.505C19.9839 19.6414 19.9611 19.7776 19.9109 19.9049C19.8607 20.0322 19.7841 20.1478 19.6859 20.2444C19.5878 20.341 19.4702 20.4164 19.3407 20.4658C19.2113 20.5153 19.0727 20.5377 18.934 20.5316C18.7952 20.5255 18.6593 20.4911 18.5348 20.4305C18.4103 20.3699 18.3001 20.2845 18.2111 20.1797ZM1.99001 9.211C1.98991 10.4616 2.36478 11.6844 3.06777 12.7267C3.77076 13.769 4.77068 14.5845 5.94252 15.0712C7.11437 15.5579 8.40613 15.6943 9.65639 15.4633C10.9066 15.2322 12.06 14.6441 12.9721 13.7723C12.9914 13.7493 13.0116 13.7274 13.033 13.7064C13.0543 13.6854 13.0767 13.6655 13.1 13.6465C13.839 12.8991 14.38 11.9847 14.6752 10.9836C14.9704 9.98254 15.0108 8.92551 14.793 7.90547C14.5751 6.88543 14.1056 5.93364 13.4258 5.13381C12.746 4.33398 11.8766 3.71061 10.8942 3.31853C9.9118 2.92646 8.84649 2.77769 7.79189 2.8853C6.73729 2.99291 5.72574 3.35361 4.84621 3.93567C3.96669 4.51774 3.24616 5.30334 2.74794 6.22338C2.24973 7.14342 1.98912 8.16971 1.98903 9.21198L1.99001 9.211Z"
            fill="#99B2C6"/>
        </svg>
      </div>
    </div>
  </div>
  <div class="project-summary-table">
    <div id="sprint-loading-indicator--background" class="loading-indicator--background" *ngIf="isLoading">
      <div class="loading-indicator">
        <div class="block-1"></div>
        <div class="block-2"></div>
        <div class="block-3"></div>
        <div class="block-4"></div>
        <div class="block-5"></div>
      </div>
    </div>
    <div class='projects-listing-table-container' (scroll)="onScroll($event)">
      <table class='projects-listing-table'>
        <thead class='listing-table-headings'>
        <tr class="table-row">
          <th class='listing-column listing-title-column'>
                    <span class='d-flex align-items-center'>
                        Project Name
                        <div class="sorting-btn-wrapper">
                            <button class="sort-up-btn" (click)="sortProjects('asc')"></button>
                            <button class="sort-down-btn" (click)="sortProjects('desc')"></button>
                        </div>
                    </span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Total Sprints</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Completed Sprints</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Open Sprints</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Backlog Story Points</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Planned Story Points</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Completed Story Points</span>
          </th>
          <th class='listing-column listing-column-size'>
            <span>Closed Story Points</span>
          </th>
          <th class='listing-column listing-progress-column'>
            <span>Overall Progress</span>
          </th>
          <th class='listing-column listing-column-size listing-column-padding'>
            <span>Members</span>
          </th>
        </tr>
        </thead>
        <tbody class='listing-table-body'>
        <ng-container *ngIf="projects.length > 0; else noProjectsMessage">
          <tr *ngFor="let project of projects" class="table-row">
            <td class='listing-td project-name-td'>
              <a href="">
                <img src="/assets/project-icon.svg" alt="delete"/>
                {{project.project_name}}
              </a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.total_sprints > 0 ? '/projects/' + project.id + '/sprint_overview?' + getRoadMapFilters('allSprints') : '#' "
                [style.color]="project.total_sprints > 0 ? '#0047ff' : '#172b4d'">{{project.total_sprints}}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.completed_sprints > 0 ? '/projects/' + project.id + '/sprint_overview?' + getRoadMapFilters('closedSprints') : '#' "
                [style.color]="project.completed_sprints > 0 ? '#0047ff' : '#172b4d'">{{project.completed_sprints}}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.open_sprints > 0 ? '/projects/' + project.id + '/sprint_overview?' + getRoadMapFilters('openSprints') : '#' "
                [style.color]="project.open_sprints > 0 ? '#0047ff' : '#172b4d'">{{project.open_sprints}}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.backlog_story_points > 0 ? '/projects/' + project.id + '/project_tickets?' + getFilterParams('backlog') : '#' "
                [style.color]="project.backlog_story_points > 0 ? '#0047ff' : '#172b4d'">{{project.backlog_story_points}}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.planned_story_points > 0 ? '/projects/' + project.id + '/project_tickets?' + getFilterParams('planned') : '#' "
                [style.color]="project.planned_story_points > 0 ? '#0047ff' : '#172b4d'">{{project.planned_story_points}}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="roundUpCompletedStoryPoints(project.completed_story_points) > 0 ? '/projects/' + project.id + '/project_tickets?' + getFilterParams('completed') : '#' "
                [style.color]="roundUpCompletedStoryPoints(project.completed_story_points) > 0 ? '#0047ff' : '#172b4d'">{{ roundUpCompletedStoryPoints(project.completed_story_points) }}</a>
            </td>
            <td class='listing-td project-data-td'>
              <a
                [href]="project.closed_story_points > 0 ? '/projects/' + project.id + '/project_tickets?' + getFilterParams('closed') : '#' "
                [style.color]="project.closed_story_points > 0 ? '#0047ff' : '#172b4d'">{{project.closed_story_points}}</a>
            </td>
            <td class='listing-td project-progress-td'>
              <div class="project-progress">
                <div class="project-progress-completed" [style.width.%]="project.overall_progress"
                     [style.background-color]="getProgressBarColor(project.overall_progress || 0)">
                </div>
              </div>
              <span class="project-progress-text">{{project.overall_progress || 0}}%</span>
            </td>
            <td class='listing-td project-member-td'>
              <member-tooltip [members]="project.members" style="display: flex;"></member-tooltip>
            </td>
          </tr>
        </ng-container>
        <ng-template #noProjectsMessage>
          <tr>
            <td colspan="10" class="no-projects-text">No Projects Found</td>
          </tr>
        </ng-template>
        </tbody>
        <div class="loading-spinner" *ngIf="isSpinning"></div>
      </table>
    </div>
  </div>
</div>
