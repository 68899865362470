<div class="comments w-100">
  <div class="journal-user-img"
      [style.background-color]="getAvatarBackgroundColor(comment?.user?.firstname, comment?.user?.lastname)">
      <ng-container *ngIf="comment?.get_user_avatar == null">
        {{ getUserInitials(comment?.user) }}
      </ng-container>
      <ng-container *ngIf="comment?.get_user_avatar">
        <img
        [src]="getMemberAvatarURL(comment?.user?.id)"
        alt="{{ comment?.user?.firstname }} {{ comment?.user?.lastname }}">
      </ng-container>
    </div>
  <div class="activity_comment w-75">
    <h4>{{ getUserFullName(comment?.user) }}</h4>
    <p class="comment-title-comment-box-123">{{ formatDateToString(comment?.created_at, comment?.updated_at) }}</p>
    <div class="mt-2" [style.display]="isCommentEditable? 'block': 'none'">
      <op-ckeditor  [context]="ckEditorContext"
      [content]="comment.notes"
      (onContentChange)="onContentChange($event)">
      </op-ckeditor>
    </div>
    <span class="mt-2" id="display-comment-123" *ngIf="!isCommentEditable" [innerHTML]="comment?.notes"></span>
    <div class="comment_buttons">
      <div class="d-flex justify-content-end" *ngIf="!isCommentEditable">
        <img class="edit write pe-3" id="btn-edit-comment-123" src="/assets/write.svg" (click)="editComment()"
          alt="">
      </div>
      <div class="comment_edit_buttons d-flex justify-content-end mt-2 mb-4" *ngIf="isCommentEditable">
        <button id="save_description" class="save_description mt-1" (click)="updateComment()">Save</button>
        <button id="cancel_description" class="cancel_description mt-1" (click)="discardComment()">Cancel</button>
      </div>
    </div>
  </div>
</div>