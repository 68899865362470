<div id="id01" class="deleteConfirmation" [style.display]="'none'">
  <form class="modal-content" action="/action_page.php">
    <div class="container">
      <img src="/assets/delIcon.svg" alt="" />
      <p></p>
      <div class="clearfix">
        <button type="button" class="deletebtn">Delete</button>
        <button type="button" class="cancelbtn">Cancel</button>
      </div>
    </div>
  </form>
</div>
<div id="sprint-loading-indicator--background" class="loading-indicator--background" *ngIf="isLoading">
  <div class="loading-indicator">
    <div class="block-1"></div>
    <div class="block-2"></div>
    <div class="block-3"></div>
    <div class="block-4"></div>
    <div class="block-5"></div>
  </div>
</div>
<div id="myModal" class="modal card-modal" style="display: block">
  <div class="backdropModal" [style.display]="'none'"></div>
  <div class="modal-content card-modal-content">
    <input type="hidden" name="work_package_id" id="work-package-modal-id" value="19996" autocomplete="off" />
    <div class="cards_content">
      <div class="cards_main card-modal-main">
        <div class="row">
          <div class="col-lg-9 col-md-8">
            <div class="ticket_title">
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-center w-100">
                <div class="d-flex align-items-start mr-auto mr-sm-0 mt-3 mt-sm-0 w-100">
                  <div class="d-flex cards_title w-100">
                    <button (click)="goBackButton()" style="height: 31px" class="back-button-style op-back-button button hide-when-print work-packages-back-button op-back-button_mobile-limited-width" ng-reflect-ng-class="work-packages-back-button op-b" title="Back">
                      <img class="backButton" src="/assets/keyboard_return.svg" alt="" />
                    </button>
                    <span style="margin-top: 7px" id="ticket_id">{{ticket.id? ticket.id : ''}}:</span>
                    <textarea style="margin-top: 5px" #getTitle type="text" id="ticket_title_input" value="Add your Title Here" maxlength="150"
                      [class]="isCancelSaveCardTitle? 'title_style': null"
                      [attr.readonly]="isEditCardTitle? 'readonly': null"
                      autocomplete="off">{{ticket.subject? ticket.subject : ''}}</textarea>
                  </div>
                  <img class="title_edit_title" id="title_edit_title" src="/assets/tiitle_edit.svg" alt=""
                    [style.display]="isEditCardTitle? 'block': 'none'" (click)="editCardTitle()" />
                  <img id="title_save_title" class="title_save_title d-none" src="/assets/save_title.svg" alt=""
                    [style.display]="isCancelSaveCardTitle? 'block': 'none'" (click)="saveCardTitle()" />
                  <img id="title_cancel_title" class="title_cancel_title d-none" src="/assets/cancel_title.svg" alt=""
                    [style.display]="isCancelSaveCardTitle? 'block': 'none'" (click)="editCancelCardTitle()" />
                  <img class="title_right_border" src="/assets/title_right_border.svg" alt="" />
                  <div class="cards_status">
                    <div class="status_section">
                      <div class="status_detail">
                        <div class="status_dropdown" #statusDropdown>
                          <div class="status_caption" id="project-status" (click)="toggleStatusList()">
                            {{statusById(ticket?.status_id)}}</div>
                          <div class="status_list" id="project-status-list"
                            [style.display]="isShowStatusList? 'block' : 'none'">
                            <ng-container *ngFor="let status of statuses">
                              <div class="status_item" (click)="updateTicketStatus(status?.id)">
                                {{ status?.name }}
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="display: none" class="cards_status">
                    <div class="status_section">
                      <div class="status_detail">
                        <div class="status_dropdown">
                          <div class="status_caption" id="project-close-status"></div>
                          <div class="status_list" id="project-close-status-list">
                            <div class="status_item">Completed</div>
                            <div class="status_item">In Progress</div>
                            <div class="status_item">Closed</div>
                            <div class="status_item">Stop</div>
                            <div class="status_item">Pause</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="d-flex align-items-start justify-content-between h-100 cards_status mt-3">
              <div class="closed-tickets mr-2">
                <label class="closed-tickets-wrapper">
                  <label class="closed-checkbox-wrapper">
                    <input id="closedTick" type="checkbox" value="check" class="closedTick" [checked]="ticket?.closed"
                      (change)="toggleWpStatus($event)" />
                    <!-- <span class="filter-aside-checkmark"></span> -->
                  </label>
                  <p>Close Ticket</p>
                </label>
              </div>
              <div class="title_button d-flex mt-1" #titleButton>
                <img style="cursor:pointer"  class="relation_button button--icon icon-unwatched" id="top-watcher-button"
                  [src]="ticket?.is_watcher ? '/assets/eye_icon2.svg' : '/assets/eye_icon.svg'"
                  alt="" (click)="ticket?.is_watcher ? removeWatcher() : addWatcher()" />

                <img class="option_button" src="/assets/list_icon.svg" alt="" (click)="showOptionDropdown()" />
                <div class="backdrops"></div>
                <div id="option_dropdown" class="option_dropdown_content"
                  [style.display]="isShowOptionDropdown? 'block' : 'none'">
                  <a class="loging_button"><span class="material-icons timelapse"><img class="settingLogs" src="/assets/timelapse.svg" alt="" /></span>Log Time</a>
                  <a id="log-unit-cost" target="_blank" [href]="'/work_packages/'+ticket?.id+'/cost_entries/new'"><span
                      class="material-icons money"><img class="settingLogs" src="/assets/money.svg" alt="" /></span>Log Unit Costs</a>
                  <a id="change-project" target="_blank" [href]="'/work_packages/'+ticket?.id+'/move/new'"><span
                      class="material-icons publishedwithchanges"><img style="height: 12px" class="settingLogs" src="/assets/Vector2.svg" alt="" /></span>Change Project</a>
                  <a id="copy-single" target="_blank" [href]="'/work_packages/'+ticket?.id+'/copy'"><span
                      class="material-icons articlecopy"><img class="settingLogs" src="/assets/article.svg" alt="" /></span>Copy</a>
                  <a id="copy-to-other-project" target="_blank"
                    [href]="'/work_packages/move/new?copy=true&amp;ids[]='+ticket?.id"><span
                      class="material-icons foldercopy"><img class="settingLogs" src="/assets/folder.svg" alt="" /></span>Copy to other Project</a>
                  <a id="download-pdf" target="_blank" [href]="'/work_packages/'+ticket?.id+'.pdf'"><span
                      class="material-icons article"><img class="settingLogs" src="/assets/article.svg" alt="" /></span>Download PDF</a>
                  <a id="download-atom" target="_blank" [href]="'/work_packages/'+ticket?.id+'.atom'"><span
                      class="material-icons download"><img class="settingLogs" src="/assets/file_download.svg" alt="" /></span>Download Atom</a>
                  <a id="deleted-wp" target="_blank" (click)="deleteTicket()">
                    <span class="material-icons delete del-btn"><img class="settingLogs" src="/assets/delete.svg" alt="" /></span>Delete</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row modal_main">
              <div class="col-lg-9 col-md-8 modal_first">
                <div class="row pt-2">
                  <div class="col-xl-9 col-lg-12 col-md-12 col-12 mb-2">
                    <div class="label_section pt-0">
                      <h2>Labels</h2>
                      <div class="label_detail">
                        <div class="label_status" id="labelStatus"></div>
                        <div class="label_section_added">
                          <div class="backdrops"></div>
                          <div id="work_package_labels"
                            class="card-labels-wrapper d-flex flex-wrap align-items-center mb-1">
                            <div *ngFor="let label of ticketLabels" class="card_main"
                              [id]="'workpackage-label-' + label.id">
                              <button class="card_labels modal-label-{{label.id}}"
                                [style.background-color]=label?.color?.hexcode
                                [style.color]="getContrastColor(label?.color?.hexcode)">
                                {{label.title? label.title : ''}}
                              </button>
                              <div class="label_close_box" [id]="'wp-label-rm-' + label.id" [data-id]="label.id"
                                (click)="removeTicketLabel(label.id)"
                                [data-work-package-id]="label.workPackageId">
                                ×
                              </div>
                            </div>
                          </div>
                          <div class="label-dropdown-btn-wrapper" #labelDropdownBtnWrapper>
                            <div id="label_modal" class="label_modal label_modal_btn">
                              <div class="label_content d-none" id="style-1"
                                [style.display]="isShowLabelDropdown ? 'block' : 'none'">
                                <project-labels (remove)="closeLabelDropdownWrapper()" [labels]="labels" [ticketLabels]="ticketLabels" [colors]="colors"
                                  [projectId]="projectId" [ticketId]="ticketId" (updatedLabels)="updateLabels($event)"
                                  (updatedTicketLabels)="updateTicketLabels($event)">
                                </project-labels>
                              </div>
                            </div>
                            <button class="label_add" type="button" id="label_btn" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false" (click)="showLabelDropdownWrapper()">
                              <i class="button--icon icon-add"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-5">
                    <div class="storypoints_section">
                      <h2>Story Points</h2>
                      <div class="storypoints_detail" #storyPointsDetails>
                        <input type="number" id="storypoints_input" class="storypoints_input"
                          value={{ticket?.story_points}} readonly="" (click)="toggleStoryPointsDropdown()" />
                        <button class="storypoints_btn" type="button" (click)="toggleStoryPointsDropdown()">
                          <img src="/assets/tiitle_edit.svg" alt="" />
                        </button>
                        <div class="storypoints_dropdown d-none"
                          [style.display]="isShowStoryPointsDropdown? 'block': 'none'">
                          <div class="storypoints_drop_top">
                            <h3 class="storypoints_title">Story Points</h3>
                            <div class="storypoints_close" (click)="closeStoryPointsDetails()">×</div>
                          </div>
                          <input #storypoints_edit_inp type="number" (input)="validateStoryPoints(storypoints_edit_inp.value)" class="storypoints_edit_inp" value={{ticket?.story_points}} />
                          <span *ngIf="!storypoints_edit_inp.value" class="errorMessageSearch">Enter valid value</span>
                          <div class="storypoints_btns_wrapper">
                            <button class="storypoints_save_btn"
                             [disabled]="!storypoints_edit_inp.value || storypoints_edit_inp.value < 0"
                             (click)="updateStoryPointsDetails()">Submit</button>
                            <button class="storypoints_cancel_btn" (click)="closeStoryPointsDetails()">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-7">
                    <div class="member_section">
                      <h2>Members</h2>
                      <div class="member_detail" #memberDetails>
                        <div class="member_detail" id="display-assignee-list">
                          <member-tooltip (remove)="removeAssignee($event)"
                            [tooltipStyle]="customTooltipStyle"
                            [users]="ticket?.assignees_list"
                            [isDeleteable]="'true'"
                            style="display: flex">
                          </member-tooltip>
                        </div>
                        <button class="member_add ml-1" type="button" id="member_add" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false" (click)="showMemberDropMenu(true)">
                          <i class="button--icon icon-add"></i>
                        </button>
                        <div class="backdrops"></div>
                        <div class="members_drop members_drop_menu members_assignee_drop"
                          [style.display]="isShowMemberDropMenu? 'block': 'none'">
                          <div class="member_drop_content">
                            <span class="members_close" (click)="showMemberDropMenu(false)">×</span>
                            <h3>Members</h3>
                          </div>
                          <input id="search-center-member-list" type="text" placeholder="Search Members.." 
                            #searchMembersInput (input)="searchMembers(searchMembersInput.value)" />
                          <div class="member_list" id="center-select-member-list">
                            <span class="errorMessageSearch" [ngClass]="{'d-none': ticket && searchedMembers.length > 0}">No Members Found</span>
                            <ng-container *ngFor="let member of searchedMembers">
                              <div class="tick member_list_item d-flex align-items-center search-member-select-list-right"
                                (click)="toggleAssignee(member?.user)">
                                <div class="member-select-wrapp">
                                  <div [ngStyle]="{'padding-top.px': member?.get_user_avatar == null ? 6 : 0}" class="member-avatar multi-member-sprint principle-avatar dropdown-journal-user-img"
                                  [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                                    <ng-container *ngIf="member?.get_user_avatar == null">
                                      <span class="no-avatar">
                                        {{ getUserInitials(member?.user) }}
                                      </span>
                                    </ng-container>
                                    <ng-container *ngIf="member?.get_user_avatar">
                                      <img 
                                      [src]="getMemberAvatarURL(member?.user?.id)"
                                      alt="{{ member?.user?.firstname }} {{ member?.user?.lastname }}">
                                    </ng-container>
                                  </div>
                                  <div class="memberSelectedWrapper">
                                    <div class="member_list_item_name">{{ getUserFullName(member?.user) }}</div>
                                    <div class="tickMember" *ngIf="isMember(member?.user)"><img src="/assets/check.svg" class="tick"></div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-8 pt-sm-0">
                    <div class="date_section">
                      <h2>Due Date</h2>
                      <div class="date_detail" #dateDetails>
                        <input type="checkbox" class="d-none" id="date_check" name="date_check" value="date" />
                        <img class="date-set-icon" src="/assets/date.svg" alt="" />
                        <div class="d-flex">
                          <div class="d-flex align-items-center dates font-family-lato" style="margin-left: 4px ;border-radius: 7px; height: 30px">
                            <span class="mb-0 color_fade">{{ticket?.start_date ? formatDates(ticket?.start_date) : ticket?.due_date ? 'NA' : '' }}</span>
                            <span *ngIf="ticket?.start_date || ticket?.due_date" class="dash color_fade">-</span>
                            <span class="mb-0 color_fade">{{ ticket?.due_date ? formatDates(ticket?.due_date) : ticket?.start_date ? 'NA' : '' }}</span>
                          </div>
                        </div>
                        <button class="date_btn" type="button" id="date_btn" data-toggle="dropdown" aria-haspopup="true"
                          aria-expanded="false" [style.background]="dateColor" (click)="dateDrop(true)">
                          {{dateText}}
                        </button>
                        <div class="date_drop date_drop_menu" [style.display]="isShowDateDrop? 'block': 'none'">
                          <div class="date_drop_content">
                            <span style="cursor: pointer" class="date_close end_close" (click)="dateDrop(false)">×</span>
                            <h3>Date</h3>
                          </div>
                          <form id="date-form-wp">
                            <div class="d-flex justify-content-around">
                              <div class="start_date pl-0">
                                <h4>Start Date</h4>
                                <input #start_date_view type="date" name="start_date" />
                                <span style="cursor: pointer" class="start_close" (click)="setCurrentDate('startClose')" >×</span>
                                <button (click)="setCurrentDate('start')" class="start_btn" type="button" id="start_btn">
                                  <img class="calendar-date" src="/assets/calendar-event.svg" alt="calendar-event" />Today
                                </button>
                              </div>
                              <div class="end_date">
                                <h4>End Date</h4>
                                <input #due_date_view type="date" name="due_date" />
                                <span style="cursor: pointer" class="end_close" (click)="setCurrentDate('endClose')">×</span>
                                <button (click)="setCurrentDate('end')" class="end_btn" type="button" id="end_btn">
                                  <img class="calendar-date" src="/assets/calendar-event.svg" alt="calendar-event" />Today
                                </button>
                              </div>
                            </div>
                            <p [style.display]="dateError ? 'block': 'none'" style="color: red; font-size: 12px; display: none" class="date-warning">
                              End Date should be greater than Start Date
                            </p>
                            <input type="submit" id="save_date_wp" class="save_log mt-1" value="Submit"
                              (click)="dateUpdate()" />
                          </form>
                          <div class="d-flex justify-content-around"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-4">
                    <div class="priority_section">
                      <h2>Priority Time</h2>
                      <div class="priority_detail">
                        <div class="d-flex">
                          <span id="work-package-priority-color" [style.background-color]="ticket?.priority?.color?.hexcode"></span>
                          <h3 id="work-package-priority-label">{{ ticket?.priority?.name }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-5">
                    <div class="estimated_section">
                      <h2>Estimated Time (hrs)</h2>
                      <div class="estimated_detail" #estimatedDetail>
                        <input type="number" id="estimated_check" name="estimated_check"
                          value={{ticket?.estimated_hours}} readonly="" />
                        <button class="est_btn" type="button" id="est_btn" (click)="estimateDrop(true)">
                          <img src="/assets/tiitle_edit.svg" alt="" />
                        </button>
                        <div class="estimat_drop estimat_btn_drop" [style.display]="isShowEstimete? 'block': 'none'">
                          <div class="estimat_drop_content">
                            <span class="estimat_close" (click)="estimateDrop(false)">×</span>
                            <h3>Estimated Hours</h3>
                          </div>
                          <input (input)="validateEstimateInput(getEstimate.value)" #getEstimate type="number" [value]="ticket?.estimated_hours ? ticket?.estimated_hours : 0" id="estimated_time"
                            name="hours" maxlength="5" max="99" step="0.01" required="" />
                            <span *ngIf="!getEstimate.value" class="errorMessageSearch">Enter valid value</span>
                          <div class="log_btn">
                            <input type="submit" id="save_est" class="save_est mt-1" value="Submit"
                              (click)="estimateDropApi()" />
                            <p id="cancel_est" class="cancel_est" (click)="estimateDrop(false)">Cancel</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-7 pt-sm-0">
                    <div class="spent_section">
                      <h2>Spent Time (hrs)</h2>
                      <div class="spent_detail">
                        <input type="number" id="spent_check" name="spent_check" value={{ticket.spent_hours}}
                          readonly="" />
                        <button class="spen_btn" type="button" id="spen_btn" (click)="spenDrop(true)">
                          <img src="/assets/tiitle_edit.svg" alt="" />
                        </button>
                        <div class="spen_drop spen_btn_drop" [style.display]="isShowSpentDrop? 'block': 'none'">
                          <div class="spen_drop_content">
                            <span class="spen_close" (click)="spenDrop(false)">×</span>
                          </div>
                          <form #myForm="ngForm" (ngSubmit)="submitForm(myForm)" class="logs_time mt-3" id="center-work-package-time-entry"
                            style="display: block">
                            <div class="log_time_content d-flex justify-content-start flex-column">
                              <input [(ngModel)]="formData.spent_on" type="date" class="te-data" name="spent_on" [max]="maxDate"
                               required />
                               <div *ngIf="(form?.controls['spent_on']?.touched || submitted) && isFieldInvalid('spent_on') ">
                                <span class="error-message">This field is required.</span>
                              </div>
                            </div>
                            <div class="log_time_content d-flex justify-content-start flex-column">
                              <input [(ngModel)]="formData.hours"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxlength="2" max="24" type="number" min="0.1" value="1" placeholder="Hours"
                                class="spentLength te-hours" name="hours" step="0.01" required />
                              <div *ngIf="isFieldInvalid('hours') && (form?.controls['hours']?.touched || submitted)">
                                <span class="error-message">This field is required.</span>
                              </div>
                            </div>
                            <div style="padding: 2px 0" class="log_time_content d-flex justify-content-start">
                              <select #dateSelectActivity [(ngModel)]="formData.activity_id" name="activity_id" class="te-activity dateSelectStack"
                                required="">
                                <option *ngFor="let activity of ticket.time_entry_activities; let activityIndex = index" [value]="activity.id"
                                  [selected]="isActivitySelected(activity.id) || activityIndex == 0">
                                  {{ activity.name }}
                                </option>
                              </select>
                            </div>
                            <div class="log_time_content">
                              <input [(ngModel)]="formData.comments" type="text" class="te-comment"
                                placeholder="Comment" name="comments" autocomplete="off" maxlength="100" />
                            </div>
                            <div class="log_btn">
                              <input type="submit" id="save_log" class="save_log mt-1" value="Submit"
                                 />
                              <p id="cancel_log" class="cancel_log" style="cursor: pointer" (click)="spenDrop(false)">
                                Cancel</p>
                            </div>
                          </form>
                        </div>
                        <button class="spenModal_btn ml-2" type="button" id="spenModal_btn"
                          (click)="showSpanModel(true)">
                          <span class="material-icons"><img src="/assets/detailsFile.svg" ></span>
                        </button>
                        <div id="spenModal" class="spenModal" [style.display]="isShowSpanModel? 'block': 'none'">
                          <div class="spenModal-content">
                            <div class="spen_table_header_bg">
                              <span style="cursor: pointer" class="spenModalCose mr-3 mt-2" (click)="showSpanModel(false)">×</span>
                            </div>
                            <div class="table px-4 spen-modal-wrapper">
                              <table id="spen_table" class="mt-4">
                                <thead>
                                  <tr id="table_head">
                                    <th>Date</th>
                                    <th>User</th>
                                    <th style="min-width: 260px" class="spen-workpackage">Work Package</th>
                                    <th>Activity</th>
                                    <th class="spen-comment">Comment</th>
                                    <th>Hours</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody id="table_body" classname="edit-spen-table">
                                  <tr *ngFor="let entry of ticket.time_entries">
                                    <td>{{ entry?.spent_on }}</td>
                                    <td>{{ entry?.user?.firstname }}</td>
                                    <td>{{ticket.work_package_name}} #{{entry?.id}}: {{ticket.subject}}</td>
                                    <!-- <td class="time-entry-comment">---</td> -->
                                    <td>{{ findActivityName(entry?.activity_id) }}</td>
                                    <td style="min-width: 160px" class="time-entry-comment">{{entry?.comments ?
                                      entry?.comments : '---'}}</td>
                                    <td>{{entry?.hours}}</td>
                                    <td style="margin-top: -2px" class="spen-edit-relative w-100">
                                      <div class="updateSpentTime d-flex justify-content-end mt-2" style="position: relative">
                                        <button (click)="showSpentEditDropModal(true, entry)" class="dropdown-toggle"
                                          type="button" id="edit_spent_btn-{{ entry.id }}"
                                          data-edit_spen_dropdown="edit_spen_dropdown-{{ entry.id }}"
                                          data-spen_dropdown_close="edit_spen_dropdown_close-{{ entry.id }}">
                                          <img src="/assets/write.svg" class="edit write pe-3" alt="">
                                        </button>
                                        <div class="spent-dropdown"
                                          [style.display]="isShowSpentEditDrop && selectedEntry?.id === entry.id ? 'block': 'none'">
                                          <div class="edit_spen_dropdown">
                                            <span (click)="showSpentEditDropModal(false)"
                                              class="edit_spen_dropdown_close" id="edit_spen_dropdown_close-23">×</span>
                                            <h2>Spent Hours</h2>
                                            <form class="logs_time" style="display: block;"
                                              (submit)="onSubmitEditLogs()"
                                              id="edit-work-package-time-entry-{{ selectedEntry?.id }}">
                                              <input type="hidden" value="23" name="time_entry_id"
                                                class="time_entry_id">
                                              <h3 class="logTime"> <img style="margin-right: 5px" src="/assets/clock-filled.svg" width="15" height="15" alt="clock-filled" > Log Time</h3>
                                              <div class="log_time_content">
                                                <!--<h5>Date *</h5>-->
                                                <input [(ngModel)]="formDataUpdate.spent_on" [value]="selectedEntry?.spent_on" type="date" name="spent_on"
                                                  required="">
                                              </div>
                                              <div class="log_time_content">
                                                <!--<h5>Hours *</h5>-->
                                                <input value="{{ selectedEntry?.hours }}"
                                                  [(ngModel)]="formDataUpdate.hours"
                                                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                                  maxlength="2" max="24" type="number" value="12" placeholder="Hours"
                                                  name="hours" step="0.01" required="">
                                              </div>
                                              <div class="log_time_content">
                                                <!--<h5>Acitvity *</h5>-->
                                                <select class="te-activity dateSelectStack" [(ngModel)]="formDataUpdate.activity_id" [value]="selectedEntry?.activity_id" name="activity_id"
                                                  required="">
                                                  <option *ngFor="let activity of ticket.time_entry_activities"
                                                    [value]="activity.id"
                                                    [selected]="formData.activity_id == activity.id">
                                                    {{ activity.name }}
                                                  </option>
                                                </select>
                                              </div>
                                              <div class="log_time_content">
                                                <!--<h5>Comment *</h5>-->
                                                <input [(ngModel)]="formDataUpdate.comments" [value]="selectedEntry?.comments || ''" type="text"
                                                  name="comments" value="" placeholder="Comment" autocomplete="off"
                                                  maxlength="100">
                                              </div>
                                              <div class="log_btn">
                                                <button type="submit" class="save_log mt-1">Submit</button>
                                                <span 
                                                  id="edit_spen_dropdown_close-23"
                                                  type="cancel"
                                                  (click)="showSpentEditDropModal(false)"
                                                  class="cancel_btn_edit mt-1 ml-2 cancel_est">Cancel</span>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                        <!--<span class="material-icons delete" id="time-log-delete-23" data-value="23">delete</span>-->
                                        <img (click)="removeTimeLogs(entry.id)" src="/assets/delette.svg"
                                          class="delete delette" alt="delIcon">
                                      </div>
                                    </td>

                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-12">
                    <div class="remaining_section">
                      <h2>Remaining Time (hrs)</h2>
                      <div class="remaining_detail">
                        <span id="remaining-check-value" class="modal-info-value card-modal-value">{{
                          remainingTime(ticket?.estimated_hours, ticket?.spent_hours) }}</span>
                        <button class="rem_btn" type="button" id="rem_btn">
                          <span class="material-icons edit">edit</span>
                        </button>
                        <div class="rem_drop">
                          <div class="rem_drop_content">
                            <span class="rem_close">×</span>
                            <h3>Remaining Hours</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-5" id="col-category-label" style="display: none">
                    <div class="mb-3">
                      <div>
                        <h2 class="modal-info-label">Category</h2>
                      </div>
                      <div id="col-category-value">
                        <span id="view-category" class="modal-info-value card-modal-value">-</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-6" id="col-version-label">
                    <div class="mb-3">
                      <div>
                        <h2 class="modal-info-label">Version</h2>
                      </div>
                      <div id="col-version-value">
                        <span id="view-version"
                          class="modal-info-value card-modal-value">{{ ticket?.version?.name ? ticket?.version?.name : 'Backlog' }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-6" id="col-budget-label" style="display: none">
                    <div class="mb-3">
                      <div>
                        <h2 class="modal-info-label">Budget</h2>
                      </div>
                      <div id="col-budget-value">
                        <span id="view-budget" class="modal-info-value card-modal-value">-</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div id="work-package-information">
                      <div class="modal-info row custom-values-row"></div>
                    </div>
                    <div class="col-lg-11 custom_margin pl-0">
                      <div class="ticket_activity">
                        <h2>Progress</h2>
                      </div>
                      <div class="progress" style="width: 100%; margin-top: 0px">
                        <div style="background: #00af3c;" id="center-progress-bar" class="progress_b color-carrot" style="height: 9px"
                          [style.width]="ticket?.done_ratio+'%'">
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-11 mt-4 pl-0">
                      <div class="ticket_description">
                        <h2 class="ml-1">Description</h2>
                        <button id="edit_description" class="edit_description" (click)="editDescription()">
                          <img src="/assets/tiitle_edit.svg" alt="" />
                        </button>
                      </div>
                      <div class="description_detail">
                        <div class="description_detail_editor">
                          <div [style.display]="isDescriptionEditable? 'block': 'none'" *ngIf="ticket?.id">
                            <op-ckeditor  [context]="ckEditorContext"
                            [content]="ticket?.description"
                            (onContentChange)="onContentChange($event)">
                            </op-ckeditor>
                          </div>
                          <label class="ck ck-label ck-voice-label" *ngIf="!isDescriptionEditable">
                            {{removeHtmlTags(ticket?.description)}}
                          </label>
                        </div>
                        <div class="d-flex justify-content-end mt-2 mb-4" *ngIf="isDescriptionEditable">
                          <button id="save_description" class="save_description mt-1" (click)="updateDescription()">Save</button>
                          <button id="cancel_description" class="cancel_description mt-1" (click)="discardDescription()">Cancel</button>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-xl-11 my-4 pl-0" *ngIf="ticket?.checklist && ticket.checklist?.length > 0">
                      <div class="checkListSection">
                        <h2 class="checkListSectionTitle">Checklist</h2>
                      </div>
                      <div class="checklistWrapperDiv">
                        <div *ngFor="let check of ticket.checklist; let i = index" class="checklistH checklistH-3" id="{{i}}">
                          <div class="ticket_checklist_header d-flex align-items-center justify-content-between flex-wrap">
                            <div class="checklist_header-title d-flex align-items-center justify-content-between">
                              <textarea #checkListTitle [readonly]="isEditMode[i] ? null : 'readonly'" [ngClass]="{'readonly-checklist-title': !isEditMode[i]}" class="ml-1 ticket_title_checklist readonly-checklist-title editTitleCHecklistBtn editTitleCHecklistBtn-3" [id]="'checklist-title-'+check.id">{{check.title}}</textarea>
                              <span>{{getChecklistItemsCount(check)}}</span>
                              <div *ngIf="!isEditMode[i]" class="checklist-title-edit-btn checklist-title-edit-btn-3 editTitleCHecklistBtn" id="ticket_title_checklist-3" data-id="3">
                                <button (click)="toggleEditMode(i)" class="edit-checklist-title edit-checklist-title-3">
                                  <img class="title_edit_checklist-icon" src="/assets/tiitle_edit.svg" alt="">
                                </button>
                              </div>
                              <div *ngIf="isEditMode[i]" class="checklist-title-edit-controls checklist-title-edit-controls-3">
                                <button class="save-checklist-title save-checklist-title-3"
                                  (click)="updateChecklist(check?.id, checkListTitle.value, i)">
                                  <img class="title_save_checklist-icon" src="/assets/save_title.svg" alt="">
                                </button>
                                <button (click)="cancelEdit(i)" class="cancel-checklist-title cancel-checklist-title-3">
                                  <img class="title_cancel_checklist-icon" src="/assets/cancel_title.svg" alt="">
                                </button>
                              </div>
                              <img class="ml-2" src="/assets/title_right_border.svg" alt="">
                            </div>
                            <div class="delete-collapse-checklist-wrapper ml-auto">
                              <button class="delete-whole-checklist-btn" (click)="deleteChecklist($event, check.id, i)" closedchecklist="0" openchecklistitems="2">Delete</button>
                              <button #checklistDropShowBtn class="collapse-checklist-btn collapse-checklist-btn-{{i}}" (click)="toggleChecklist(i)" [ngClass]="{'opened': isChecklistOpen[i]}" >
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.6667 0.99999L6.00008 5.66666L1.33341 0.99999" stroke="#809FB8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div #checklistDropShow class="checklistCollapse" [style.display]="isChecklistOpen[i] ? 'block' : 'none'">
                            <div class="checkboxprogresswrapper mb-3" id="checkboxprogresswrapper-3">
                              <div class="checkboxprogressValue " id="center-progress-value-3">{{ checklistProgress(i)+'%' }}</div>
                              <div class="progress">
                                <div id="center-progress-bar-3" class="progress_b color-carrot" style="height: 9px;" [style.width]="checklistProgress(i)+'%'">
                                </div>
                              </div>
                            </div>
                            <div class="card_checklists_wrapper mb-3" id="card-checklists-wrapper-3">
                              <div *ngFor="let item of check.checklist_items; let k = index" id="{{k}}" class="card_ticket_checklist card_ticket_checklist-5">
                                <div class="ticket_checklist_title_wrapper">
                                  <label class="checklist-checkbox-wrapper">
                                    <input class="itemsCheckBox itemsCheckBox-5" [checked]="item.status" #checklistItemStatus (change)="updateChecklistItemStatus(item.id, check.id, checklistItemStatus.checked)" type="checkbox" value="check">
                                      <span class="checklist-aside-checkmark  checkmarkCLosed-5"></span>
                                  </label>
                                  <span>{{k+1}}.</span>
                                  <textarea style="cursor: auto" type="text" #checklistItemTitle
                                    [class]="'checklist_title checklist-item-title-'+item.id"
                                    [style.text-decoration]="(item.status && !editableChecklistItems.includes(item.id)) ? 'line-through' : 'none'" 
                                    [style.border]="editableChecklistItems.includes(item.id) ? '1px solid #e2e2e2 !important' : 'none'"
                                    [readonly]="!editableChecklistItems.includes(item.id)">{{item.title}}</textarea>
                                </div>
                                <div class=" checklist_delete delete_checklist_items delete_checklist_items-5" [style.display]="editableChecklistItems.includes(item.id) ? 'block' : 'none'">
                                  <button style="background: none; margin-right: 2px"  class="delete_checklist" (click)="updateChecklistItemTitle(item.id, check.id, checklistItemTitle.value)">
                                    <span class="material-icons"><img width="25px" height="25px" src="/assets/save_title.svg" alt="" /></span>
                                  </button>
                                </div>
                                <div class=" checklist_delete delete_checklist_items delete_checklist_items-5" [style.display]="editableChecklistItems.includes(item.id) ? 'block' : 'none'">
                                  <button style="background: none; margin-right: 2px"  class="delete_checklist" (click)="discardChecklistItem(item.id, check.id)">
                                    <span class="material-icons"><img width="25px" height="25px" src="/assets/cancel_title.svg" alt="" /></span>
                                  </button>
                                </div>
                                <div class=" checklist_delete delete_checklist_items delete_checklist_items-5" [style.display]="editableChecklistItems.includes(item.id) ? 'none' : 'block'">
                                  <button style="background: none; margin-right: 2px" class="delete_checklist" (click)="editChecklistItemTitle(item.id)">
                                    <span class="material-icons">
                                      <img width="25px" height="25px" src="/assets/tiitle_edit.svg" alt="" />
                                    </span>
                                  </button>
                                </div>
                                <div class=" checklist_delete delete_checklist_items delete_checklist_items-5" id="5">
                                  <button class="delete_checklist" (click)="deleteChecklistItem($event, item.id, check.id)">
                                    <span class="material-icons"><img width="16px" height="16px" src="/assets/deleteCheck.svg" alt="" /></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="checklist-new-item" attr="newItem">
                              <button (click)="toggleNewItem(i)" class="js-new-checklist-item-button" id="js-new-checklist-item-button-{{i}}" [style.display]="isNewItemVisible[i] ? 'none' : 'block'">
                                Add an item
                              </button>
                              <textarea id="js-new-checklist-item-input-{{i}}" #newChecklistItem class="checklist-new-item-text js-new-checklist-item-input" [style.display]="isNewItemVisible[i] ? 'block' : 'none'" placeholder="Add an item" maxlength="200"></textarea>
                              <div class="checklist-add-controls" [style.display]="isNewItemVisible[i] ? 'block' : 'none'">
                                <button class="js-add-checklist-item" id="js-add-checklist-item-{{i}}" (click)="createChecklistItem(check.id, newChecklistItem.value)">Add</button>
                                <button (click)="cancelNewItem(i)" class="js-cancel-checklist-item" id="js-cancel-checklist-item-{{i}}">Cancel</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-lg-11 pl-0">
                      <div class="tabs">
                        <div class="cards_tabs">
                          <button type="radio" class="tablink" id="activity_tabs"
                            [class]="isShowActivityModal? 'activity_line active': null"
                            (click)="activeTabs('activity')">Activity</button>
                          <button type="radio" class="tablink" id="files_tabs"
                            [class]="isShowFileModal? 'activity_line active': null"
                            (click)="activeTabs('files')">Files</button>
                          <button type="radio" class="tablink" id="watcher_tabs"
                            [class]="isShowWatcherModal? 'activity_line active': null"
                            (click)="activeTabs('watcher')">Watcher</button>
                          <button type="radio" class="tablink" id="relation_tabs"
                            [class]="isShowRelationModal? 'activity_line active': null"
                            (click)="activeTabs('relation')">Relation</button>
                          <button type="radio" class="tablink" id="commentBox_tabs"
                            [class]="isShowCommentModal? 'activity_line active': null"
                            (click)="activeTabs('comments')">Comments</button>
                        </div>
                        <div id="activity" class="tabcont" [style.display]="isShowActivityModal? 'block': 'none'">
                          <ticket-activities [activities]="activities"></ticket-activities>
                        </div>
                        <div id="files" class="tabcont" [style.display]="isShowFileModal? 'block': 'none'">
                          <div class="attachment_detail">
                            <div id="attachment-list">
                              <ng-container *ngFor="let attachment of ticket.attachments">
                                <div class="attach_section">
                                  <div class="attached_img" [innerHTML]="attachmentContent(attachment)">
                                  </div>
                                  <div class="attached_content">
                                    <h3 class="attached_title">
                                      <a target="_blank" [href]="'api/v3/attachments/'+attachment.id+'/content'">
                                        {{ attachment?.filename || attachment?.fileName }}
                                      </a>
                                    </h3>
                                    <p class="attached_details">
                                      Added {{ attachmentAddedDate(attachment.created_at || attachment.createdAt) }} -
                                      <button id="delete_attachment_10" data-attachment-id="10"
                                        class="delete_attachment" (click)="deleteAttachment(attachment.id)">Delete</button>
                                    </p>
                                  </div>
                                </div>
                              </ng-container>
                            </div>

                            <div class="fileUpload add_attachment">
                              <span>Add an Attachment</span>
                              <input id="add_attachment" type="file" class="upload"
                                (change)="uploadAttachment($event)" />
                            </div>
                          </div>
                        </div>
                        <div id="watcher" class="tabcont" [style.display]="isShowWatcherModal? 'block': 'none'">
                          <div class="watcher" #watcher>
                            <input id="search-center-watcher-list" type="text" placeholder="Search Watcher.."
                              (click)="watcherList(true)" />
                            <div class="watcher_list" id="center-select-watcher-list"
                              [style.display]="isShowWatcherList? 'block': 'none'">
                              <div (click)="addWatcher(member.user.id)" *ngFor="let member of members" class="member_list_item" [style.display]="!isUserWatcher(member.user.id) ? 'flex': 'none'">
                                <!-- <div [style.display]="!isUserWatcher(member.user.id) ? 'block': 'none'"> -->
                                  <div *ngIf="!member.get_user_avatar" class="member_select_avatar" [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                                    {{ member.user.firstname.charAt(0) + member.user.lastname.charAt(0) }}
                                  </div>
                                  <img *ngIf="member.get_user_avatar" [src]="'/api/v3/users/' + member.user.id + '/avatar'" class="dropdown-journal-user-img">
                                  <div class="member_list_item_name">{{ member.user.firstname }} {{ member.user.lastname }}</div>
                                <!-- </div> -->
                              </div>
                            </div>
                            <div id="selected-watcher-list" class="existing-watchers">
                              <div *ngFor="let watcher of ticket.watchers" class="existing-watcher-item">
                                <div *ngIf="!watcher.get_user_avatar" class="member_select_avatar" [style.background-color]="getAvatarBackgroundColor(watcher?.user?.firstname, watcher?.user?.lastname)">
                                  {{ watcher.user.firstname.charAt(0) + watcher.user.lastname.charAt(0) }}
                                </div>
                                <img *ngIf="watcher.get_user_avatar" [src]="'/api/v3/users/' + watcher.user.id + '/avatar'" class="dropdown-journal-user-img">
                                {{ watcher.user.firstname }} {{ watcher.user.lastname }}
                                <span (click)="removeWatcher(watcher.user.id)" class="watcher-remove">×</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="relation" class="tabcont" [style.display]="isShowRelationModal? 'block': 'none'">
                          <div class="relations">
                            <div class="d-none">
                              <h2>RELATIONS</h2>
                              <hr>
                              <div class="relation_button_box">
                                <button class="relations_btn"><span class="material-icons add">add</span>Create New
                                  Relations</button>
                              </div>
                              <div class="relations_content">
                                <div class="d-flex justify-content-between align-items-center w-100">
                                  <div class="custom-select-wrapper" style="width: 30%">
                                    <div class="input-wrapper">
                                      <input type="text" placeholder="Select State" class="custom-select"
                                        id="relation-group-select" readonly="readonly">
                                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                                      <ul id="relation-group-list">
                                        <li data-value="relates">
                                          <span data-value="relates">Related To</span>
                                        </li>
                                        <li data-value="duplicates">
                                          <span data-value="duplicates">Duplicates</span>
                                        </li>
                                        <li data-value="duplicated">
                                          <span>Duplicated by</span>
                                        </li>
                                        <li data-value="blocks">
                                          <span>Blocks</span>
                                        </li>
                                        <li data-value="blocked">
                                          <span>Blocked by</span>
                                        </li>
                                        <li data-value="precedes">
                                          <span>Precedes</span>
                                        </li>
                                        <li data-value="follows">
                                          <span>Follows</span>
                                        </li>
                                        <li data-value="includes">
                                          <span>Includes</span>
                                        </li>
                                        <li data-value="partof">
                                          <span>Part of</span>
                                        </li>
                                        <li data-value="requires">
                                          <span>Requires</span>
                                        </li>
                                        <li data-value="required">
                                          <span>Required by</span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="d-flex align-items-center">
                                    <div class="custom-select-wrapper">
                                      <div class="input-wrapper">
                                        <input type="text" placeholder="Select State" class="custom-select"
                                          id="search-relations">
                                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                                        <ul id="searched-relation-list"></ul>
                                      </div>
                                    </div>
                                    <span class="relation_close ml-2">×</span>
                                  </div>
                                </div>
                              </div>
                              <table id="relations_table"></table>
                            </div>
                            <h2>Children</h2>
                            <hr>
                            <table class="childern_table">
                              <thead>
                                <tr>
                                  <th>Id</th>
                                  <th>Type</th>
                                  <th>Subject</th>
                                  <th>Status</th>
                                  <td></td>
                                </tr>
                              </thead>
                              <tbody id="wp-children-table">
                                <tr *ngFor="let child of ticket.children">
                                  <td>#{{child.id}}</td>
                                  <td>{{child?.work_package_name || child?.type?.name}}</td>
                                  <td>{{child.subject}}</td>
                                  <td>{{child?.status_name || child?.status?.name}}</td>
                                  <td (click)="removeRealtion(child.id)"> <span style="cursor: pointer; font-size:20px" class="table_close" id="wp-child-remove-37"
                                      data-parent-row-id="wp-child-row-37" data-id="37">×</span></td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="childern_button_box">
                              <div class="d-flex">
                                <button [style.display]="!isShowRelationDropMenu ? 'block' : 'none'" (click)="addChildToggle()" class="childern_exsist_btn ml-2">
                                  <span class="d-none material-icons link">link</span>Add Child
                                </button>
                              </div>
                            </div>
                            
                            <div class="childern_exsisting_content" [style.display]="isShowRelationDropMenu ? 'block' : 'none'">
                              <div class="d-flex align-items-center">
                                <div class="custom-select-wrapper" style="width: 60%">
                                  <div class="input-wrapper">
                                    <input [(ngModel)]="searchValue" (input)="onSearchRelationInput($event)" type="text" id="search-existing-children" placeholder="Add Child" class="custom-select">
                                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    <ul id="existing-children-relation" style="height: auto">
                                      <li (click)="createRelation(wp.id)"  class="availableRelationsShow" *ngFor="let wp of availableRelations">
                                        <div class="d-flex align-items-center">
                                          <span class="relations_title">{{ wp?._links?.type?.title }}</span>
                                          <span class="relation_project">{{ wp?.subject }}</span>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                          <span class="relation_text">{{ wp._links?.project?.title }}</span>
                                          <span class="relation_text">#{{ wp?.id }}</span>
                                          <div class="relation_text">
                                            <span class="relation_round"></span>{{ wp?._links?.status?.title }}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>                                    
                                  </div>
                                </div>
                                <span style="cursor: pointer" class="exsisting_close ml-2" (click)="addChildToggle()">×</span>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div id="commentBox" class="tabcont" [style.display]="isShowCommentModal? 'block': 'none'">
                            <button class="btn btn-light mb-2 add-comment-btn-padding" id="create-open-comment" *ngIf="!isCommentEditable" (click)="editNewComment()">Add Comment
                            </button>
                            <div class="description_detail">
                              <div class="description_detail_editor">
                                <div [style.display]="isCommentEditable? 'block': 'none'" *ngIf="ticket?.id">
                                  <op-ckeditor  [context]="ckEditorContext"
                                  [content]="updatedTicketComment"
                                  (onContentChange)="onNewCommentContentChange($event)">
                                  </op-ckeditor>
                                </div>
                              </div>
                              <div class="d-flex justify-content-end mt-2 mb-4" *ngIf="isCommentEditable">
                                <button id="save_description" class="save_description mt-1" (click)="addNewComment()">Save</button>
                                <button id="cancel_description" class="cancel_description mt-1" (click)="discardNewComment()">Cancel</button>
                              </div>
                            </div>
                            <div class="comments_content">
                              <div class="comments_box" id="new-comment-section-box">
                                <ticket-comments [comments]="comments" (updatedComment)="updateJournal($event)"></ticket-comments>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-4 modal_second">
                <div class="tickets_buttons mt-4 mt-md-0">
                  <div class="people">
                    <h2>Members</h2>
                    <div #people>
                      <button (click)="memberDrops(true)" type="button" id="member_button" class="member_button right_side_box">
                        <img src="/assets/member.svg" alt="" /><span class="ml-2">Members</span>
                      </button>
                      <div class="member_drop member_right_drop_list"
                        [style.display]="isShowMemberDrops? 'block': 'none'">
                        <div class="member_drop_content">
                          <span style="padding: 10px" class="member_close" (click)="memberDrops(false)">×</span>
                          <h3>Members</h3>
                        </div>
                        <input id="search-member-list" type="text" placeholder="Search Members.."
                          #searchMembersSidebarInput (input)="searchMembers(searchMembersSidebarInput.value)" />
                        <div class="member_list center-select-member-list" id="center-select-member-list">
                          <span class="errorMessageSearch" [ngClass]="{'d-none': ticket && searchedMembers.length > 0}">No Members Found</span>
                          <ng-container *ngFor="let member of searchedMembers">
                            <div class="tick member_list_item d-flex align-items-center search-member-select-list-right"
                              (click)="toggleAssignee(member?.user)">
                              <div class="member-select-wrapp rightMemberSelected">
                                <div [ngStyle]="{'padding-top.px': member?.get_user_avatar == null ? 6 : 0}"
                                    class="no-avatar-right member-avatar multi-member-sprint principle-avatar dropdown-journal-user-img"
                                    [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                                    <ng-container *ngIf="member?.get_user_avatar == null">
                                        <span class="no-avatar">
                                            {{ getUserInitials(member?.user) }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="member?.get_user_avatar">
                                        <img [src]="getMemberAvatarURL(member?.user?.id)"
                                            alt="{{ member?.user?.firstname }} {{ member?.user?.lastname }}">
                                    </ng-container>
                                </div>
                                <div class="memberSelectedWrapper">
                                    <div class="member_list_item_name">{{ getUserFullName(member?.user) }}</div>
                                    <div class="tickMember" *ngIf="isMember(member?.user)"><img src="/assets/check.svg" class="tick"></div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div #accountDrop>
                      <button (click)="accountableDrop(true)" type="button" id="accountable_button" class="accountable_button right_side_box">
                        <img src="/assets/Acc.svg" alt="" /><span class="ml-2"
                        >Accountable</span>
                      </button>
                      <div class="account_drop" [style.display]="isShowAccountableDrop? 'block' : 'none'">
                        <div class="account_drop_content">
                          <span class="account_close" (click)="accountableDrop(false)">×</span>
                          <h3>Accountable</h3>
                        </div>
                        <input id="search-accountable-list" type="text" placeholder="Search Accountable.." 
                        #searchAccountableInput (input)="searchMembers(searchAccountableInput.value)" />
                        <div class="member_list" id="center-select-member-list">
                          <span class="errorMessageSearch" [ngClass]="{'d-none': ticket && searchedMembers.length > 0}">No Members Found</span>
                          <ng-container *ngFor="let member of searchedMembers">
                            <div style="width: auto" class="tick member_list_item d-flex align-items-center search-member-select-list-right"
                              (click)="updateAccountable(member?.user?.id)">
                              <div class="member-select-wrapp acountable-member-select-wrap">
                                <div  [ngStyle]="{'padding-top.px': member?.get_user_avatar == null ? 6 : 0}" class="member-avatar multi-member-sprint principle-avatar dropdown-journal-user-img"
                                [style.background-color]="getAvatarBackgroundColor(member?.user?.firstname, member?.user?.lastname)">
                                  <ng-container *ngIf="member?.get_user_avatar == null">
                                    {{ getUserInitials(member?.user) }}
                                  </ng-container>
                                  <ng-container *ngIf="member?.get_user_avatar">
                                    <img 
                                    [src]="getMemberAvatarURL(member?.user?.id)"
                                    alt="{{ member?.user?.firstname }} {{ member?.user?.lastname }}">
                                  </ng-container>
                                </div>
                                <div class="memberSelectedWrapper">
                                  <div class="member_list_item_name">{{ getUserFullName(member?.user) }}</div>
                                  <div class="tickMember" *ngIf="isAccountable(member?.user?.id)"><img src="/assets/check.svg" class="tick"></div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="details">
                    <h2>Details</h2>
                    <div #dropDate>
                      <button type="button" id="dates_button" class="dates_button right_side_box"
                        (click)="datesDrop(true)">
                        <img src="/assets/date.svg" alt="" /><span class="ml-2">Date</span>
                      </button>
                      <div class="dates_drop" [style.display]="isShowDatesDrop? 'block': 'none'">
                        <div class="dates_drop_content">
                          <span style="padding: 10px" class="dates_close" (click)="datesDrop(false)">×</span>
                          <h3>Date</h3>
                        </div>
                        <form id="right-date-form-wp">
                          <div class="d-flex justify-content-around">
                            <div class="start_date pl-0">
                              <h4>Start Date</h4>
                              <input #start_date_view_right type="date" name="start_date" />
                              <span (click)="setCurrentDateRight('startClose')" class="start_close startCloseDate">×</span>
                              <button (click)="setCurrentDateRight('start')" class="start_btn startDateBtns" type="button" id="start_btn">
                                <img class="calendar-date" src="/assets/calendar-event.svg" alt="calendar-event" />Today
                              </button>
                            </div>
                            <div class="end_date">
                              <h4>End Date</h4>
                              <input #due_date_view_right type="date" name="due_date" />
                              <span (click)="setCurrentDateRight('endClose')" class="endCloseDate end_close">×</span>
                              <button (click)="setCurrentDateRight('end')" class="end_btn endDateBtns" type="button" id="end_btn">
                                <img class="calendar-date" src="/assets/calendar-event.svg" alt="calendar-event" />Today
                              </button>
                            </div>
                          </div>
                          <p [style.display]="dateError ? 'block': 'none'" style="color: red; font-size: 12px; display: none" class="date-warning">
                            End Date should be greater than Start Date
                          </p>
                          <input (click)="dateUpdate('right')" type="submit" id="save_date_wp" class="save_log mt-1" />
                        </form>
                        <div class="d-flex justify-content-around"></div>
                      </div>
                    </div>
                    <div class="right-checklist-btn-wrapper" #rightChecklist>
                      <button type="button" id="checklist_button" class="checklist_button right_side_box"
                        (click)="checkListDropdown(true)">
                        <img src="/assets/check_list.svg" alt="" /><span class="ml-2">Check list</span>
                      </button>
                      <div class="right-checklist-dropdown d-none"
                        [style.display]="isShowChecklistDrop? 'block': 'none'">
                        <div class="right-checklist-dropdown-header">
                          <h3 class="checklist-header-title">Add Checklist</h3>
                          <div class="checklist-header-close-btn" id="checklist_close_btn"
                            (click)="checkListDropdown(false)">×</div>
                        </div>
                        <div class="right-checklist-dropdown-content">
                          <input class="addcheckListBtnValue" #newChecklistTitle type="text" placeholder="Enter Title" />
                          <button class="addcheckListBtn" (click)="createChecklist(newChecklistTitle.value)">Add</button>
                        </div>
                      </div>
                    </div>
                    <button style="display: none" type="button" id="attachment_button"
                      class="attachement_button right_side_box">
                      <img src="/assets/attach.svg" alt="" /><span class="ml-2">Attachment</span>
                    </button>
                    <div #progress>
                      <button type="button" id="progress_button" class="progress_button right_side_box"
                        (click)="progressDrop(true)">
                        <img src="/assets/progress.svg" alt="" /><span class="ml-2">Progress</span>
                      </button>
                      <div class="progress_drop" [style.display]="isShowProgressDrop? 'block': 'none'">
                        <div class="progress_drop_content">
                          <span style="padding: 10px" class="progress_close" (click)="progressDrop(false)">×</span>
                          <h3>Progress</h3>
                        </div>
                        <div class="progress_port">
                          <div class="progress">
                            <div id="work-progress-bar" class="progress_b"
                              [style.width]="ticket?.done_ratio+'%'"
                              style="height: 8px; background: #00af3c"></div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                          <h4 id="work-progress-bar-label">{{ticket?.done_ratio}}%</h4>
                          <img (click)="showInputProgressDropdown()" class="progress_btn edit" src="/assets/tiitle_edit.svg" alt="" />
                        </div>
                        <div class="progress_input_sec" [style.display]="showInputProgDropdown? 'flex': 'none'">
                          <input #progressInput (input)="validateProgressInput(progressInput.value)" id="done-ratio-input" class="progress_input" type="number" value="0" max="100"
                            min="0" max="100" />
                          <button style="width: 19%" id="done-ratio-input-button" class="progress_save">
                            <span (click)="updateProgress()"><img style="cursor: pointer;" width="30px" height="30px" src="/assets/saveFile.svg" alt=""></span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div #priority>
                      <button type="button" id="priority_button" class="priority_button right_side_button"
                        (click)="priorityDrop(true)">
                        <img src="/assets/priority.svg" alt="" /><span class="ml-2">Priority</span>
                      </button>
                      <div class="priority_drop" [style.display]="isShowProrityDrop? 'block': 'none'">
                        <div class="priority_drop_content">
                          <span style="padding: 10px" class="priority_close" (click)="priorityDrop(false)">×</span>
                          <h3>Priority</h3>
                        </div>
                        <div *ngFor="let priority of priorityList" class="my-2" >
                          <label class="isbillable_container">{{priority?.name}}
                            <input type="radio"
                              data-color="#C5F6FA"
                              name="work_package_priority"
                              [value]="priority?.id"
                              [checked]="ticket.priority_id === priority?.id ? true : false" 
                              (click)="priorityUpdate(priority?.id)"
                            />
                            <span  class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div #version>
                      <button type="button" id="version_button" class="version_button right_side_box"
                        (click)="versionDrop(true)">
                        <img src="/assets/version.svg" alt="" /><span class="ml-2">Version</span>
                      </button>
                      <div class="version_drop" [style.display]="isShowVersionnDrop? 'block': 'none'">
                        <div class="version_drop_content">
                          <span style="padding: 10px" class="version_close" (click)="versionDrop(false)">×</span>
                          <h3>Version</h3>
                        </div>
                        <input #searchVersionsInput (input)="searchVersions(searchVersionsInput.value)" type="text" placeholder="search version" id="search-pv" />
                        <div id="work-package-version-list">
                          <span class="errorMessageSearch" [ngClass]="{'d-none': ticket && searchedVersions.length > 0}">No Sprint Found</span>
                          <ng-container *ngFor="let version of searchedVersions">
                            <button (click)="updateVersion(version?.id)">
                              <h3 class="m-0 p-0">{{ version?.name }}</h3>
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <button type="button" id="category_button" class="disabled category_button right_side_box">
                      <img style="width: 18px; cursor:pointer" src="/assets/categoryFile.svg" alt="" /><span class="ml-2">Category</span>
                    </button>
                    <div class="category_drop">
                      <div class="category_drop_content">
                        <span class="category_close">×</span>
                        <h3>Category</h3>
                      </div>
                      <input type="text" placeholder="search category" id="search-pc" />
                      <div id="work-package-category-list">
                        <button id="wp-category-null">
                          <h3 class="m-0 p-0">-</h3>
                        </button>
                      </div>
                    </div>
                    <button type="button" id="test_cf_button" class="disabled test_cf_button right_side_box">
                      <img src="/assets/cf_1.svg" alt="" /><span class="ml-2">Text CF</span>
                    </button>
                    <button type="button" id="list_cf_button" class="disabled list_cf_button right_side_box">
                      <img src="/assets/cf_2.svg" alt="" /><span class="ml-2">List CF</span>
                    </button>
                  </div>
                  <div class="Costs costs">
                    <h2>Costs</h2>
                    <button type="button" id="budget_button" class="disabled budget_button right_side_box">
                      <img src="/assets/budget.svg" alt="" /><span class="ml-2">Budget</span>
                    </button>
                    <div class="budget_drop">
                      <div class="budget_drop_content">
                        <span class="budget_close">×</span>
                        <h3>Budget</h3>
                      </div>
                      <input type="text" placeholder="search version" id="search-pb" />
                      <div id="work-package-budget-list">
                        <button id="wp-budget-null">
                          <h3 class="m-0 p-0">-</h3>
                        </button><button id="wp-budget-1" data-id="1">
                          <h3 class="m-0 p-0">Kanban Feature</h3>
                        </button>
                      </div>
                    </div>
                    <!-- <button type="button" id="billable_button" class="billable_button d-none">
                      <span class="material-icons sywatchlaterncalt">watchlater</span>Billable Hrs
                    </button> -->
                    <div class="billable_drop">
                      <div class="billable_drop_content">
                        <span class="billable_close">×</span>
                        <h3>Billable</h3>
                      </div>
                      <input type="number" value="0" />
                    </div>
                    <button type="button" id="overall_button" class="disabled overall_button right_side_box">
                      <img src="/assets/over_all.svg" alt="" /><span class="ml-2">Over all</span>
                    </button>
                    <div class="overall_drop">
                      <div class="overall_drop_content">
                        <span class="overall_close">×</span>
                        <h3>Overall</h3>
                      </div>
                      <input id="wp-overall-cost" class="not-allowed" type="number" value="0" disabled="" />
                    </div>
                    <!-- <button type="button" id="isbillable_button" class="isbillable_button d-none">
                      <span class="material-icons money">money</span>isBillable<sup>*</sup>
                    </button>
                    <div class="isbillable_drop">
                      <div class="isbillable_drop_content">
                        <span class="isbillable_close">×</span>
                        <h3>isbillable</h3>
                      </div>
                      <div class="d-flex justify-content-between mt-2 mx-3">
                        <label class="isbillable_container">Yes
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="isbillable_container">No
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div> -->
                    <button type="button" id="label_cost_button" class="disabled label_cost_button right_side_box">
                      <img src="/assets/labor_cost.svg" alt="" /><span class="ml-2">Labor Cost</span>
                    </button>
                    <div class="label_cost_drop">
                      <div class="label_cost_drop_content">
                        <span class="label_cost_close">×</span>
                        <h3>Label Cost</h3>
                      </div>
                      <input id="wp-labor-cost" class="not-allowed" type="number" value="0" disabled="" />
                    </div>
                    <button type="button" id="unit_cost_button" class="disabled spent_cost_button right_side_box">
                      <img src="/assets/unit_cost.svg" alt="" /><span class="ml-2">Unit Cost</span>
                    </button>
                    <div class="unit_cost_drop">
                      <div class="unit_cost_drop_content">
                        <span class="unit_cost_close">×</span>
                        <h3>Unit Cost</h3>
                      </div>
                      <input id="wp-unit-cost" class="not-allowed" type="number" value="0" disabled="" />
                    </div>
                    <button type="button" id="spent_unit_button" class="disabled spent_unit_button right_side_box">
                      <img src="/assets/spent_unit.svg" alt="" /><span class="ml-2">Spent Unit</span>
                    </button>
                    <div class="spent_unit_drop">
                      <div class="spent_unit_drop_content">
                        <span class="spent_unit_close">×</span>
                        <h3>Spent Unit</h3>
                      </div>
                      <input id="wp-spent-units" class="not-allowed" type="number" value="0" disabled="" />
                    </div>
                    <button type="button" id="btncustomField6" data-drop-down-id="cf-dropdown-6" class="disabled customField">
                      <span style="width: 30px" class="material-icons sywatchlaterncalt"><img style="width: 18px" src="/assets/watchLater.svg" alt="" /></span>isBillable
                    </button>
                    <div class="custom-field-dropdown" id="cf-dropdown-6">
                      <div class="d-flex justify-content-between mt-2 mx-3">
                        <label class="isbillable_container">Yes
                          <input type="radio" name="radio_cf_6" value="true" false="" />
                          <span class="checkmark"></span>
                        </label>
                        <label class="isbillable_container">No
                          <input type="radio" name="radio_cf_6" value="false" checked="" />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <button type="button" id="btncustomField8" data-drop-down-id="cf-dropdown-8" class="disabled customField">
                      <span style="width: 30px"  class="material-icons sywatchlaterncalt"><img style="width: 18px" src="/assets/watchLater.svg" alt="" /></span>Billable Hrs
                    </button>
                    <div class="custom-field-dropdown" id="cf-dropdown-8">
                      <input type="number" id="cf-number-8" value="" />
                    </div>
                  </div>
                  <div class="mt-4">
                    <h2>Action</h2>
                    <button type="button" id="move_button" class="move_button right_side_box disabled">
                      <img src="/assets/move.svg" alt="" /><span class="ml-2">Move</span>
                    </button>
                    <button type="button" id="watch_button" class="watch_button right_side_box"
                      (click)="ticket?.is_watcher ? removeWatcher() : addWatcher()" [disabled]="isWatcherButtonDisabled">
                      <img [src]="ticket?.is_watcher ? '/assets/watch2.svg' : '/assets/watch.svg'" alt="" /><span class="ml-2">Watch</span>
                    </button>
                    <button type="button" id="archive_button" class="archive_button right_side_box" (click)="toggleArchive()">
                      <img src="/assets/archive.svg" alt="" /><span class="ml-2">{{ this.ticket.is_archived ? 'Unarchive' : 'Archive' }}</span>
                    </button>
                    <button type="button" id="copy_button" class="copy_button right_side_box disabled" disabled="">
                      <img src="/assets/copy.svg" alt="" /><span class="ml-2">Copy</span>
                    </button>
                    <button type="button" id="template_button" class="template_button right_side_box disabled"
                      disabled="">
                      <img src="/assets/templates.svg" alt="" /><span class="ml-2">Make Template</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>