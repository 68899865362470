<span class="spot-icon spot-icon_{{viewModel.iconClass}} op-files-tab--storage-info-box-icon"></span>

<div
  class="text-box"
>
  <div
    class="text-box-header"
  >
    <span [textContent]="viewModel.header"></span>
  </div>
  <div
    class="text-box-content"
  >
    <span [textContent]="viewModel.content"></span>
  </div>
</div>

<div
  class="button-box"
>
  <button *ngFor="let button of viewModel.buttons"
    class="button op-files-tab--storage-info-box-button"
    (click)="button.action()"
  >
    <op-icon
      icon-classes="button--icon spot-icon spot-icon_external-link"
      class="op-icon--wrapper">
      <i
        class="button--icon spot-icon spot-icon_external-link"
      ></i>
    </op-icon>
    <span
      class="button--text"
    >{{button.label}}</span>
  </button>
</div>
