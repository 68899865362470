import {
  Component,
  Output,
  EventEmitter,
  Input
} from "@angular/core";

@Component({
  selector: "ticket-comments",
  templateUrl: "./ticket-comments.component.html",
  styleUrls: ["./ticket-comments.component.scss"],
})
export class TicketCommentsComponent {
  @Input() comments: any[] = [];
  @Output() updatedComment = new EventEmitter<{ [key: string]: any }>();

  public commentDate: any = null;

  constructor() {}

  stringToDateConverter(dateString: string): any {
    const [month, day, year, time] = dateString.split('/');

    const [hour, minuteMeridium] = time.split(':');
    const [minute, meridium] = minuteMeridium.split(' ');

    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10), parseInt(hour, 10), parseInt(minute, 10));
  }

  isNewDate(dateString: string): Boolean {
    let nextDate = this.stringToDateConverter(dateString);
    if (nextDate.toLocaleDateString() == this.commentDate) {
      return false;
    }
    this.commentDate = nextDate.toLocaleDateString();
    return true;
  }

  formatDate(dateString: string): string {
    let date = this.stringToDateConverter(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };

    return date.toLocaleDateString('en-US', options);
  }

  emitUpdatedComment(comment: { [key: string]: any }) {
    this.updatedComment.emit(comment);
  }
}
